import React, { FC, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { useCourses } from "../../hooks/useCourses";

const invalidInput = css`
  border: 0.5px solid #f13636;
  padding: 2px 10px 2px 4px;
  border-radius: 0 3px 3px 0;
`;
const validInput = css`
  border: 0.5px solid #42f136;
  padding-top: 2px;
  padding: 2px 10px 2px 4px;
  border-radius: 0 3px 3px 0;
`;
export const FormVideo = styled.form`
  position: relative;
  width: auto;
  display: flex;
  z-index: 3;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .videoInputContainer {
    display: flex;

    span {
      height: 100%;
      border: 1px solid #a3a3a3;
      border-right: none;
      padding: 2px 2px 0 10px;
      flex-grow: 1;
      background-color: white;
      border-radius: 3px 0 0 3px;
    }

    input {
      padding-right: 10px;
      ${({ iframeUrl }) =>
        iframeUrl === "not-valid"
          ? invalidInput
          : iframeUrl.length > 0
          ? validInput
          : null}
    }
  }

  #form-video-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
    height: ${({ height }) => (height / 2) * 0.5}px;
    width: ${({ height }) => (height / 2) * 0.75}px;
    border: 1px dashed black;

    p {
      margin: 0;
    }
  }

  div:last-of-type {
    display: flex;

    button {
      width: 100px;
      border-radius: 5px;
      border: 2px solid gray;
    }

    button: last-of-type {
      margin-left: 50px;
    }
  }

  > p:last-of-type {
    cursor: pointer;
    margin: 10px 0 0 0;
    color: #0099be;
  }
`;

const HasVideoSpan = styled.span`
  color: ${({ hasVideo }) => (hasVideo ? "blue" : "red")};
`;

type VideoFormProps = {
  hasVideo: Boolean;
  height: number;
  formEnable: Boolean;
  idCourse: string;
  idModule: string;
  idLesson: string;
  toggleForm: () => void;
  submit: () => void;
};

const VideoForm: FC<VideoFormProps> = ({
  hasVideo,
  height,
  formEnable,
  toggleForm,
  idCourse,
  idModule,
  idLesson,
  submit,
}) => {
  const [urlVideo, setUrlVideo] = useState("");
  const [FirebaseOrYoutube, setFirebaseOrYoutube] = useState<string>("youtube");
  const [iframeUrl, setIframeUrl] = useState("");
  const { updateValuesOfLesson } = useCourses();
  const video = urlVideo;

  function handleSubmit(e: any) {
    e.preventDefault();

    if (FirebaseOrYoutube === "youtube" && iframeUrl) {
      updateValuesOfLesson(idCourse, idModule, idLesson, {
        video: ["youtube", iframeUrl],
      })
        .then(() => {
          alert("El video se subio con exito");
          submit();
          toggleForm();
        })
        .catch((error) => {
          alert(error);
        });
    }
  }

  function handleChange(e: any) {
    setUrlVideo(e.target.value);
    const value = e.target.value;

    if (value !== "") {
      if (value.length === 11) {
        setIframeUrl("https://www.youtube.com/embed/" + value);
      } else {
        const regExp =
          /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        const match = value.match(regExp);
        if (match && match[1].length === 11) {
          setUrlVideo(match[1]);
          setIframeUrl("https://www.youtube.com/embed/" + match[1]);
        } else {
          setIframeUrl("not-valid");
        }
      }
    } else {
      setIframeUrl("");
    }
  }

  useEffect(() => {
    if (!formEnable) {
      setUrlVideo("");
      setIframeUrl("");
    }
  }, [formEnable]);

  const isValid = iframeUrl !== "" && iframeUrl !== "not-valid";

  return (
    <FormVideo onSubmit={handleSubmit} height={height} iframeUrl={iframeUrl}>
      {FirebaseOrYoutube === "youtube" ? (
        <YoutubeVideoForm
          video={video}
          handleChange={handleChange}
          iframeUrl={iframeUrl}
          hasVideo={hasVideo}
          toggleForm={toggleForm}
          isValid={isValid}
          setFirebaseOrYoutube={setFirebaseOrYoutube}
        />
      ) : (
        <FirebaseVideoForm
          setFirebaseOrYoutube={setFirebaseOrYoutube}
          idCourse={idCourse}
          idModule={idModule}
          idLesson={idLesson}
          hasVideo={hasVideo}
          isValid={isValid}
          toggleForm={toggleForm}
          submit={submit}
        />
      )}
    </FormVideo>
  );
};

type YoutubeFormProps = {
  video: string;
  handleChange: (x: any) => void;
  iframeUrl: string;
  hasVideo: Boolean;
  toggleForm: () => void;
  isValid: Boolean;
  setFirebaseOrYoutube: (x: string) => void;
};
const YoutubeVideoForm: FC<YoutubeFormProps> = ({
  video,
  handleChange,
  iframeUrl,
  hasVideo,
  toggleForm,
  isValid,
  setFirebaseOrYoutube,
}) => {
  return (
    <>
      <label htmlFor="videoInput">URL del video:</label>
      <div className="videoInputContainer">
        <span>https://www.youtube.com/watch?v=</span>
        <input
          name="videoInput"
          type="text"
          value={video}
          placeholder="URL del video"
          onChange={handleChange}
          required={true}
        />
      </div>
      <div id="form-video-preview">
        {iframeUrl === "" || iframeUrl === "not-valid" ? (
          <p>Preview del Video</p>
        ) : (
          <iframe
            title="Preview Video"
            src={iframeUrl + "?autoplay=1&enablejsapi=1"}
            frameBorder="0"
            width="100%"
            height="100%"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
      </div>

      <p>
        Actualmente esta lección{" "}
        <HasVideoSpan hasVideo={hasVideo}>
          {hasVideo ? "ya contiene un video" : "no contiene un video"}
        </HasVideoSpan>
      </p>
      <div>
        <button type="button" onClick={toggleForm}>
          Cancelar
        </button>
        <button type="submit" disabled={!isValid}>
          Subir video
        </button>
      </div>
      <p onClick={() => setFirebaseOrYoutube("firebase")}>
        Cambiar a Archivo de Video
      </p>
    </>
  );
};

const FirebaseVideoContainer = styled.div`
  flex-direction: column;
  label {
    text-align: center;
  }

  .videoInputContainer {
    flex-direction: column;
    input {
      opacity: 0;
      height: 0;
    }

    button {
      width: initial !important;
      margin: auto !important;
    }
  }

  > div:last-of-type {
    justify-content: center;
    button {
      margin: 0 !important;
    }
  }

  p {
    text-align: center;
  }

  > p:last-of-type {
    cursor: pointer;
    margin: 10px 0 0 0;
    color: #0099be;
  }
`;
type FirebaseVideoProps = {
  setFirebaseOrYoutube: (x: any) => void;
  hasVideo: Boolean;
  toggleForm: () => void;
  isValid: Boolean;
  idCourse: string;
  idModule: string;
  idLesson: string;
  submit: () => void;
};
const FirebaseVideoForm: FC<FirebaseVideoProps> = ({
  setFirebaseOrYoutube,
  idCourse,
  idModule,
  idLesson,
  hasVideo,
  isValid,
  toggleForm,
  submit,
}) => {
  const [video, setVideo] = useState("");
  const [percentage, setPercentage] = useState<number>(-1);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { uploadLessonFirebaseVideo } = useCourses();
  const { updateValuesOfLesson } = useCourses();

  function onVideoUpload(e: any) {
    setVideo("");
    uploadLessonFirebaseVideo(
      e.target.files[0],
      idCourse,
      idModule,
      idLesson,
      setPercentage,
      setVideo
    );
  }

  useEffect(() => {
    if (video !== "")
      updateValuesOfLesson(idCourse, idModule, idLesson, {
        video: ["video", video],
      })
        .then(() => {
          alert("El video se subio con exito");
          submit();
          toggleForm();
        })
        .catch((error) => {
          alert(error);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video]);

  return (
    <FirebaseVideoContainer>
      <label htmlFor="videoInput">Archivo de video:</label>
      <div className="videoInputContainer">
        <button
          onClick={() =>
            fileInputRef.current ? fileInputRef.current?.click() : {}
          }
        >
          Subir Archivo
        </button>
        <input
          name="videoInput"
          type="file"
          ref={fileInputRef}
          //value={video}
          placeholder="URL del video"
          onChange={onVideoUpload}
          required={true}
        />
      </div>

      <div id="form-video-preview">
        {percentage >= 0 && video === "" ? Math.round(percentage) + "%" : null}
        {video ? (
          <iframe
            title="Video File"
            src={video}
            frameBorder="0"
            width="100%"
            height="100%"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        ) : (
          <p>Preview del Video</p>
        )}
      </div>

      <p>
        Actualmente esta lección{" "}
        <HasVideoSpan hasVideo={hasVideo}>
          {hasVideo ? "ya contiene un video" : "no contiene un video"}
        </HasVideoSpan>
      </p>
      <div>
        <button type="button" onClick={toggleForm} disabled={!video}>
          Terminar
        </button>
      </div>
      <p onClick={() => setFirebaseOrYoutube("youtube")}>
        Cambiar a Video de Youtube
      </p>
    </FirebaseVideoContainer>
  );
};

export default VideoForm;
