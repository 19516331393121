import React from "react";
import styled from "styled-components";

const Container = styled.div`
    width: 100%; 
    position: relative;
    height: 100%; 

    & p {
        position: absolute; 
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;

        font-family: 'Inria Sans',sans-serif;
        color: #ffffffd9;
    }
`

const Input = styled.input`
    ::-webkit-file-upload-button{
        visibility: hidden;
    }
    cursor: pointer;
    position: relative; 
    width: inherit; 
    height: inherit; 
    background: ${ 
        //Si el prop background no es undefined
        (props) => props.backgroundImage !== '' ? ('url('+props.backgroundImage+')') : 'linear-gradient(90deg,#D14FC4 0%,#D14F4F 100%)'
    };
    background-origin: border-box;
    background-position: top;
    background-size: cover;
    overflow: hidden;
`

const BackgroundUploadInput = (props: any) => {

    function readURI(e: any){
        if(e.target.files && e.target.files[0]){
          let reader = new FileReader();
          reader.onload = function(ev: any){
            props.setBackground({
                ...props.cursoData, 
                background:ev.target.result
            });
          }
          reader.readAsDataURL(e.target.files[0]);
        }
    }

    function handleChange(e: any){
        readURI(e);
        props.setBackgroundFile(e.target.files[0])
        e.target.value = null
    }

    return(
        <Container>
            <Input  name='background-file'
                    type='file' 
                    id='background-upload'
                    title=' '
                    accept=".jpg, .png"
                    style={{color: 'transparent'}}
                    backgroundImage={props.cursoData.background}
                    onChange={handleChange}
            />
            {props.cursoData.background === '' ? <p>Subir Imagen de Fondo</p> : null} 
        </Container>
    )
}

export default BackgroundUploadInput