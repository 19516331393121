import React, { useState, useRef, useContext } from "react";
import styled from "styled-components";
import BottonCompra from "../BottonCompra";
import { useCoursePreview } from "../../hook/useCoursePreview";
import { InputContainerStyle } from "./styles";
import { CursoPreviewData } from "../..";
import { EditPreviewProps } from "../..";
import { useAuthUser } from "../../../../Services/useAuthUser";

export const CallToAction = ({ id }: { id: string }) => {
  const { addCoursePreviewData } = useCoursePreview();
  const [isModifyModule, setModify] = useState(false);

  const inputReference = useRef<any>(null);

  function enableModification(event: any) {
    if (event.target !== inputReference.current) {
      setModify(!isModifyModule);
    }
  }

  function onLoseFocus() {
    setModify(false);
  }

  const cursoData = useContext(CursoPreviewData);
  const EditData = useContext(EditPreviewProps);
  const { isAdmin } = useAuthUser();

  function renameButton(event: any) {
    if (event.key === "Enter") {
      addCoursePreviewData(cursoData!.id, { previewBoton: event.target.value })
        .then((resolve) => {
          EditData.update();
        })
        .catch((reject) => {
          window.alert(reject);
          EditData.update();
        });
      setModify(false);
    }
  }

  return (
    <CallToActionStyles>
      <div>
        {isModifyModule ? (
          <InputContainerStyle style={{ marginBottom: "max(8px,2%)" }}>
            <input
              autoFocus
              ref={inputReference}
              onBlur={onLoseFocus}
              onKeyPress={renameButton}
            />
          </InputContainerStyle>
        ) : (
          <BottonCompra cursoData={cursoData} cursoId={id} isGratuito={cursoData?.isGratuito || false} />
        )}
        {isAdmin && <p onClick={enableModification}>Cambiar texto del botón</p>}
      </div>
    </CallToActionStyles>
  );
};

const CallToActionStyles = styled.div`
  display: flex;
  justify-content: center;

  p {
    margin: 0;
    color: #145882;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
  }

  @media screen and (min-width: 721px) {
    justify-content: flex-end;

    div {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
  }

`;
