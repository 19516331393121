import React, { FC } from "react";
import MaterialCreator from "./MaterialCreator";
import { MaterialCard } from "../MaterialCard";
import { ModuleMaterialContainer, ModuleMaterial } from "./styles";
import { useAuthUser } from "../../../../Services/useAuthUser";
import { useFirebaseContext } from "../../../../Services/Firebase";

type MaterialesProps = {
  moduleData: any;
  refresh: () => void;
  idConstants: { idCourse: string; idModule: string };
};

function useDeleteMaterial() {
    const firebase = useFirebaseContext();

  return function deleteModuleMaterial(urlFormated: string, url: string) {
    const fixedURI = urlFormated.replaceAll("%2F", "/");
    //NOTE: it would be nice to spesify what each splitID is
    console.log("fixedURI", fixedURI);
    const [collecion, cursoId, moduloId, materialId] = fixedURI.split("/");

    function getMaterialRef() {
      return firebase
        .getCollectionRef(collecion)
        .doc(cursoId)
        .collection("modulos")
        .doc(moduloId);
    }

    async function deleteFromStore() {
      let storageURI =
        "gs://" + process.env.REACT_APP_STORAGE_BUCKET + "/" + fixedURI;
      await firebase.storage.refFromURL(storageURI).delete();
    }

    return new Promise<any>(async (resolve, reject) => {
      try {
        await deleteFromStore();
      } catch (error: any) {
        console.log("No se pudo borrar el material del store: ", error.message);
        // reject("No se pudo borrar el material del storage: " + error.message);
      }

      try {
        const materialDBref = getMaterialRef();
        const documentData = await materialDBref.get();
        const materialDB = documentData.data()?.[materialId];
        let materials: string[] = materialDB;

        materials = materials.filter((materialUrl) => {
          return materialUrl !== url;
        });

        await materialDBref.update({ materiales: materials });
      } catch (error: any) {
        reject("No se pudo borrar el material del curso: " + error.message);
      }

      resolve("Material borrado con exito");
    });
  }
}

// Esta pagina solo es controlada por el admin.
export const MaterialesModulo: FC<MaterialesProps> = ({
  moduleData,
  refresh,
  idConstants,
}) => {
  const { isAdmin } = useAuthUser();
  const deleteModuleMaterial = useDeleteMaterial();

  return (
    <>
      {(moduleData?.data?.materiales?.length > 0 || isAdmin) && (
        <ModuleMaterialContainer>
          <p>Material de apoyo:</p>
          <ModuleMaterial pageHeight={480}>
            {moduleData?.data?.materiales?.map(
              (material: any, index: number) => (
                <MaterialCard
                  key={"material-card-" + index + 1}
                  cardData={material}
                  refresh={refresh}
                  deleteMaterial={deleteModuleMaterial}
                />
              )
            )}
            {isAdmin && (
              <MaterialCreator
                idConstants={idConstants}
                refresh={refresh}
                materials={moduleData.data.materiales}
              />
            )}
          </ModuleMaterial>
        </ModuleMaterialContainer>
      )}
    </>
  );
};
