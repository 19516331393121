import React, { FC, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { ModulosType } from "../../types";
import { useCourses } from "../../hooks/useCourses";
import { MaterialesModulo } from "./MaterialesModulo";
import { useAuthUser } from "../../../../Services/useAuthUser";

import {
  Container,
  ModulePresentationInfo,
} from "./styles";
import { useCursoContext } from "../../CursoContext";
import { ImagenBanner } from "./ImagenBanner";

export const ModulePresentation: FC = () => {
  const [isEditEnable, setEditEnable] = useState(false);

  const { updateValuesOfModule } = useCourses();
  const inputReference = useRef<any>(null);
  const { isAdmin } = useAuthUser();

  const {
    courseData,
    doModuleLessonRefresh: refresh,
    courseModules,
  } = useCursoContext();

  const urlParams = useParams<any>();

  const idConstants = {
    idCourse: courseData?.id || "",
    idModule: urlParams.idModule,
  };

  function getModuleSelectedData():
    | { id: string; data: ModulosType }
    | undefined {
    if (courseModules) {
      const module = courseModules.find(
        (modulo) => modulo.id === urlParams.idModule
      );
      if (module) return { id: module.id, data: module.data };
    }
    return undefined;
  }

  const moduleData = getModuleSelectedData();

  const [text, setText] = useState(moduleData?.data.descripcion);



  function toggleLessonDescriptionEdit(event: any) {
    if (event.target !== inputReference.current) {
      setEditEnable(true);
    }
  }

  function onLoseFocus() {
    setEditEnable(false);
  }

  async function doCreatLesson(event: any) {
    if (event.key === "Enter") {
      await updateValuesOfModule(idConstants.idCourse, idConstants.idModule, {
        descripcion: text,
      });
      setEditEnable(false);
      refresh();
    }
  }

  function handleChange(event: any) {
    setText(event.target.value.replace("\n", ""));
  }

  useEffect(() => {
    if (isEditEnable && text)
      inputReference.current.setSelectionRange(text.length, text.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditEnable]);

  return (
    <>
      {moduleData && (
        <Container>
          <ImagenBanner moduloImgUrl={moduleData.data.moduloImgUrl} />
          <ModulePresentationInfo
            isDescriptionEmpty={moduleData.data.descripcion}
          >
            <h1>{moduleData.data.nombreModulo}</h1>
            <div>
              {isAdmin ? (
                isEditEnable ? (
                  <textarea
                    autoFocus
                    ref={inputReference}
                    onBlur={onLoseFocus}
                    onKeyPress={doCreatLesson}
                    value={text}
                    onChange={handleChange}
                  />
                ) : moduleData.data.descripcion ? (
                  <p>
                    <span onClick={toggleLessonDescriptionEdit}>
                      {moduleData.data.descripcion}
                    </span>
                  </p>
                ) : (
                  <p>
                    <span onClick={toggleLessonDescriptionEdit}>
                      Agregar una descripción al modulo
                    </span>
                  </p>
                )
              ) : (
                <p>{moduleData.data.descripcion}</p>
              )}
            </div>
          </ModulePresentationInfo>

          <MaterialesModulo
            moduleData={moduleData}
            idConstants={idConstants}
            refresh={refresh}
          />
        </Container>
      )}
    </>
  );
};
