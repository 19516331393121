import React, { useState, useEffect, FC } from "react";
import styled from "styled-components";
import { useAuthUser } from "../../../Services/useAuthUser";
import { useFirebaseContext } from "../../../Services/Firebase";
import { ElBotton } from "../../../components/common/ElBotton";
import { useUploadFile } from "../hook/useUploadFile";

export const PopUp: FC<{ cursoId: string }> = ({ cursoId }) => {
  const { isAdmin } = useAuthUser();
  const firebase = useFirebaseContext();

  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpData, setPopUpData] = useState<any>({});

  const uploadFile = useUploadFile();

  async function uploadImage(file: any) {
    try {
      return await uploadFile(file, cursoId);
    } catch (error) {
      alert("Error al subir la imagen");
      console.log("error: ", error);
    }
  }

  async function guardarPopUp() {
    const texto = (document.getElementById("texto-modal") as HTMLInputElement)
      .value;

    const imagen = (document.getElementById("imagen-modal") as HTMLInputElement)
      ?.files?.[0];

    if (!imagen) {
      alert("No hay imagen");
      return;
    }

    const url = await uploadImage(imagen);

    // guardar imagen y obtener url

    await firebase.curso(cursoId).collection("popUp").doc("popUp").set({
      texto,
      url,
      mostrar: true,
    });

    setPopUpData({ texto, url, mostrar: true });

    alert("Se guardo el PopUp");
  }

  useEffect(() => {
    // Cargar la info del popUp
    // luego ponemos el timeout
    async function cargarPopUp() {
      const popUp = await firebase
        .curso(cursoId)
        .collection("popUp")
        .doc("popUp")
        .get();

      if (popUp.exists) {
        const data = popUp.data();
        setPopUpData(data);

        if (data!.mostrar) {
          const id = setTimeout(() => {
            setShowPopUp(true);
            clearTimeout(id);
          }, 15000);
        }
      }
    }

    if (cursoId) {
      cargarPopUp();
    }
  }, [cursoId, firebase]);

    function activar() {
        firebase.curso(cursoId).collection("popUp").doc("popUp").update({
        mostrar: true,
        });

        setPopUpData((prev: any) => ({ ...prev, mostrar: true }));
    }

    function desactivar() {
        firebase.curso(cursoId).collection("popUp").doc("popUp").update({
        mostrar: false,
        });

        setPopUpData((prev: any) => ({ ...prev, mostrar: false }));
    }

  return (
    <>
      <PopUpStyles show={showPopUp} onClick={() => setShowPopUp(false)}>
        <div onClick={(e) => e.stopPropagation()} className="modal-popup">
          <img src={popUpData.url} alt="" />
          <p>{popUpData.texto}</p>
        </div>
      </PopUpStyles>
      {isAdmin && (
        <AdminPopUpStyles>
          <h3>Admin PopUp</h3>
          {/* opcion para activar/des-activar */}

          {/* opcion para editar la imagen */}
          <label htmlFor="imagen-modal">Imagen</label>
          <input type="file" name="" id="imagen-modal" accept="image/*" />

          {/* opcion para editar el texto */}
          <label htmlFor="texto-modal">Texto para el modal</label>
          <textarea id="texto-modal" />

          <ElBotton onClick={guardarPopUp}>Guardar</ElBotton>

          {popUpData.url && (
            <div>
              <h3>Hay un modal guardado.</h3>

              <ElBotton onClick={() => setShowPopUp(true)}>Visualizar</ElBotton>
              {popUpData.mostrar ? (
                <>
                  <p>El PopUp esta habilitado</p>
                  <ElBotton onClick={desactivar}>Desactivar</ElBotton>
                </>
              ) : (
                <>
                  <p>El PopUp esta deshabilitado</p>
                  <ElBotton onClick={activar}>Activar</ElBotton>
                </>
              )}
            </div>
          )}
        </AdminPopUpStyles>
      )}
    </>
  );
};

const AdminPopUpStyles = styled.div`
  border: 1px solid black;
  padding: 12px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PopUpStyles = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? "flex" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;

  .modal-popup {
    z-index: 1010;
    background-color: white;
    border-radius: 12px;
    width: 400px;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 12px 12px 0 0;
    }

    p {
      text-align: center;
    }
  }
`;
