import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useFirebaseContext } from "../../Services/Firebase";
import { useErrorSingIn } from "./useErrorSignIn";
import { SignUpLink } from "../Registro";
import { PasswordForgetLink } from "../PasswordForget";
import { Links } from "./styles";

import * as ROUTES from "../../constants/routes";
import { ErrorMsg } from "./styles";

export const SignInForm = () => {
  const history = useHistory();
  const firebase = useFirebaseContext();
  const [formData, setFormData] = useState({ email: "", password: "" });

  const [error, errorType] = useErrorSingIn("");

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.name === "email") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value.toLowerCase(),
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  }

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      firebase
        .doSignInWithEmailAndPassword(formData.email, formData.password)
        .catch((error: any) => errorType(error));
      const user = await firebase.isUserLogged();
      if (localStorage.getItem("fromModal") === "first") {
        localStorage.setItem("fromModal", "second");
        user ? history.goBack() : history.push(ROUTES.SIGN_IN);
      } else {
        user ? history.goBack() : history.push(ROUTES.SIGN_IN);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const isInvalid = formData.password === "" || formData.email === "";



  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <label className="label__correo" htmlFor="email">Correo electrónico</label>
        <input
          className="form-control"
          name="email"
          value={formData.email}
          onChange={onChange}
          type="text"
          placeholder="Correo electrónico"
        />
      </div>
      <div className="form-group">
        <label className="label__contraseña" htmlFor="password">Contraseña</label>
        <input
          className="form-control"
          name="password"
          value={formData.password}
          onChange={onChange}
          type="password"
          placeholder="Contraseña"
        />
      </div>

      <Links>
      <div>
        <PasswordForgetLink />
      <button className="boton" disabled={isInvalid} type="submit">
        Iniciar sesión
      </button>
      </div>
      {error && <ErrorMsg>{error}</ErrorMsg>}
        {/* SignupLink Tiene el boton de crear cuenta y tiene un div de parent  */}
        <SignUpLink />
      </Links>
    </form>
  );
};
