import React, { FC, useState } from "react";
import styled from "styled-components";
import { CategoriasCursos } from "../../../constants/types";

type CourseListFilterProps = {
  // TODO: use enum type here
  categorySelected: string;
  filterCategoryClick: (event: any, x?: string) => void;
  search: string;
  handleChange: (event: any) => void;
};

const CourseListFilter: FC<CourseListFilterProps> = ({
  categorySelected,
  filterCategoryClick,
  search,
  handleChange,
}) => {
  const [searchBarStatus, setSeachbarStatus] = useState(false);

  function toggleSearchBar() {
    setSeachbarStatus(!searchBarStatus);
  }

  return (
    <Container>
      <FilterType>
        <div>
          <ListFilterContainer>
            <ul>
              {searchBarStatus ? (
                <input
                  autoFocus
                  type="text"
                  id="courseSearch"
                  value={search}
                  onBlur={() => setSeachbarStatus(false)}
                  placeholder="Buscar Curso"
                  onChange={handleChange}
                />
              ) : (
                <>
                  <div className="no-mobile-category-menu">
                    <li
                      style={
                        categorySelected === CategoriasCursos.Prosperidad
                          ? selectedStyle
                          : {}
                      }
                      onClick={filterCategoryClick}
                    >
                      Prosperidad
                    </li>
                    <span>|</span>
                    <li
                      style={
                        categorySelected === CategoriasCursos.Relaciones
                          ? selectedStyle
                          : {}
                      }
                      onClick={filterCategoryClick}
                    >
                      Relación
                    </li>
                    <span>|</span>
                    <li
                      style={
                        categorySelected === CategoriasCursos.Salud
                          ? selectedStyle
                          : {}
                      }
                      onClick={filterCategoryClick}
                    >
                      Salud
                    </li>
                    <span>|</span>
                    <li
                      style={
                        categorySelected === CategoriasCursos.Gratuito
                          ? selectedStyle
                          : {}
                      }
                      onClick={filterCategoryClick}
                    >
                      Gratuito
                    </li>
                  </div>

                  {/* Versiomo mobil */}
                  <div className="mobile-category-menu">
                    <select name="category-menu" onChange={filterCategoryClick}>
                      <option value="">--</option>
                      <option value="salud">Salud</option>
                      <option value="prosperidad">Prosperidad</option>
                      <option value="relación">Relación</option>
                      <option value="gratuito">Gratuito</option>
                    </select>
                  </div>
                </>
              )}

              <li
                style={searchBarStatus ? selectedStyle : {}}
                onClick={toggleSearchBar}
              >
                <i className="fas fa-search"></i>
              </li>
            </ul>
          </ListFilterContainer>
        </div>

        <SearchInputContainer>
          <input
            type="text"
            id="courseSearch"
            value={search}
            placeholder="Buscar Curso"
            onChange={handleChange}
          />
        </SearchInputContainer>
      </FilterType>
    </Container>
  );
};

const Container = styled.div`
  @media screen and (max-width: 425px) {
    width: fit-content;
    margin: auto;
  }
  margin-bottom: 24px;
`;
const FilterType = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    > div:first-of-type {
      width: 100%;
    }
    > div:last-of-type {
      display: none;
    }
  }
`;
const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 29px;
  flex-grow: 1;

  input {
    height: 100%;
    border: 1px solid #c0c0c0;
    border-radius: 5px;
    flex-grow: 1;
    background: #fbfbfb;
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 13px;
    padding: 0 10px;
  }

  i {
    margin-left: 7px;
    font-size: 31px;
    color: #c7c7c7;
    text-shadow: 0px 1.5px 1px rgba(117, 117, 117, 0.84);
  }
`;
const ListFilterContainer = styled.div`
  background: #fbfbfb;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  width: 494px;

  li {
    font-weight: 600;
    cursor: pointer;

    &:hover {
      color: #c11f6d;
    }

    &:selected {
      text-decoration: underline;
    }
  }

  ul {
    display: flex;
    padding: 0;
    margin: 0;
    padding: 0px 35px;
    justify-content: space-between;

    font-family: "Montserrat";
    font-weight: 600;
    font-size: clamp(11px, 2.4vw, 13px);
    color: #282828;
    list-style-type: none;

    input {
      text-align: center;
      border: none;
      border-radius: 5px;
      width: 100%;

      :focus {
        outline: none;
      }
    }

    .mobile-category-menu {
      display: none;
    }

    div {
      flex-grow: 1;
      display: flex;
      justify-content: space-around;
    }

    div li,
    span,
    li {
      padding: 3px 5px;
      user-select: none;
    }

    > li {
      display: none;
    }

    div li span:hover,
    li span:hover {
      cursor: pointer;
      color: #c11f6d;
    }
  }

  @media screen and (max-width: 768px) {
    margin: auto;
    width: clamp(405px, 90%, 446px);

    ul {
      padding: initial;
      justify-content: center;
      align-items: center;

      > li {
        padding-right: 20px;
        padding-left: 20px;
        border-left: 1px solid #dfdfdf;
        display: initial;
      }

      div {
        padding: 0 15px;
        flex-wrap: wrap;
        justify-content: inherit;
      }
    }
  }

  @media screen and (max-width: 425px) {
    ul {
      font-size: 13px;
    }

    ul .mobile-category-menu {
      display: block;
      padding: 0;
      width: 100%;

      select {
        margin: auto;
        display: flex;
        border: none;
        width: 100%;
        text-align: center;
        appearance: none;

        :focus {
          outline: none;
        }
      }
    }
    .no-mobile-category-menu {
      display: none;
    }

    width: 80vw;
  }
`;

const selectedStyle = {
  background: "#e7e7e7",
};

export default CourseListFilter;
