import React, { useEffect, useRef, FC } from "react";
import { MaterialCard } from "../MaterialCard";
import styled from "styled-components";
import { useFirebaseContext } from "../../../../Services/Firebase";

type StudentMaterialsProps = {
  userData: any | undefined;
  refresh: () => void;
};

export const ListaMateriales: FC<StudentMaterialsProps> = ({
  userData,
  refresh,
}) => {
  const materialContainerRef = useRef<HTMLDivElement>(null);

  function doScrollHorizontally(event: any) {
    event.preventDefault();

    if (materialContainerRef.current)
      materialContainerRef.current.scrollLeft += event.deltaY;
  }

  useEffect(() => {
    if (materialContainerRef.current)
      materialContainerRef.current.addEventListener(
        "wheel",
        doScrollHorizontally,
        { passive: false }
      );
  }, [materialContainerRef]);

  const firebase = useFirebaseContext();

  function deleteUserMaterial(urlFormated: string, url: string) {
    return new Promise<any>(async (resolve, reject) => {
      const fixedURI = urlFormated.replaceAll("%2F", "/").replace("%40", "@");
      const splitIDs = fixedURI.split("/");
      let storageURI =
        "gs://" + process.env.REACT_APP_STORAGE_BUCKET + "/" + fixedURI;
      try {
        await firebase.storage.refFromURL(storageURI).delete();
      } catch (error) {
        // reject(error);
      }

      try {
        const materialDBref = firebase
          .getCollectionRef(splitIDs[0])
          .doc(splitIDs[1])
          .collection(splitIDs[2])
          .doc(splitIDs[3]);

        const documentData = await materialDBref.get();
        const materialDB: string[] = documentData.data()?.urlMateriales;

        let materials: string[] = materialDB;

        materials = materials.filter((materialUrl) => {
          return materialUrl !== url;
        });

        await materialDBref.update({ urlMateriales: materials });
        resolve("Se borro el material");
      } catch (error) {
        reject(error);
      }
    });
  }

  return (
    <ListaMaterialesContainer ref={materialContainerRef}>
      {userData?.data.urlMateriales.length > 0 ? (
        userData?.data.urlMateriales.map((url: any, index: Number) => (
          <MaterialCard
            key={userData.email + index}
            refresh={refresh}
            cardData={url}
            userEmail={userData.email}
            deleteMaterial={deleteUserMaterial}
          />
        ))
      ) : (
        <div className="no-materials-alert">No has subido ningún trabajo</div>
      )}
    </ListaMaterialesContainer>
  );
};

const ListaMaterialesContainer = styled.div`
  display: flex;
  gap: 8px;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  background-color: darkgrey;
  border-radius: 8px;
  padding: 8px;

  ::-webkit-scrollbar {
    height: 6px;
    width: 20px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 8px;
  }

  .no-materials-alert {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    margin: 0;
    color: white;
  }
`;
