import React, { useState, FC } from "react";
import styled from "styled-components";
import { ElBotton } from "../../../../components/common/ElBotton";
import { SwitchButton } from "../../../../components/common/SwitchButton";
import { useExamenContext } from "../../ExamenContext";
import { QuestionsForm } from "./QuestionsForm";
import { QuestionsList } from "./QuestionsList";
import { UserSubmitions } from "./UserSubmitions";

const Wrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

const Controles = styled.div`
  display: flex;
  gap: 16px;
`;

export const AdminHome = () => {
  const { curso, examenData } = useExamenContext();

  const [view, setView] = useState("inicio");

  return (
    <Wrapper>
      <h1 style={{ textAlign: "center" }}>Examen {curso?.nombreCurso}</h1>
      <Controles>
        <ElBotton
          onClick={() => setView("inicio")}
          disabled={view === "inicio"}
        >
          Inicio
        </ElBotton>
        <ElBotton
          onClick={() => setView("alumnos")}
          disabled={view === "alumnos"}
        >
          Alumnos
        </ElBotton>
        <ElBotton
          onClick={() => setView("preguntas")}
          disabled={view === "preguntas"}
        >
          Preguntas
        </ElBotton>
      </Controles>

      {/* vistas con conditional render. TODO: opcional implementar rutas */}
      {view === "inicio" && examenData && (
        <ExamenControls examenData={examenData} />
      )}
      {view === "preguntas" && (
        <>
          <QuestionsForm />
          <QuestionsList />
        </>
      )}
      {view === "alumnos" && <UserSubmitions />}
    </Wrapper>
  );
};

// obtenemos esto por props y no por el contexto para facilitar el
// condicional rendering
const ExamenControls: FC<{ examenData: any }> = ({ examenData }) => {
  const { guardarExamen, getExamen } = useExamenContext();

  const [diploma, setDiploma] = useState(examenData?.diploma?.formato || "no");
  const [generacion, setGeneracion] = useState(
    examenData?.diploma?.generacion || "2022"
  );
  const [activo, setActivo] = useState(examenData?.activo || false);

  async function updateDiploma() {
    await guardarExamen({
      ...examenData,
      diploma: { ...examenData.diploma, generacion, formato: diploma },
      activo,
    });

    await getExamen();

    alert("Datos guardados");
  }

  return (
    <div style={{ marginTop: "16px" }}>
      <p>
        Examen Activo (permite o no que los usuarios entren a realizar el
        examen)
      </p>
      <SwitchButton
        name="examen-activo"
        checked={activo}
        onChange={() => setActivo(!activo)}
        label="Activo"
      />
      <h4>Datos Diploma</h4>
      <p>Año generación</p>
      <input
        value={generacion}
        onChange={(e) => setGeneracion(e.target.value)}
      />

      <p style={{ marginTop: "16px" }}>Formato diploma</p>

      <select
        value={diploma}
        onChange={(e: any) => {
          setDiploma(e.target.value);
        }}
      >
        <option value="no"></option>
        <option value="fengshui">Fengshui</option>
        <option value="bazi">Bazi</option>
        <option value="millonarios2">Metafisica para millonarios 2</option>
        <option value="sanandoAmor">Sanando el Amor</option>
        <option value="metafisicaSalud">Metasisica para la salud</option>
        <option value="dragonMadera">Dragon Madera 2024</option>
        <option value="cartasReemplazo">Cartas de Reemplazo</option>
        <option value="desactivandoAflicciones">Desactivando Aflicciones</option>
      </select>

      <ElBotton onClick={updateDiploma} color="gris">
        Guardar
      </ElBotton>
    </div>
  );
};
