import React from 'react';

import AuthUserContext from './context';

// NOTA: este componente es bien importante para el auth
// hay que darle amor
import { withFirebase } from "../../Services/Firebase"
const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: JSON.parse(localStorage.getItem('authUser'))
      };
    };

    componentDidMount() {
      // Aqui se llega con el next del Firebase auth
      this.listener = this.props.firebase.onAuthUserListener(
        authUser => {
          localStorage.setItem('authUser', JSON.stringify(authUser));
          this.setState({ authUser });
        },
        () => {
          localStorage.removeItem('authUser');
          this.setState({ authUser: null });
        }
      );
    };

    componentWillUnmount() {
      this.listener();
    };

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} authUser={this.state.authUser} />
        </AuthUserContext.Provider>
      );
    };
  }

  return withFirebase(WithAuthentication);
}

export default withAuthentication; 