import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";

import { withFirebase } from "../../Services/Firebase";
import * as ROUTES from "../../constants/routes";

import backgroundImage from "./louPan.png";

const ERROR_CODE_ACCOUNT_EXISTS = "auth/email-already-in-use";
const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign-in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

const mexico_states = [
  "Elegir ...",
  "Fuera de la Republica Mexicana",
  "Aguascalientes",
  "Baja California",
  "Baja California Sur",
  "Campeche",
  "Coahuila",
  "Colima",
  "Chiapas",
  "Chihuahua",
  "Distrito Federal",
  "Durango",
  "Guanajuato",
  "Guerrero",
  "Hidalgo",
  "Jalisco",
  "México",
  "Michoacán",
  "Morelos",
  "Nayarit",
  "Nuevo León",
  "Oaxaca",
  "Puebla",
  "Querétaro",
  "Quintana Roo",
  "San Luis Potosí",
  "Sinaloa",
  "Sonora",
  "Tabasco",
  "Tamaulipas",
  "Tlaxcala",
  "Veracruz",
  "Yucatán",
  "Zacatecas",
];

const SignUpPage = () => {
  const background = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right center",
    backgroundSize: "cover",

    flexGrow: "1",
  };

  const card = {
    backgroundColor: "white",
    marginTop: "30px",
    width: "70%",
    maxWidth: "400px",
    padding: "15px",
    borderBottomLeftRadius: "5%",
    borderBottomRightRadius: "5%",
    margin: "0 auto",
  };

  return (
    <div style={background}>
      <div style={card}>
        <h3>Registro</h3>
        <SignUpForm />
      </div>
    </div>
  );
};

const INITIAL_STATE = {
  username: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  estado: "",
  ciudad: "",
  error: null,
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    event.preventDefault();

    const { username, email, passwordOne, estado, ciudad } = this.state;

    const roles = [];
    const products = [];

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then((authUser) => {
        //create a user in you Firebase realtime databse
        return (
          this.props.firebase
            //.user(authUser.user.uid)
            .user(email)
            .set({
              username,
              email,
              roles,
              products,
              estado,
              ciudad,
            })
        );
      })
      .then(() => {
        return this.props.firebase.doSendEmailVerification();
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }
        this.setState({ error });
      });
  };

  onChange = (event) => {
    if (event.target.name === "email") {
      this.setState({ [event.target.name]: event.target.value.toLowerCase() });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };
  onChangeCheckbox = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const { username, email, estado, ciudad, passwordOne, passwordTwo, error } =
      this.state;

    const isInvalidUbi = estado === "";

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === "" ||
      email === "" ||
      username === "" ||
      isInvalidUbi;

    return (
      <form onSubmit={this.onSubmit}>
        <div className="form-group">
          <input
            className="form-control"
            name="username"
            value={username}
            onChange={this.onChange}
            type="text"
            placeholder="Nombre de usuario"
          />
        </div>
        <div className="form-group">
          <input
            className="form-control"
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
            placeholder="Correo electronico"
          />
        </div>

        <div className="form-group">
          <label htmlFor="estado">Estado</label>
          <select
            className="form-control"
            name="estado"
            value={estado}
            onChange={this.onChange}
          >
            {mexico_states.map((estado) => {
              return <option key={estado}>{estado}</option>;
            })}
          </select>
        </div>

        <div className="form-group">
          <input
            className="form-control"
            name="ciudad"
            value={ciudad}
            onChange={this.onChange}
            type="text"
            placeholder="Ciudad"
          />
        </div>

        <div className="form-group">
          <input
            className="form-control"
            name="passwordOne"
            value={passwordOne}
            onChange={this.onChange}
            type="password"
            placeholder="Contraseña"
          />
        </div>

        <div className="form-group">
          <input
            className="form-control"
            name="passwordTwo"
            value={passwordTwo}
            onChange={this.onChange}
            type="password"
            placeholder="Confirma tu contraseña"
          />
        </div>

        <button className="boton" disabled={isInvalid} type="submit">
          Continuar
        </button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}


const SignUpLink = () => (
  <div>
    <p>¿Necesitas crear cuenta nueva? </p>
    <Link to={ROUTES.SIGN_UP}>
      <button>Crear Cuenta</button>
    </Link>
  </div>
);

const SignUpForm = compose(withRouter, withFirebase)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };
