import React, { Component } from 'react';
import { Link } from 'react-router-dom';


import { withFirebase } from './../../Services/Firebase';
import * as ROUTES from '../../constants/routes';

import llaves from './llaves.png';

const PasswordForgetPage = () => {

  const background = {
    //backgroundColor: 'red',
    backgroundImage: `url(${llaves})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right center',


    flexGrow: '1'
  }

  const card = {
    backgroundColor: 'white',
    marginTop: '30px',
    width: '70%',
    maxWidth: '400px',
    padding: '15px',
    borderRadius: '2%',
    margin: '0 auto',
  }

  return (
    <div style={background}>
      <div className="container">
        <div style={card}>
          <h1>Restablecer contraseña</h1>
          <p>
            No te preocupes, a todos nos ha pasado.
            Ingresa tu correo electrónico y te mandaremos
            un link para restablecer tu contraseña.
                </p>
          <PasswordForgetForm />
        </div>
      </div>
    </div>
  )
}

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    event.preventDefault();

    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });
  };

  onChange = event => {
    if (event.target.name === 'email' ) {
      this.setState({ [event.target.name]: event.target.value.toLowerCase() });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === '';

    return (
      <form onSubmit={this.onSubmit}>
        <div className="form-group">
          <input
            className="form-control"
            name="email"
            value={this.state.email}
            onChange={this.onChange}
            type="text"
            placeholder="Correo electrónico"
          />
        </div>

        <button
          className="boton"
          disabled={isInvalid}
          type="submit"
        >
          Reiniciar contraseña
                </button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>¿Olvidaste tu contraseña?</Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
