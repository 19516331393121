import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { userMaterial, useUsersMaterials } from "../../hooks/useUsersMaterials";
import { StudentsMaterials } from "./UserMaterials";
import { ListaMateriales } from "./ListaMateriales";
import { UserMaterialUploader } from "./UserMaterialUploader";
import { useAuthUser } from "../../../../Services/useAuthUser";
import { useCursoContext } from "../../CursoContext";


const UsersMaterials: FC = () => {
  const { isAdmin, authUser } = useAuthUser();
  //Cargar todos los documentos de materiales-alumnos
  const [usersMaterials, setUsersMaterials] = useState<userMaterial[]>([]);
  const [isUpdated, setUpdated] = useState(false);
  const { getUsersMaterials } = useUsersMaterials();

  const { courseData, doModuleLessonRefresh: refresh } = useCursoContext();

  function loadUsersMateriels() {
    if (courseData?.id) {
      getUsersMaterials(courseData?.id).then((resolve) => {
        setUsersMaterials(resolve);
        setUpdated(true);
      });
    }
  }

  function doRefresh() {
    refresh();
    setUpdated(false);
  }

  useEffect(() => {
    loadUsersMateriels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isUpdated === false) loadUsersMateriels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated]);

  return (
    <Container>
      <h1>Trabajos de usuarios del curso</h1>

      <div className="user-materials">
        {isAdmin ? (
          <p className="materials-admin-warning">
            Los Administradores no pueden usar esta funcionalidad
          </p>
        ) : (
          <>
            <h3>Mis trabajos</h3>
            <div className="container">
              <ListaMateriales
                userData={usersMaterials.find(
                  (user) => user.email === authUser.email
                )}
                refresh={doRefresh}
              />
              <UserMaterialUploader
                idCourse={courseData?.id || ""}
                refresh={doRefresh}
                authUserName={authUser.username}
                userEmail={authUser.email}
                userData={usersMaterials.find(
                  (user) => user.email === authUser.email
                )}
              />
            </div>
          </>
        )}
      </div>

      <StudentsMaterials
        usersData={usersMaterials}
        authUserEmail={authUser.email}
        refresh={doRefresh}
      />
    </Container>
  );
};

const Container = styled.div`
  max-width: 850px;
  width: 100%;
  height: auto;

  > h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    margin: 30px 10px 20px;
    font-weight: 700;
  }

  .materials-admin-warning {
    margin-top: 3px;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    text-align: center;
    margin: 0;
  }

  .user-materials {
    border: 2px solid #dddddd;
    border-radius: 8px;
    padding: 12px 25px 15px;
    background-color: #f9f9f9;

    > h3 {
      font-family: "Montserrat", sans-serif;
      font-size: 15px;
      margin-bottom: 6px;
    }

    .container {
      display: flex;
      width: 100%;
    }
  }
`;

export default UsersMaterials;
