import React from "react";
// import { BrowserRouter as Router, withRouter, useHistory } from 'react-router-dom';

import { SignInForm } from "./SignInForm";
import { Container, Card, Titulo, Imagen, Column } from "./styles";

//Social Providers
import { GoogleSignIn } from "./GoogleSignIn";

import imagenLogin from "./images/imagen__login.jpeg";

const SignInPage = () => {
  return (
    <Container>
      <Imagen src={imagenLogin} alt="Imagen de inicio de sesión"/>
      <Column>
        <Card>
          <Titulo>CAMPUS DE ESTUDIANTES</Titulo>
          <hr />
          <GoogleSignIn />
          <p>O ingresa tus datos</p>
          <SignInForm />
        </Card>
      </Column>
    </Container>
  );
};

export default SignInPage;
