import React from "react";
import styled from "styled-components";

export const DrawerToggleButton = (props: any) => (
  <ToogleButtonStyle>
    <button onClick={props.click}>
      <div className="line" />
      <div className="line" />
      <div className="line" />
    </button>
  </ToogleButtonStyle>
);

const ToogleButtonStyle = styled.div`
  @media (min-width: 920px) {
    display: none;
    .toggle-button {
      display: none;
    }
  }

  button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 30px;
    background: transparent;
    border: none;
    padding: 0;
    box-sizing: border-box;

    &:focus {
      outline: none;
    }
  }

  .line {
    width: 30px;
    height: 2px;
    background: rgb(29, 29, 29);
  }
`;