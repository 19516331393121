import styled from "styled-components";
import React from "react";


export const TablonCourseSeparator = () => {
  return (
    <SeparatorContainer>
      <h1>Nuestros antiguos cursos</h1>
    </SeparatorContainer>
  );
};

const SeparatorContainer = styled.div`
  width: 100%;
  margin-top: 35px;

  h1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    font-size: 20px;
    font-family: "Montserrat";
    color: #9d9d9d;
    padding-bottom: 13px;

    :before,
    :after {
      color: #a7a7a7;
      content: "";
      flex: 1 1;
      border-bottom: 1px solid;
      margin: auto;
    }
    :before {
      margin-right: 10px;
    }
    :after {
      margin-left: 10px;
    }
  }
`;