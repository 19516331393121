import React, { FunctionComponent } from "react";

import { ModalBackdrop, ModalWrapper, ModalContent } from "./styles";

type ModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  small?: boolean;
  estilos?: any;
};

export const ModalCommon: FunctionComponent<ModalProps> = ({ isOpen, closeModal, small, children, estilos }) => {
  if (!isOpen) return null;
  function handleModalClose(e: any) {
    e.stopPropagation();
    if (e.target.localName === "div") {
      closeModal();
    }
  }
  return (
    <ModalWrapper>
      <ModalBackdrop />
      <ModalContent onClick={(e: Event) => handleModalClose(e)} className={small ? "small" : ""} style={estilos}>
        <div className="modalContent">{children}</div>
      </ModalContent>
    </ModalWrapper>
  );
};

export default ModalCommon;
