import React, { FC, useRef } from "react";
import styled from "styled-components";
import { userMaterial, useUsersMaterials } from "../../hooks/useUsersMaterials";

type MaterialUploaderProps = {
  userEmail: string;
  idCourse: string;
  userData: userMaterial | undefined;
  authUserName: string;
  refresh: () => void;
};

export const UserMaterialUploader: FC<MaterialUploaderProps> = ({
  userEmail,
  idCourse,
  userData,
  refresh,
  authUserName,
}) => {
  const { updateValuesUserMaterials, uploadUserMaterial } = useUsersMaterials();
  const inputRef = useRef<HTMLInputElement>(null);
  const [subiendo, setSubiendo] = React.useState(false);

  async function handleUpload(e: any) {
    e.stopPropagation();
    const file = e.target.files[0];
    const filename = file.name;

    if (filename.includes(" ")) {
      alert("El nombre del archivo no puede contener espacios");
      return;
    }

    if (file) {
      setSubiendo(true);
      const path = `/cursos/${idCourse}/materiales-alumnos/${userEmail}/${file.name}`;

      const resolve = await uploadUserMaterial(path, file);
      let newMaterials: string[] = userData?.data.urlMateriales || [];

      newMaterials.push(resolve + "@" + file.name);
      await updateValuesUserMaterials(idCourse, userEmail, {
        urlMateriales: newMaterials,
        nombreUsuario: authUserName,
      });

      setSubiendo(false);
      refresh();
    }
  }

  return (
    <UploaderContainer>
      {subiendo ? (
        <i className="fas fa-spinner fa-spin"></i>
      ) : (
        <>
          <i
            className="fas fa-plus-circle"
            onClick={() => inputRef.current?.click()}
          ></i>
          <p>Agregar</p>
        </>
      )}
      <input
        type="file"
        accept=".jpg, .png, .pdf"
        ref={inputRef}
        onChange={handleUpload}
      />
    </UploaderContainer>
  );
};

const UploaderContainer = styled.div`
  display: flex;
  min-width: 80px;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #bdbdbd;
  transition: 0.2s;
  cursor: pointer;

  :hover {
    color: #3699ff;
  }

  & i {
    font-size: calc(100px * 0.6);
  }

  & p {
    margin: 0;
  }
  & input {
    color: transparent;
    height: 0px;
    width: 0px;
  }
  & input[type="file"] {
    opacity: 0;
    height: 0px;
    width: 0px;
  }
`;
