import React from "react";
import styled from "styled-components";
import { useWhatsappMessage } from "../../../../Services/useWhatsappMessage";
import burbujaChat from "./chat.png";

const Container = styled.div`
    position: fixed;
    top: 80%;
    right: 96px;

    width: 100px;
    height: auto;

    cursor: pointer;

    > img {
        max-width: 100%;
        height: auto;
    }
`

export const Chat = (props: any) => {

    const sendWhatsapp = useWhatsappMessage(props.message)

    return (
        <Container>
            <img onClick={sendWhatsapp} src={burbujaChat} alt="BurbujaChat" id="Abdul-Aziz"/>
        </Container>
    )

}