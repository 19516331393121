
import React, { FC } from "react";
import styled from "styled-components";

type CourseProgressProps = {
  percentage: Number;
};

export const CourseProgress: FC<CourseProgressProps> = ({ percentage }) => {
  return (
    <ProgressContainer percentage={percentage}>
      <div className="course-progressBar">
        <div></div>
      </div>
      <p>{percentage}% Completado</p>
    </ProgressContainer>
  );
};

const ProgressContainer = styled.div`
  margin: 5px 0 15px;
  width: 85%;

  & .course-progressBar {
    height: 7px;
    background: #c7c7c7;

    & div {
      content: "xd";
      width: ${({ percentage }) => percentage}%;
      height: 100%;
      background: #00a70d;
    }
  }

  & p {
    text-align: center;
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    padding: 3px 0;
  }
`;