import React, { useState } from 'react';
import { DudasWrapper } from '../styles';

export const DudasFengshui = () => {
  const [selectedIndice, setSelectedIndice] = useState<number>();

  return (
    <DudasWrapper selectedIndice={selectedIndice}>
      <h3>Quizás tengas algunas dudas con respecto al curso</h3>
      <ul>
        <li>
          <h5 onClick={() => setSelectedIndice(1 === selectedIndice ? 0 : 1)}>¿Podré realizar el estudio de feng shui de mi casa?</h5>
          {subIndiceList[0]}
        </li>
        <li>
          <h5 onClick={() => setSelectedIndice(2 === selectedIndice ? 0 : 2)}>¿Tendré asesorías sobre mis estudios de feng shui?</h5>
          {subIndiceList[1]}
        </li>
        <li>
          <h5 onClick={() => setSelectedIndice(3 === selectedIndice ? 0 : 3)}>¿Cómo se si este diplomado es para mi?</h5>
          {subIndiceList[2]}
        </li>
        <li>
          <h5 onClick={() => setSelectedIndice(4 === selectedIndice ? 0 : 4)}>
            Temo que sea mucha información y no pueda aprovechar el contenido del curso.
          </h5>
          {subIndiceList[3]}
        </li>
        <li>
          <h5 onClick={() => setSelectedIndice(5 === selectedIndice ? 0 : 5)}>¿Qué métodos de pago hay?</h5>
          {subIndiceList[4]}
        </li>
      </ul>
    </DudasWrapper>
  );
};

const subIndiceList = [
  <p>
    De hecho será requisito para que te certifiques con nosotros realizar el estudio de feng shui no solo de tu casa sino también de otro inmueble
    propio o de algún conocido
  </p>,
  <p>
    Tus proyectos y sus avances estarán disponibles en nuestra plataforma para que los demás estudiantes puedan recibir retroalimentación. Además
    contaras con asesorías en las clases y si fuera necesario personalizadas.
  </p>,
  <p>
    Si tienes un proyecto arquitectónico en mente (una casa, una oficina, un local u otro equipamiento) y quieres que desde su diseño estén
    implementados todas las pautas del feng shui clásico. Este diplomado es para ti. Si quieres activar la energía de tu casa, oficina o negocio
    actual para atraer la prosperidad en todas sus formas. Este diplomado es para ti. Si estas en búsqueda de un inmueble y quieres saber su
    favorabilidad energética. Este diplomado es para ti.
  </p>,
  <p>
    Así es, va a haber mucha información, no solo desde la mente sino también desde las emociones. Descuida, he procurado conformar un grupo amoroso
    para que puedas maximizar el contenido del diplomado y veas resultados durante el desarrollo del mismo.
  </p>,
  <p>
    Desde esta página web puedes realizar una compra con tarjeta de crédito o débito a través de la pasarela de pago segura Stripe. No es posible
    actualmente realizar la compra por Paypal, pero sí deseas solicitar alguna condición especial a través del correo hola@arleneruizfengshui.com
  </p>,
];