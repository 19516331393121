
import styled, { css } from "styled-components";

export const Container = styled.div`
  max-width: 850px;
  width: 100%;
  height: auto;
`;

export const descriptionEmpty = css`
  color: #00adff;
  font-weight: bold;
  cursor: pointer;
`;
export const ModulePresentationInfo = styled.div`
  margin: 35px 20px;

  h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 30px;
    font-weight: 700;
  }

  p {
    font-size: 20px;
  }

  p span {
    cursor: pointer;
    ${({ isDescriptionEmpty }) => (isDescriptionEmpty ? "" : descriptionEmpty)}

    &:hover {
      text-decoration: underline;
    }
  }

  textarea {
    font-weight: 200;
    font-size: 20px;
    width: 100%;
    height: 120px;

    :focus {
      border: none;
      outline: none !important;
      border: 1px solid gray;
      resize: none;
    }
  }
`;
export const ModuleMaterialContainer = styled.div`
  p {
    margin: 8px 20px;
    font-size: 18px;
  }
`;
export const ModuleMaterial = styled.div`
  border-radius: 10px;
  height: ${({ pageHeight }) => pageHeight + "px"};
  border: 1px solid #8d8d8d;
  margin-bottom: 50px;
  background: rgb(139, 144, 135);
  height: auto;
  max-height: 400px;
  padding: 20px;

  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;

  div {
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    overflow: hidden;
  }

  div:nth-child(9n) {
    margin-right: 0;
  }
`;
