import React, { FC } from "react";
import { css, FlattenSimpleInterpolation } from "styled-components";
import {
  IndiceComponentContainer,
  IndiceContainer,
} from "../styles";

const estilo = css`
  h5 {
    background: #d6b3b3;
    color: black;
  }
  ul {
    border-color: #d6b3b3;
  }
`;

export const BaziIndiceContainer = () => {
  return (
    <IndiceContainer>
      <h3>Indice</h3>
      <p>Contenido del diplomado de astrologia bazi</p>

      <div>
        <IndiceComponent title="MÓDULO 1: FUNDAMENTOS" estilos={estilo}>
          <li>
            <p>» Origen de astrología Bazi</p>
          </li>
          <li>
            <p>» La trinidad cósmica.</p>
          </li>
          <li>
            <p>» El Yin y Yang y los 5 elementos.</p>
          </li>
          <li>
            <p>» Calculo de la Carta Bazi</p>
          </li>
          <li>
            <p>» Calculo de los 4 pilares</p>
          </li>
          <li>
            <p>» Calculo de los pilares de vida</p>
          </li>
          <li>
            <p>» Calculo de tabla de estrellas auxiliares.</p>
          </li>
          <li>
            <p>» El día maestro.</p>
          </li>
          <li>
            <p>» Lectura de los 5 elementos.</p>
          </li>
        </IndiceComponent>
        <IndiceComponent title="MÓDULO 2: RELACIONES" estilos={estilo}>
          <li>
            <p>» Relaciones con días maestros.</p>
          </li>
          <li>
            <p>» Relaciones con los signos.</p>
          </li>
          <li>
            <p>
              » Choque, daño, combinaciones. Lectura e interpretación con las
              relaciones esenciales.
            </p>
          </li>
        </IndiceComponent>
        <IndiceComponent title="MÓDULO 3: DIEZ DIOSES" estilos={estilo}>
          <li>
            <p>» Calculo de los 10 dioses.</p>
          </li>
          <li>
            <p>» Formas de usar a los 10 dioses.</p>
          </li>
          <li>
            <p>» Los 10 dioses y los 10 daños.</p>
          </li>
          <li>
            <p>» Lectura general.</p>
          </li>
          <li>
            <p>» Consejos y lectura para dinero.</p>
          </li>
          <li>
            <p>» Consejos y lectura para amor.</p>
          </li>
          <li>
            <p>» consejos y lectura para salud.</p>
          </li>
        </IndiceComponent>
        <IndiceComponent title="MÓDULO 4: DIOSES Y DAÑOS" estilos={estilo}>
          <li>
            <p>
              » Lecturas avanzadas con relaciones de signos y tallos
              celestiales.
            </p>
          </li>
          <li>
            <p>» Lecturas avanzadas de los 10 dioses.</p>
          </li>
        </IndiceComponent>
        <IndiceComponent title="MÓDULO 5: TRAUMA RAIZ" estilos={estilo}>
          <li>
            <p>» Estructuras en cartas Bazi.</p>
          </li>
          <li>
            <p>» Análisis del carácter de una persona.</p>
          </li>
          <li>
            <p>» Lectura.</p>
          </li>
          <li>
            <p>» Técnicas de equilibrio y sanación.</p>
          </li>
          <li>
            <p>» Los dioses Útiles y su empleo</p>
          </li>
        </IndiceComponent>
        <IndiceComponent title="MÓDULO 6: EL DIOS ÚTIL" estilos={estilo}>
          <li>
            <p>» Los Dioses y los daños, lecturas avanzadas.</p>
          </li>
          <li>
            <p>» Estructuras avanzadas, lectura e interpretación.</p>
          </li>
          <li>
            <p>» Lecturas avanzada para dinero, carrera, relaciones y salud.</p>
          </li>
        </IndiceComponent>
        <IndiceComponent
          title="MÓDULO 7: ANALISIS DE UNA CARTA BAZI"
          estilos={estilo}
        >
          <li>
            <p>» Análisis de Relaciones.</p>
          </li>
          <li>
            <p>» Análisis de salud.</p>
          </li>
          <li>
            <p>» Análisis de prosperidad.</p>
          </li>
          <li>
            <p>» Métodos de análisis de una carta bazi.</p>
          </li>
        </IndiceComponent>
      </div>
    </IndiceContainer>
  );
};

type IndiceComponentProps = {
  title: string;
  estilos?: FlattenSimpleInterpolation;
};
const IndiceComponent: FC<IndiceComponentProps> = ({
  title,
  estilos,
  children,
}) => {
  return (
    <IndiceComponentContainer estilos={estilos}>
      <h5>{title}</h5>
      <ul>{children}</ul>
    </IndiceComponentContainer>
  );
};