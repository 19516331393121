import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";
import "firebase/functions";
/* tslint:disable */

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
  auth;
  db;
  storage;
  firestorage;
  functions;
  googleProvider;
  facebookProvider;

  constructor() {
    if (!app.apps.length) {
      app.initializeApp(config);
    } else {
      app.app(); // if already initialized, use that one
    }

    this.auth = app.auth();
    this.db = app.database();
    this.storage = app.storage();
    this.firestorage = app.firestore();
    this.functions = app.functions();

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
  }

  /* Chat API */
  // move to a new service.
  chatSendMessage = (message: any) => {
    let newPostRef = this.db.ref("chat/messages").push();
    newPostRef.set({
      message: message.message,
      user: message.user,
    });
  };

  chatListen = () => {
    return this.db.ref("chat/messages");
  };

  chatDelete = () => {
    this.db.ref("chat/messages").set({});
  };

  chatUserJoin = (user: any) => {
    return new Promise((resolve) => {
      this.db
        .ref("chat/users/" + user.uid)
        .set({
          uid: user.uid,
          username: user.username,
        })
        .then(() => {
          resolve("write succes");
        });
    });
  };

  chatUserLeft = (user: any) => {
    this.db.ref("chat/users/" + user.uid).remove();
  };

  chatUsersRef = () => {
    return this.db.ref("chat/users");
  };

  /* ----------------------------------------------------- */

  /* Auth API */

  doCreateUserWithEmailAndPassword = (email: string, password: string) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email: string, password: string) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email: string) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password: string) => {
    if (this.auth.currentUser)
      return this.auth.currentUser.updatePassword(password);
  };

  doSendEmailVerification = () => {
    if (this.auth.currentUser)
      return this.auth.currentUser.sendEmailVerification();
  };

  /* Merge Auth and DB User API */

  onAuthUserListener = (next: (a: any) => any, fallback: () => void) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        //this.user(authUser.uid)
        this.user(authUser.email as string)
          .get()
          .then((snapshot) => {
            //console.log('aquis', snapshot.data());
            const dbUser = snapshot.data();

            // default empty roles
            if (dbUser && !dbUser.roles) {
              dbUser.roles = {};
            }

            // default empty products
            if (dbUser && !dbUser.products) {
              dbUser.products = [] || null;
            }

            // merge auth and db user
            const userInfo = {
              uid: authUser?.uid as string,
              email: authUser?.email as string,
              emailVerified: authUser?.emailVerified as boolean,
              providerData: authUser?.providerData as unknown,
              ...dbUser,
            };
            next(userInfo);
          });
      } else {
        fallback();
      }
    });

  /* Check user auth */
  isUserLogged = () => {
    return new Promise((resolve, reject) => {
      try {
        this.auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          }
        });
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };

  /* User API */

  user = (uid: string) => this.firestorage.collection("users").doc(uid);

  users = () => this.firestorage.collection("users");

  /* Estudios API*/
  estudio = (id: string) => this.firestorage.collection("estudios").doc(id);

  estudios = () => this.firestorage.collection("estudios");

  /* Actualizaciones API */
  actualizacion = (id: string) =>
    this.firestorage.collection("actualizaciones").doc(id);

  actualizaciones = () => this.firestorage.collection("actualizaciones");

  /* Tablones API */
  tablon = (id: string) => this.firestorage.collection("tablones").doc(id);

  tablones = () => this.firestorage.collection("tablones");

  /* Cursos API */
  curso = (id: string) => this.firestorage.collection("cursos").doc(id);

  cursos = () => this.firestorage.collection("cursos");

  // subcripcionTemporal = () =>
  //   this.firestorage.collection("subcripcionTemporal");

  // userSubcripcionTemporal = (email: string) =>
  //   this.firestorage.collection("subcripcionTemporal").doc(email);

  /* Curso API */

  cursoExamen = (docId: string) => this.firestorage.collection("cursos/" + docId + "/examen")

  cursoExamenRespuestas = (docId: string) => this.firestorage.collection("cursos/" + docId + "/examen-respuestas")

  cursoModulo = (docId: string, moduloId: string) =>
    this.firestorage.collection("cursos/" + docId + "/modulos").doc(moduloId);

  cursoModulos = (docId: string) =>
    this.firestorage.collection("/cursos/" + docId + "/modulos");

  cursoModuloLeccion = (cursoId: string, moduloId: string, leccionId: string) =>
    this.firestorage
      .collection("/cursos/" + cursoId + "/modulos/" + moduloId + "/lecciones")
      .doc(leccionId);

  cursoModuloLecciones = (cursoId: string, moduloId: string) =>
    this.firestorage.collection(
      "/cursos/" + cursoId + "/modulos/" + moduloId + "/lecciones"
    );

  leccionesCompletas = (userEmail: string, idCourse: string) =>
    this.firestorage.collection(
      "/users/" +
        userEmail +
        "/progreso-cursos/" +
        idCourse +
        "/lecciones-completas"
    );

  /* Curso Users Materials API */

  cursoAlumnosMateriales = (cursoId: string) =>
    this.firestorage.collection("/cursos/" + cursoId + "/materiales-alumnos");

  cursoAlumnoMaterial = (cursoId: string, emailUser: string) =>
    this.firestorage
      .collection("/cursos")
      .doc(cursoId)
      .collection("/materiales-alumnos")
      .doc(emailUser);

  /* Cursos Venta API*/
  cursoVenta = (id: string) =>
    this.firestorage.collection("cursos-venta").doc(id);

  cursosVenta = () => this.firestorage.collection("cursos-venta");

  getCollectionRef = (collectionName: string) =>
    this.firestorage.collection(collectionName);
}

export default Firebase;
