import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import * as serviceWorker from "./serviceWorker";

import App from "./routes";

import "bootstrap/dist/css/bootstrap.css";
import { FirebaseProvider } from "./Services/Firebase/context";

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <FirebaseProvider>
      <App />
    </FirebaseProvider>,
    rootElement
  );
} else {
  ReactDOM.render(
    <FirebaseProvider>
      <App />
    </FirebaseProvider>,
    rootElement
  );
}

// ReactDOM.render(
//   <FirebaseProvider>
//     <App />
//   </FirebaseProvider>
//   , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
