import styled from "styled-components";

export const Container = styled.div`
    font-family: 'Montserrat';
    max-width: 650px;
    margin: 172px auto;
`

export const Wrapper = styled.div`
    padding: 16px;
`

export const Titulo = styled.h1`
    font-family: 'Montserrat';
    margin-bottom: 55px;
`