import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useFirebaseContext } from "../../../../Services/Firebase";
import { useAuthUser } from "../../../../Services/useAuthUser";

const ExamButton = styled.button`
  width: 217px;
  left: 20px;
  top: 1021px;
  padding: 2px 8px;

  background: rgba(182, 83, 83, 0.77);
  border-radius: 5px;
  border: none;
  display: flex;
  margin-bottom: 30px;

  &.enabled {
    background: rgba(182, 83, 83, 0.77);
    color: white;
    opacity: 1;

    &:hover {
      background-color: rgba(200, 120, 120, 0.77);
    }
  }

  & p {
    font-family: "Montserrat", sans-serif;
    margin: 2px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  }
`;

export const BottonExamen: FC<{ courseId: string }> = ({ courseId }) => {
  const { isAdmin } = useAuthUser();
  const firebase = useFirebaseContext();
  const history = useHistory();

  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    async function getExamen() {
      // Checa si existe un examen para el curso
      const check = await firebase.cursoExamen(courseId).get();
      if (!check.empty) {
        const dataExamen = check.docs[0].data();
        setEnabled(dataExamen.activo);
      }
    }

    getExamen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // use firebase to get examenData y saber si esta activo el examen

  return (
    <ExamButton
      disabled={isAdmin ? false : !enabled}
      className={enabled ? "enabled" : ""}
      onClick={() => {
        history.push(`/curso/${courseId}/examen`);
      }}
      title={enabled ? "" : "Aun no haz reunido los requisitos para el examen"}
    >
      <p>Examen</p>
    </ExamButton>
  );
};
