import React, { FC } from "react";
import styled from "styled-components";

const SwitchStyle = styled.label`
    position: relative;
    display: inline-block;
    width: fit-content;
    min-width: 110px;
    max-width: 256px;
    height: 34px;
    > input {
    display: none;
  }
  .slide {
    margin-top: 0 !important;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    padding: 8px;
    padding-left: 15px;
    color: #fff;
    border-radius: 8px;
    font-size: 14px;
  }
  .slide:before {
    position: absolute;
    border-radius: 50%;
    content: "";
    height: 26px;
    width: 26px;
    left: 78px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  input:checked + .slide {
    padding-left: 40px;
    background-color: #6dd95c;
    border-radius: 8px;
  }
  input:focus + .slide {
    box-shadow: 0 0 1px #01aeed;
  }
  input:checked + .slide:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    left: -20px;
  }
`;

export const SwitchButton: FC<{
  checked: boolean;
  onChange: (e?: any) => void;
  label: string;
  name?: string;
}> = ({ checked, onChange, label, name = '' }) => {
  return (
    <SwitchStyle>
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <div className="slide round">{label}</div>
    </SwitchStyle>
  );
};
