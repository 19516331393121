import React, { createContext, FC } from 'react';
import { useCoursePage } from "./hooks/useCursoPage";

type CursoContextType = ReturnType<typeof useCoursePage>;

const CursoContext = createContext<CursoContextType | {}>({});

export const CursoContextProvider: FC = ({children}) => {

  const contextValue = useCoursePage();  

    return (
        <CursoContext.Provider value={contextValue}>
            {children}
        </CursoContext.Provider>
    )
}


export const useCursoContext = (): CursoContextType => {

    const context = React.useContext(CursoContext);

    if (context === undefined) {
        throw new Error('useCursoContext must be used within a CursoContextProvider');
    }

    return context as CursoContextType;

}