export enum CategoriasCursos {
  Salud = "salud",
  Prosperidad = "prosperidad",
  Relaciones = "relación",
  Gratuito = "gratuito",
}

export type Modulo = {
  name: string;
  id: string;
};

export type MaterialesAlumnos = {
  nombreUsuario: string;
  urlMateriales: string;
};
// hackeado
export type ModuloData = {
  id: string;
  data: {
    index: number;
    lecciones: string[];
    moduloTitulo: string;
    imgDecorativa: string;
  };
};

export type Curso = {
  id: string;
  autor: string;
  nombreCurso: string;
  descripcion: string;
  categoria: string;
  moduleImages: string[];
  courseImg?: string;
  background: string;
  cursoUrl: string;
  subtitulo: string;
  precio?: number;
  precioMensual?: number;
  enVenta?: boolean;
  tipoCurso?: string;
  modulos?: Modulo[];
  materialesAlumnos?: MaterialesAlumnos[];
  customModulos?: any;
  previewBoton?: string;
  previewDescripcion?: string;
  previewTitulo?: string;
  isBought?: boolean;
  completePercentage?: number;
  previewModulos?: ModuloData[];
  stripeConnect?: {
    accountId: string;
    fee: number;
  };
  errorValues?: string[];
  isClosed?: string;
  previewBeneficios?: string[];
  previewImageAlt?: string;
  previewBeneficioImagen?: string;
  previewVideo?: string;
  isDiplomadoFengshui2023?: boolean;
  isDiplomadoBazi2023?: boolean;
  isDiplomadoBaziFengShui?: boolean;
  invisible?: boolean;
  faqPagos?: string;
  isGratuito?: boolean;
};

export type CursoTablon = {
  id: string;
  nombreCurso: string;
  descripcion: string;
  background: string;
  autor: string;
  cursoUrl: string;
  isBought?: boolean;
  subtitulo: string;

  precio: number;
  completePercentage?: number;
  tipoCurso: string;
};

export type Material = {
  description: string;
  file: string;
  image: string;
  name: string;
  id: string;
};

export type Tablon = {
  description: string;
  id: string;
  image: string;
  materiales: Material[];
  name: string;
  venta: boolean;
  price: string;
};

export type Error = {
  code: string;
  message: string;
};

export type RespuestaPreguntaExamen = {
  texto: string;
  correcta: boolean;
};

export type PreguntaExamen = {
  pregunta: string;
  imagen?: string;
  respuestas: RespuestaPreguntaExamen[];
};

export type ExamenCurso = {
  questions: PreguntaExamen[];
  id?: string;
  activo: boolean;
  diploma: {
    formato: string;
    generacion: string;
  };
};

export type UserExamenRespuestas = {
  calificacion: number;
  comentario: string;
  completado: boolean;
  mencionHonorifica: boolean;
  nombreDiploma: string;
  respuestas: RespuestaPreguntaExamen[];
};
