import styled from "styled-components"
import React, { FC } from "react"


const ButtonStyle = styled.button<{ color: string, textColor: string }>`
    background: ${props => props.color};
    border-radius: 8px;
    padding: min(7%, 12px);
    border: none;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: clamp(11px, 2.6vw,13px);
    color: ${props => props.textColor};
    width: fit-content;
    display: block;
    cursor: pointer;
    text-transform: uppercase;
    transition: 0.25s;
    margin: 0 auto;

    :hover {
        transform: scale(1.05);
    }

    &:disabled {
        opacity: 80%;
    }

    &:disabled {
        transform: scale(1);
        opacity: 80%;
        cursor: auto;
    }

    a{
        color: white;

        :hover{
            color: white;
        }
    }

    @media screen and (max-width: 425px){
        margin: 5% 1em 0px auto; 
    }
`

type ElBottonProps = {
    onClick?: ((event?: any) => void) | ((event?: any) => Promise<void>);
    color?: 'azul' | 'gris' | 'verde';
    disabled?: boolean;
    style?: React.CSSProperties;
}

export const ElBotton: FC<ElBottonProps> = ({ children, color, onClick, disabled, style }) => {

    let colorBotton = '#145882';
    let textColor = 'white';

    if (color) {
        switch(color) {
            case 'azul':
                colorBotton = '#145882';
                break;
            case 'gris':
                colorBotton = '#F7F7F7';
                textColor = '#000000';
                break;
            case 'verde':
                colorBotton = '#14824D';
                break;
            default:
                break;
        }
    }

    return(
        <ButtonStyle style={style} disabled={disabled} onClick={onClick} color={colorBotton} textColor={textColor}>
            {children}
        </ButtonStyle>
    )
}