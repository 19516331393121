/* eslint-disable no-useless-escape */
import React, { useState } from 'react'
import root from 'react-shadow/styled-components'
import ModalCommon from "../common/ModalCommon"
import { ElBotton } from "../common/ElBotton"
import { Wrapper, PrivacidadCheck, FormFields } from './styles'
import * as ROUTES from '../../constants/routes'

import modalBookImg from '../../pages/servicios/images/Curso_ebook.png'
import { Link } from 'react-router-dom'

export const QuieroElLibroModal = () => {

    const [modalOpen, setModalOpen] = useState(false);

    const [userEmail, setUserEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [privacy, setPrivacy] = useState(false)

    const handleSaveEmail = () => {
        // TODO. Save the emails on the db
        const data = {
            email: userEmail,
            nombre: userName,
        }
        fetch(process.env.REACT_APP_BACKEND_SERVER_IP+'/libro-gratuito',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            referrerPolicy: 'no-referrer', 
            body: JSON.stringify(data) 
        }).then((response) => {
            if(response.status === 200) {
                window.alert('¡Se le ha enviado el libro a su correo con exito!')
            }
            else {
                window.alert('Hubo un error al solicitar el libro')
            }
        }).catch(reason => {
            console.error(reason)
        })


        setModalOpen(false)
    }

    function validEmail(e: string) {
        var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
        return String(e).search (filter) !== -1;
    }

    const isValid = userName !== '' && validEmail(userEmail) && privacy;

    return (
        <root.div>
            <ModalCommon small isOpen={modalOpen} closeModal={() => setModalOpen(false)} >
                <Wrapper>
                    <div className='libro-modal-mainContainer'>
                        <div className='libro-modal-formulario'>
                            <h1>Cómo convertir tu casa en un Imán de éxito</h1>
                            <p className='description'>
                                Manual práctico para<br/>
                                transformar tus espacios en<br/>
                                un imán de prosperidad
                            </p>

                            <FormFields>
                                <label>Nombre</label>
                                <input  
                                    value={userName}
                                    onChange={(e) => setUserName(e.target.value)}
                                    placeholder="Tu nombre"
                                ></input>
                                
                                <label>Correo</label>
                                <input  
                                    value={userEmail}
                                    onChange={(e) => setUserEmail(e.target.value)}
                                    placeholder="Tu correo"
                                ></input>
                            </FormFields>

                            <PrivacidadCheck>
                                <input checked={privacy} onChange={() => { setPrivacy(!privacy)}} type="checkbox" />
                                <p>He leído y acepto la <Link to={ROUTES.AVISO_PRIVACIDAD} >política de privacidad</Link></p>
                            </PrivacidadCheck>
                            <ElBotton disabled={!isValid} style={{margin: 0, width: '100%'}} onClick={handleSaveEmail}>
                                QUIERO MI LIBRO GRATIS AHORA &gt;&gt;
                            </ElBotton>
                        </div>
                        <div className='libro-modal-imagen'>
                            <img src={modalBookImg} alt="Libro Gratuito" />
                        </div>
                    </div>
                    <p className='description'>Te enviaremos un correo electrónico con el link para descargar el libro</p>
                </Wrapper>
            </ModalCommon>
            <ElBotton style={{margin: '0 auto'}} onClick={() => setModalOpen(true)}>
                QUIERO EL LIBRO
            </ElBotton>
        </root.div>
    )
}
