import React from "react";
import styled from "styled-components";

const AboutMetafisica = () => {
  return (
    <Container>
      <h3>¿Qué puede hacer la metafísica china por mi?</h3>
      <p>
        Según la metafísica china, somos un organismo compuestos por tres sistemas: mente, materia y espíritu. Cuando algún sistema esta débil, tu
        organismo manifestará una enfermedad (prosperidad, relaciones, salud, entre otros). Mis servicios estan destinados a potencializar cada
        sistema para crear una vida conectada con absoluta prosperidad.
      </p>
      <ul>
        <MetafisicaCardListElement>
          <div className="card-img-container">
            <div>
              <img src="https://firebasestorage.googleapis.com/v0/b/web-arlene.appspot.com/o/assets%2Fcursos-preview%2FPAGINA-05.jpg?alt=media&token=5ecb0262-d742-4745-914c-44f1137d8927" alt="Materia" style={{ opacity: "0.7" }} />
            </div>
          </div>
          <div className="card-text-container">
            <h3>Materia</h3>
            <p>Explota la energia de tu casa u oficina para alinearse con tu vida.</p>
          </div>
        </MetafisicaCardListElement>

        <MetafisicaCardListElement>
          <div className="card-img-container">
            <div>
              <img src="https://firebasestorage.googleapis.com/v0/b/web-arlene.appspot.com/o/assets%2Fcursos-preview%2FPAGINA-06.jpg?alt=media&token=56572e92-26bb-4974-8447-de9619e8914b" alt="Materia" />
            </div>
          </div>
          <div className="card-text-container">
            <h3>Mente</h3>
            <p>Activar y desactivar patrones mentales que estan obstaculizando el éxito.</p>
          </div>
        </MetafisicaCardListElement>

        <MetafisicaCardListElement>
          <div className="card-img-container">
            <div>
              <img src="https://firebasestorage.googleapis.com/v0/b/web-arlene.appspot.com/o/assets%2Fcursos-preview%2FPAGINA-07.jpg?alt=media&token=5cc3c955-261e-44a0-84b9-4084ffbd5938" alt="Materia" style={{ opacity: "0.7" }} />
            </div>
          </div>
          <div className="card-text-container">
            <h3>Espíritu</h3>
            <p>Sanar traumas, heridas y ser conciente de la potencia de tu alma.</p>
          </div>
        </MetafisicaCardListElement>
      </ul>
    </Container>
  );
};

const Container = styled.div`
  margin: 33px 0;
  font-family: "Montserrat";
  line-height: 20px;

  h3 {
    font-weight: 700;
    text-align: center;
    font-size: min(3.7vw, 20px);
    margin-bottom: min(8%, 54px);
  }

  p {
    word-break: break-word;
    font-size: min(2.9vw, 14px);
    text-align: justify;
    margin-bottom: min(5%, 35px);
  }

  ul {
    display: flex;
    padding: 0 25px;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0 5%;

    h3 {
      padding: 0 10%;
    }

    p {
      line-height: 155%;
      padding: 0 5%;
    }
  }
`;

const MetafisicaCardListElement = styled.li`
  width: 23%;
  display: block;

  .card-img-container {
    height: 262px;
    width: 100%;
    background: #fef7f8;
    display: flex;

    > div {
      width: 65%;
      margin: auto;
      height: auto;

      img {
        max-width: 100%;
        display: block;
      }
    }
  }

  .card-text-container {
    h3 {
      margin: 13px 0;
    }

    p {
      text-align: center;
    }
  }

  @media screen and (max-width: 768px) {
    background: #fef7f8;
    width: 100%;
    display: flex;
    margin-bottom: 3.5%;

    .card-img-container {
      height: auto;
      background: none;

      > div img {
        width: 20vw;
      }
    }

    .card-text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;

export default AboutMetafisica;
