import React from "react";
import { useCursoContext } from "../CursoContext";
import { useAuthUser } from "../../../Services/useAuthUser"

const web2Url = process.env.REACT_APP_WEB2_URL;

export const PaginaForo = () => {

    const { courseData } = useCursoContext();
    const authUser = useAuthUser();

    const data = {
        cursoId: courseData?.id,
        nombreCurso: courseData?.data.nombreCurso,
        user: {
            isAdmin: authUser?.isAdmin,
            email: authUser?.authUser.email,
            username: authUser?.authUser.username,
        }
    }
    // convert to a base64 string
    const codedData = Buffer.from(JSON.stringify(data)).toString('base64');

  return (
    <iframe
      title="foro"
      src={web2Url + "/forum?data=" + codedData}
      width="100%"
      height="100%"
      frameBorder={0}
    ></iframe>
  );
};
