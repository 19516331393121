import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import YouTube, { YouTubeProps } from "react-youtube";

/*
    Este componente se encarga de renderizar el video, sea Youtube o Archivo, al mismo tiempo que 
    monitorea el uso del usuario del video y asegurarse si vio la clase correctamente 
*/

type VideoPlayerProps = {
  video: [videoType: string, videoUrl: string];
  setLessonComplete: (value: boolean) => void;
};

const VideoPlayer: FC<VideoPlayerProps> = ({ video, setLessonComplete }) => {
  const [currentTimeSeconds, setCurrentTime] = useState<number>(0);
  const [videoDuration, setDuration] = useState<number>(0);
  const lessonCompletePercentage = 0.9;

  const onTimeChange = (e: any) => {
    if (Math.floor((e.target.currentTime / e.target.duration) * 100) >= 90) {
      setLessonComplete(true);
    }
  };

  const opts: YouTubeProps["opts"] = {
    height: "100%",
    width: "100%",
    wmode: "transparent",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      //autoplay: 1,
    },
  };

  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    updateTime(event.target);
  };

  function updateTime(youtubePlayer: any) {
    setDuration(youtubePlayer.getDuration());

    setInterval(() => {
      if (youtubePlayer.getCurrentTime() !== currentTimeSeconds) {
        setCurrentTime(Math.round(youtubePlayer.getCurrentTime()));
      }
    }, 1000);
  }

  useEffect(() => {
    if (currentTimeSeconds >= videoDuration * lessonCompletePercentage)
      setLessonComplete(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTimeSeconds]);


  const videoRef = React.createRef<HTMLVideoElement>();
  const url = video[1];
  useEffect(() => {
    videoRef.current?.load();
  }, [url, videoRef]);

  return (
    <Container>
      {video[0] === "youtube" && (
        <YouTube
          className="video"
          videoId={video[1].slice(video[1].lastIndexOf("/") + 1)}
          opts={opts}
          onReady={onPlayerReady}
        />
      )}

      {video[0] === "video" && (
        <video
          onTimeUpdate={(e: any) => {
            onTimeChange(e);
          }}
          width="320"
          height="240"
          className="video"
          controls
          ref={videoRef}
        >
          <source src={url} type="video/mp4" />
          Tu navegador no soporta videos mp4, contacte con soporte técnico.
        </video>
      )}
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  background: black;
  z-index: 99;

  .video {
    width: 100%;
    height: 100%;
  }
`;

export default VideoPlayer;
