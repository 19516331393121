import React, { FC } from "react";
import styled from "styled-components";
import pdfIcon from "./pdf.png";
import imageIcon from "./image.png";
import TrashCanSvg from "./Navegacion/trash-alt-solid.svg";
import { useAuthUser } from "../../../Services/useAuthUser";

type MaterialCardTypes = {
  cardData: string;
  userEmail?: string;
  refresh: () => void;
  deleteMaterial: (urlFormated: string, url: string) => Promise<any>;
};

export const MaterialCard: FC<MaterialCardTypes> = ({
  cardData,
  refresh,
  userEmail,
  deleteMaterial,
}) => {
  const fileNameAndExtension = cardData.slice(cardData.lastIndexOf("@") + 1);

  const { isAdmin, authUser} = useAuthUser();

  const fileUrl = cardData.slice(0, cardData.lastIndexOf("@"));
  const baseUrl =
    "https://firebasestorage.googleapis.com/v0/b/" +
    process.env.REACT_APP_STORAGE_BUCKET +
    "/o/";

  let imagePath: string = fileUrl.replace(baseUrl, "");
  const indexOfEndPath = imagePath.indexOf("?");
  imagePath = imagePath.substring(0, indexOfEndPath);
  imagePath = imagePath.replaceAll("%2F", "/");

  const fileName = fileNameAndExtension.slice(
    0,
    fileNameAndExtension.lastIndexOf(".")
  );
  const fileNameShort =
    fileName.length > 16 ? fileName.slice(0, 16) + "..." : fileName;

  function getIconByExtension() {
    switch (cardData.slice(cardData.lastIndexOf(".") + 1)) {
      case "pdf":
        return pdfIcon;
      case "png":
        return imageIcon;
      case "jpg":
        return imageIcon;
      case "jpeg":
        return imageIcon;
    }

    return "";
  }

  async function handleDeleteMaterial() {
    const confirmation = window.confirm(
      `Desea borrar el archivo ${fileNameAndExtension}`
    );

    if (confirmation) {
      await deleteMaterial(imagePath, cardData);
      refresh();
    }
  }

  const url = cardData.slice(0, cardData.lastIndexOf("@"));

  return (
    <CardContainer>
      <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      download={fileNameAndExtension}
      >
        <img src={getIconByExtension()} alt="icono del material" />
      </a>
      <p>{fileNameShort}</p>
      {isAdmin === true || authUser.email === userEmail ? (
        <img
          src={TrashCanSvg}
          alt=""
          className="fas fa-trash-alt"
          onClick={handleDeleteMaterial}
        />
      ) : null}
    </CardContainer>
  );
};

// ${MaterialDimensions}
const CardContainer = styled.div`
  position: relative;

  p {
    margin: 0;
    line-height: 13px;
    max-width: 80px;
    overflow-wrap: break-word;
    text-align: center;
    color: white;
    margin-top: 3px !important;

    font-size: 12px !important;
    padding: 0 3px;
  }

  a {
    cursor: pointer;
    text-align: center;
    padding-bottom: 30px;
    img {
      width: 64px;
      height: auto;
    }
  }

  > img {
    position: absolute;
    width: 20%;
    top: 0;
    right: 5px;
    cursor: pointer;

    :hover {
      filter: invert(38%) sepia(70%) saturate(911%) hue-rotate(-389deg)
        brightness(83%) contrast(123%);
    }
  }
`;
