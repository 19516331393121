import { useHistory } from "react-router-dom";
import { Curso } from "../../../constants/types";
import { useFirebaseContext } from "../../../Services/Firebase/context";

export const useCoursePreview = () => {
  const firebase = useFirebaseContext();
  const history = useHistory();

  function getCoursePreviewByUrl(courseUrl: string) {
    return new Promise<Curso>(async (resolve, reject) => {
      try {
        const snapshotCurso = await firebase.getCollectionRef("cursos").where("cursoUrl", "==", courseUrl).get();
        let cursoData: Curso | any;

        snapshotCurso.forEach((data) => {
          cursoData = { id: data.id, ...data.data() };
        });

        const snapshotPreviewModules = await firebase
          .getCollectionRef("cursos")
          .doc(cursoData.id)
          .collection("preview-modulos")
          .orderBy("index")
          .get();
        let previewModulos: any = [];

        snapshotPreviewModules.forEach((modulo) => {
          previewModulos.push({ id: modulo.id, data: modulo.data() });
        });

        cursoData = { ...cursoData, previewModulos };

        resolve(cursoData);
      } catch (error) {
        console.error(error);
        history.replace("/cursos");
      }
    });
  }

  function addCoursePreviewData(courseId: string, data: object) {
    return new Promise<string>(async (resolve, reject) => {
      try {
        const snapshotCurso = firebase.getCollectionRef("cursos").doc(courseId);
        snapshotCurso
          .update(data)
          .then(() => {
            resolve("Se agrego la informacion la pagina preview");
          })
          .catch((error) => {
            reject(`No se pudo agregar la informacion a la pagina\n\n${error}`);
          });
      } catch (error) {
        reject(`No se pudo agregar la informacion a la pagina\n\n${error}`);
      }
    });
  }

  function addPreviewModuleLessonData(data: object, courseId: string, moduleId?: string) {
    return new Promise<string>(async (resolve, reject) => {
      try {
        let snapshotCurso;

        if (moduleId) snapshotCurso = firebase.getCollectionRef("cursos").doc(courseId).collection("preview-modulos").doc(moduleId);
        else snapshotCurso = firebase.getCollectionRef("cursos").doc(courseId).collection("preview-modulos").doc();

        if (moduleId)
          snapshotCurso
            .update(data)
            .then(() => {
              resolve("Se agrego la informacion la pagina preview");
            })
            .catch((error) => {
              reject(`No se pudo agregar la informacion a la pagina\n\n${error}`);
            });
        else
          snapshotCurso
            .set(data)
            .then(() => {
              resolve("Se agrego la informacion la pagina preview");
            })
            .catch((error) => {
              reject(`No se pudo agregar la informacion a la pagina\n\n${error}`);
            });
      } catch (error) {
        reject(`No se pudo agregar la informacion a la pagina\n\n${error}`);
      }
    });
  }

  function UpdatePreviewModule(courseId: string, moduleId: string, payload: any) {
    return new Promise<string>(async (resolve, reject) => {
      try {
        const snapshotCurso = firebase.getCollectionRef("cursos").doc(courseId).collection("preview-modulos").doc(moduleId);

        snapshotCurso
          .update(payload)
          .then(() => {
            resolve("Se actualizo el modulo correctamente");
          })
          .catch((error) => {
            reject(`No se pudo actualizar el documento\n\n${error}`);
          });
      } catch (error) {
        reject(`No se pudo actualizar el documento\n\n${error}`);
      }
    });
  }

  function deletePreviewModule(courseId: string, moduleId: string) {
    return new Promise<string>(async (resolve, reject) => {
      try {
        const snapshotCurso = firebase.getCollectionRef("cursos").doc(courseId).collection("preview-modulos").doc(moduleId);

        snapshotCurso
          .delete()
          .then(() => {
            resolve("Se elimino el modulo correctamente");
          })
          .catch((error) => {
            reject(`No se eliminar el modulo\n\n${error}`);
          });
      } catch (error) {
        reject(`No se eliminar el modulo\n\n${error}`);
      }
    });
  }

  return {
    getCoursePreviewByUrl,
    addCoursePreviewData,
    addPreviewModuleLessonData,
    deletePreviewModule,
    UpdatePreviewModule,
  };
};
