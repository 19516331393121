/* eslint-disable no-useless-escape */
import styled from "styled-components";
import React, { useEffect, useState, FC } from "react";
import BackgroundUploadInput from "../BackgroundUploadInput";
import { useCourseCreator } from "../../hooks/useCourseCreator";

import {
  CourseCreatorForm,
  BackgroundUpload,
  CreatorForm,
  DivBottom,
} from "./style";
import { CoursePaymentConfig } from "../CoursePaymentConfig";
import { useAuthUser } from "../../../../Services/useAuthUser";
import { SwitchButton } from "../../../../components/common/SwitchButton";

const categorias = ["salud", "prosperidad", "relación", "felicidad"];

type CourseCreatorProps = {
  setCursoData: any; //this is a set state
  cursoData: any; // TODO: look for the type
  courseToEdit: string;
  categoriaFilter: string; // para prepopular nuevos cursos
  isEnable: boolean;
  refreshCourses: () => void;
  closeCourseCreator: () => void;
  setIsCourseFormEnabled: (state: boolean) => void;
};

const CourseCreator: FC<CourseCreatorProps> = ({
  cursoData,
  setCursoData,
  courseToEdit,
  categoriaFilter,
  isEnable,
  refreshCourses,
  closeCourseCreator,
  setIsCourseFormEnabled,
}) => {
  const { authUser } = useAuthUser();
  const [backgroundFile, setBackgroundFile] = useState();
  const [subiendo, setSubiendo] = useState(false);
  const { createCourse, updateCourseById, updateBackground, uploadBackground } =
    useCourseCreator();

  function handleChange(event: any) {
    if (event.target.type === "checkbox") {
      setCursoData({
        ...cursoData,
        [event.target.name]: event.target.checked,
      });
    } else {
      setCursoData({
        ...cursoData,
        [event.target.name]: event.target.value,
      });
    }
  }

  async function handleCreateCurso(cursoURL: string) {
    let newDocId = "";

    try {
      const docRef = await createCourse({
        ...cursoData,
        cursoUrl: cursoURL,
        precio: Number(precio),
        precioMensual: Number(precioMensual),
        userCreador: authUser.uid,
        background: "",
        createdAt: new Date(),
        invisible: true,
      });

      newDocId = docRef.id;
    } catch (error: any) {
      // deleteCourse(docRef.id);
      alert("Error al crear el curso: " + error.message);
      return;
    }

    try {
      await uploadBackground(backgroundFile, newDocId);
    } catch (error) {
      alert("Error al subir el fondo del curso, por favor intenta de nuevo");
    }
  }

  async function handleUpdateCurso(cursoURL: string) {
    // Edita un curso existente
    const isTablon = tipoCurso === "tablon";

    try {
      await updateCourseById(
        courseToEdit,
        {
          ...cursoData,
          precio: Number(precio),
          precioMensual: Number(precioMensual),
          cursoUrl: cursoURL,
        },
        isTablon
      );
    } catch (error: any) {
      alert("Error al editar el curso: " + error.message);
      return;
    }

    if (backgroundFile) {
      await updateBackground(backgroundFile, courseToEdit, isTablon);
    }
  }

  async function handleSubmit(event: any) {
    event.preventDefault();
    setSubiendo(true);

    const cursoURL = cursoData.nombreCurso
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replaceAll(" ", "-");

    // Crear un nuevo curso
    if (!courseToEdit) {
      await handleCreateCurso(cursoURL);
      refreshCourses();
      setIsCourseFormEnabled(false);
    } else {
      await handleUpdateCurso(cursoURL);
      refreshCourses();
    }

    setSubiendo(false);
  }

  useEffect(() => {
    // Asigna el valor de la categoria filtrada cuando se ha elegido una categoria en el filtro
    // para que asi al abrir el panel de creacion de curso este preseleccionada
    if (cursoData.categoria === "" && categoriaFilter !== cursoData.categoria)
      setCursoData({
        ...cursoData,
        categoria: categoriaFilter,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriaFilter]);

  const {
    precio,
    precioMensual,
    autor,
    background,
    categoria,
    descripcion,
    nombreCurso,
    subtitulo,
    tipoCurso,
    invisible,
    promocionado,
    isGratuito,
  } = cursoData;

  const isInvalid =
    subiendo === true ||
    autor === "" ||
    nombreCurso === "" ||
    descripcion === "" ||
    categoria === "" ||
    background === "";

  return (
    <>
      <CourseCreatorShadow onClick={closeCourseCreator} isEnable={isEnable} />
      <CourseCreatorForm enable={isEnable}>
        {/* quizas un solo componente pa los stylos y asi */}
        <BackgroundUpload>
          <BackgroundUploadInput
            cursoData={cursoData}
            setBackground={setCursoData}
            setBackgroundFile={setBackgroundFile}
          />
        </BackgroundUpload>

        <CreatorForm>
          <form onSubmit={handleSubmit}>
            <div className="formWrapper">
              <input
                name="nombreCurso"
                type="text"
                value={nombreCurso || ""}
                onChange={handleChange}
                className="createCourse-text"
                placeholder="Nombre del Curso"
                required={true}
              />
              <textarea
                name="descripcion"
                value={descripcion || ""}
                onChange={handleChange}
                className="createCourse-text"
                placeholder="Descripción del Curso"
                required={true}
              />
              <DivBottom>
                <div>
                  {tipoCurso !== "tablon" && (
                    <>
                      <select style={{ display: "none" }}></select>
                      <label htmlFor="categoria">Categoría:</label>
                      <select
                        name="categoria"
                        value={cursoData.categoria}
                        onChange={handleChange}
                      >
                        <option value="">Elija una categoría</option>

                        {categorias?.map((value: string) => (
                          <option key={value} value={value}>
                            {value.charAt(0).toUpperCase() + value.slice(1)}
                          </option>
                        ))}
                      </select>
                    </>
                  )}

                  {!courseToEdit && (
                    <>
                      <label htmlFor="precio">Precio:</label>
                      <input
                        name="precio"
                        type="text"
                        value={precio || 0}
                        onChange={handleChange}
                        className="createCourse-text"
                        placeholder="Precio del curso"
                        required={true}
                      />

                      <label htmlFor="precioMensual">Precio Mensual:</label>
                      <input
                        name="precioMensual"
                        type="text"
                        value={precioMensual || 0}
                        onChange={handleChange}
                        className="createCourse-text"
                        placeholder="Precio Mensual del curso"
                        required={true}
                      />
                    </>
                  )}

                  {/* Toggle para si es visible. */}
                  {courseToEdit && (
                    <>
                      <br />
                      <SwitchButton
                        label="Invisible"
                        name="invisible"
                        checked={invisible || false}
                        onChange={handleChange}
                      />
                      <label style={{ marginTop: "0px" }}>
                        {invisible
                          ? "Solo se mostrará a Admins y personas que ya hayan adquirido el curso"
                          : "Todos los usuarios pueden ver el curso"}
                      </label>
                      <br />
                    </>
                  )}

                  {/* Podemos empezar a quitar esto?? */}
                  {tipoCurso !== "tablon" && (
                    <>
                      <label htmlFor="tipoCurso">Tipo Curso:</label>
                      <select
                        name="tipoCurso"
                        value={cursoData.tipoCurso}
                        onChange={handleChange}
                      >
                        <option value="curso">Curso</option>
                        <option value="diplomado">Diplomado</option>
                      </select>
                    </>
                  )}

                  {courseToEdit && (
                    <CoursePaymentConfig
                      cursoData={cursoData}
                      courseId={courseToEdit}
                      setCursoData={setCursoData}
                      submit={handleSubmit}
                    />
                  )}
                </div>
                <div>
                  <label htmlFor="autor">
                    Este Curso será creado a nombre de:
                  </label>
                  <input
                    name="autor"
                    type="text"
                    value={autor || ""}
                    onChange={handleChange}
                    className="createCourse-text"
                    placeholder="Nombre del Autor"
                    required={true}
                  />
                  <br />
                  <label htmlFor="autor">Subtítulo del curso:</label>
                  <input
                    name="subtitulo"
                    type="text"
                    value={subtitulo || ""}
                    onChange={handleChange}
                    className="createCourse-text"
                    placeholder="Subtitulo del curso"
                    required={true}
                  />

                  {courseToEdit && (
                    <>
                      <br />
                      <SwitchButton
                        label="Promocionado"
                        name="promocionado"
                        checked={promocionado || false}
                        onChange={handleChange}
                      />
                      <label style={{ marginTop: "0px" }}>
                        {promocionado ? "En promoción" : "Poner en promoción"}
                      </label>
                      {/* TOGGLE GRATUITO */}
                      <br />
                      <SwitchButton
                        label="Gratuito"
                        name="isGratuito"
                        checked={isGratuito || false}
                        onChange={handleChange}
                      />
                      <label style={{ marginTop: "0px" }}>
                        {isGratuito ? "Gratuito" : "Hacer gratuito"}
                      </label>
                      <br />
                    </>
                  )}
                </div>
              </DivBottom>
            </div>
            <button
              style={{ minHeight: "24px" }}
              type="submit"
              disabled={isInvalid}
            >
              {courseToEdit ? "Click para guardar cualquier cambio" : "Crear Curso"}
            </button>
          </form>
        </CreatorForm>
      </CourseCreatorForm>
    </>
  );
};

export const CourseCreatorShadow = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  ${(props) =>
    props.isEnable ? "opacity: 1" : "opacity: 0; pointer-events: none"};
  z-index: 2;
  background: ${(props) => (props.isEnable ? "#00000038" : "transparent")};
  transition: 0.8s;
`;

export default CourseCreator;
