import React, { useRef, useState } from "react";
import styled from "styled-components";
import { ElBotton } from "../../components/common/ElBotton";
import { useAuthUser } from "../../Services/useAuthUser";
import { useFirebaseContext } from "../../Services/Firebase/context";

export const FormularioAvatar = () => {
  const { authUser } = useAuthUser();
  const firebase = useFirebaseContext();

  const [isLoading, setIsLoading] = useState(false);

  const inputRef = useRef<any>(null);

  async function uploadUserImage(emailUser: string, image: any) {
    const storageRef = firebase.storage.ref(`users/${emailUser}/userImage`);
    try {
      await storageRef.put(image);
      const url = await storageRef.getDownloadURL();
      // guardar el url como avatar en el objeto del usuario
      await firebase.user(emailUser).update({ avatar: url });
      // actualizar el localstorage
      localStorage.setItem("authUser", JSON.stringify({...authUser, avatar: url}));
      alert("imagen subida con exito");
    } catch (error: any) {
      alert("no se pudo subir la imagen: " + error.message);
    }
  }

  async function handleChange(e: any) {
    e.stopPropagation();

    if (authUser) {
      setIsLoading(true);
      await uploadUserImage(authUser?.email, e.target.files[0]);
      setIsLoading(false);
      e.target.value = null;
    }
  }

  return (
    <FormularioAvatarStyles>
      {isLoading ? (
        <span>Subiendo imagen...</span>
      ) : (
        <img
          src={
            authUser?.avatar ||
            authUser?.socialMediaPhotoUrl ||
            "https://firebasestorage.googleapis.com/v0/b/web-arlene.appspot.com/o/assets%2FdefaultUserImg.jpg?alt=media&token=c49c3148-1de6-4f76-9efd-7f577903273d"
          }
          title="Subir Material para este modulo"
          // onClick={() => inputRef.current?.click()}
          alt="user profile"
        />
      )}
      <ElBotton onClick={() => inputRef.current?.click()}>
        Subir Imagen
      </ElBotton>
      <Input
        name="background-file"
        type="file"
        id="background-upload"
        title=" "
        ref={inputRef}
        accept=".jpg, .png"
        style={{ color: "transparent" }}
        onChange={handleChange}
      />
    </FormularioAvatarStyles>
  );
};

const FormularioAvatarStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    border-radius: 50%;
    width: 254px;
    height: 254px;
    margin-bottom: 12px;
  }
`;

const Input = styled.input`
  ::-webkit-file-upload-button {
    visibility: hidden;
  }
  pointer-events: none;
  cursor: pointer;
  position: relative;
  width: inherit;
  height: inherit;
  background-origin: border-box;
  background-position: top;
  background-size: cover;
  overflow: hidden;
`;
