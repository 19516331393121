import React, { useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as ROUTES from "../../../../constants/routes";

import UserMenu from "./UserMenu";
import { useAuthUser } from "../../../../Services/useAuthUser";
import { DrawerToggleButton } from "./DrawerToggleButton";

const Toolbar = (props: any) => {
  const { authUser } = useAuthUser();
  var prevScrollpos = window.pageYOffset;
  const toolbarRef = useRef(null);

  hideOnScroll(prevScrollpos, toolbarRef);

  return (
    <ToolbarStyle ref={toolbarRef}>
      <nav>
        <div className="logo">
          <a href={ROUTES.LANDING}>
            <img src="https://firebasestorage.googleapis.com/v0/b/web-arlene.appspot.com/o/assets%2Farlenecolor.png?alt=media&token=3f4f3196-8f50-4705-a2b7-411d01996c64" alt="companie logo" />
          </a>
        </div>
        <div className="toolbar_navigation-items">
          <ul style={{ position: "relative" }}>
            <li>
              <Link className="nav-link" to={ROUTES.HOME}>
                Cursos
              </Link>
            </li>
            <li>
              <a className="nav-link" href={ROUTES.ABOUT}>
                Sobre mi
              </a>
            </li>
            <li>
              <a className="nav-link" href={ROUTES.APLICACIONES}>
                Aplicaciones
              </a>
            </li>
            {/* <MenuDesplegable /> */}
            <li>
              <a href={ROUTES.SERVICIOS} className="nav-link">Servicios</a>
            </li>
            <li>
              <a href={ROUTES.BLOG} className="nav-link">Blog</a>
            </li>
            {authUser ? (
              <UserMenu />
            ) : (
              <li onClick={props.click}>
                <Link className="nav-link" to={ROUTES.SIGN_IN}>
                  Iniciar Sesión
                </Link>
              </li>
            )}
          </ul>
        </div>
        <DrawerToggleButton click={props.drawerClickHandler} />
      </nav>
    </ToolbarStyle>
  );
};

const hideOnScroll = (prevScrollpos: number, toolbarRef: any) => {
  window.onscroll = function () {
    var currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
      toolbarRef.current.style.top = "0";
    } else {
      toolbarRef.current.style.top = "-51px";
    }
    prevScrollpos = currentScrollPos;
  };
};

const ToolbarStyle = styled.header`
  z-index: 10;
  font-family: 'Buda' !important;
  height: 50px;
  width: 100%;
  background: white;
  border-bottom: 1px solid #f5f5f5;
  box-sizing: content-box;
  image-rendering: -webkit-optimize-contrast;
  transition: top 0.3s;

  .toolbar_navigation-items {
    padding-right: 1.5%;
    height: 100%;
  }

  .toolbar_navigation-items ul {
    position: relative;
    height: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .toolbar_navigation-items li {
    padding: 6px 1.4rem 0;
  }

  .toolbar_navigation-items a,
  .toolbar_navigation-items p {
    padding: 0;
    margin: 0;
    color: rgb(70, 70, 70);
    text-decoration: none;
    /* font-family: "Buda"; */
    text-transform: uppercase;
    font-weight: 300;
    font-size: 11px;
    text-align: center;
    text-shadow: 0px 0px 1px #0000004d;
  }

  .toolbar_navigation-items p {
    cursor: pointer;
  }

  .toolbar_navigation-items a:hover,
  .toolbar_navigation-items a:active {
    color: #1b1b1b;
    text-decoration: none;
  }

  @media (max-width: 920px) {
    .toolbar_navigation-items {
      display: none;
    }
  }

  @media (min-width: 920px) {
    .logo {
      margin-left: 0;
    }
  }

  nav {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 1rem;
    border-bottom: 1px solid #d6d3d3;
    justify-content: space-between;

    .logo {
      margin-left: 1rem;

      img {
        height: 50px;
        width: auto;
        padding: 3%;
      }
    }

    @media (max-width: 920px) {
      padding: 0 1.5rem 0 1rem;
      .logo {
        margin-left: -0.5rem;
      }
    }
  }
`;

export default Toolbar;