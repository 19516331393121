import React, { FC, useState } from "react";
import { PreguntaExamen } from "../../../../constants/types";
import { useExamenContext } from "../../ExamenContext";

import styled from "styled-components";
import { QuestionsForm } from "./QuestionsForm";
import { useFirebaseContext } from "../../../../Services/Firebase";



type QuestionsListProps = {};

export const QuestionsList: FC<QuestionsListProps> = () => {
  const { examenData, idCourse, getExamen } = useExamenContext();

  const firebase = useFirebaseContext();

  const [editandoIndex, setEditandoIndex] = useState<null | number>(null);

  function editarPregunta(index: number) {
    setEditandoIndex(index);
  }

  async function borrarPregunta(index: number) {
    const newQuestions = [...examenData.questions];

    newQuestions.splice(index, 1);

    if(window.confirm('¿Estas seguro de borrar esta pregunta?')) {
      await firebase.curso(idCourse).collection("examen").doc(examenData.id).update({
        questions: newQuestions,
      }).then(async () => {
        await getExamen();
      }).catch((err: any) => {
        alert('No se pudo borrar la pregunta: '+ err.message);
      });
    };

  }


  return (
    <Wrapper>
      {/* Muestra un listado de las preguntas del examen */}
      <h3>Preguntas del examen</h3>
      <div>
        {examenData?.questions.map(
          (question: PreguntaExamen, index: number) => (
            <div key={index}>
              {editandoIndex !== index && (
                <PreguntaListContainer>
                  <div style={{display: 'flex', gap: '8px'}}>
                    <h5>
                      {index + 1}. {question.pregunta}
                    </h5>
                    <button onClick={() => editarPregunta(index)}>
                      Editar
                    </button>
                    <button onClick={() => borrarPregunta(index)}>
                      Borrar
                    </button>
                  </div>

                  {question?.imagen !== "" && (
                    <img src={question.imagen} alt="" />
                  )}

                  {question.respuestas.map((respuesta, index) => (
                    <p
                      className={respuesta.correcta ? "correcta" : ""}
                      key={index}
                    >
                      {respuesta.texto}
                    </p>
                  ))}
                </PreguntaListContainer>
              )}

              {editandoIndex === index && (
                <QuestionsForm
                  edicion
                  cerrarEdicion={() => setEditandoIndex(null)}
                  index={editandoIndex}
                  pregunta={examenData.questions[editandoIndex]}
                />
              )}
            </div>
          )
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 16px;
`;

const PreguntaListContainer = styled.div`
  padding: 8px;
  margin-bottom: 16px;

  > p {
    border: 1px solid gray;
    border-radius: 4px;
    padding: 8px;
    margin-left: 8px;

    &.correcta {
      border-color: green;
      background-color: rgba(0, 100, 0, 0.3);
    }
  }

  > img {
    max-height: 512px;
    width: auto;
    max-width: 90vw;
  }
`;
