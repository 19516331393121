import React, { FC, useRef } from "react";
import styled, { css } from "styled-components";
import { useCourses } from "../../hooks/useCourses";

type MaterialCreatorProps = {
  idConstants: { idCourse: string; idModule: string };
  materials: string[];
  refresh: () => void;
};

const MaterialCreator: FC<MaterialCreatorProps> = ({
  idConstants,
  materials,
  refresh,
}) => {
  const { uploadFile, updateValuesOfModule } = useCourses();
  const inputRef = useRef<any>(null);

  const [subiendo, setSubiendo] = React.useState(false);

  async function handleUpload(e: any) {
    e.stopPropagation();
    const file = e.target.files[0];

    const filename = file.name;

    if (filename.includes(" ")) {
      alert("El nombre del archivo no puede contener espacios");
      return;
    }

    if (file) {
      setSubiendo(true);

      const path = `/cursos/${idConstants.idCourse}/${idConstants.idModule}/materiales/${file.name}`;

      // uploadFile(path, file).then((resolve) => {
      const resolve = await uploadFile(path, file);
      let newMaterials: string[] = materials || [];

      newMaterials.push(resolve + "@" + file.name);
      await updateValuesOfModule(idConstants.idCourse, idConstants.idModule, {
        materiales: newMaterials,
      });

      setSubiendo(false);

      refresh();
    }
  }

  return (
    <CreatorContainer>
      {subiendo ? (
        <i className="fas fa-spinner fa-spin"></i>
      ) : (
        <i
          className="fas fa-plus-circle"
          onClick={() => inputRef.current?.click()}
          title="Subir Material para este modulo"
        ></i>
      )}
      <input
        type="file"
        accept=".jpg, .png, .pdf"
        ref={inputRef}
        onChange={handleUpload}
      />
    </CreatorContainer>
  );
};

const MaterialDimensions = css`
  width: 80px;
  height: 110px;
`;
const CreatorContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  ${MaterialDimensions}

  & i {
    color: #61645e;
    font-size: 60px;
    transition: 0.5s;
    cursor: pointer;

    &:hover {
      color: white;
    }
  }

  & input {
    color: transparent;
    height: 0px;
    width: 0px;
  }
  & input[type="file"] {
    opacity: 0;
    height: 0px;
    width: 0px;
  }
`;

export default MaterialCreator;
