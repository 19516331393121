import styled from 'styled-components';

export const Box = styled.div`
    
    padding: 80px 60px;
    background: black;
    position: relative;
    bottom: 0;
    width: 100% !important;
    margin-top: 10%;

    @media ( max-width: 1000px ) {
            padding: 70px 30px;
    }
`;

export const Container = styled.div` 
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;

`;

export const Column = styled.div` 
    
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 60px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
`;

export const Row = styled.div` 
    
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
    grid-gap: 20px;

    @media (max-width: 1000px) {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
`;

export const FooterLink = styled.a` 
    
    color: white;
    margin-bottom: 20px;
    font-size: 12px;
    text-decoration: none;
    letter-spacing: 1px;

    &:hover {
        color: #E4007C;
        transition: 250ms ease-in-out;
        text-decoration: none;
    }
`;

export const Heading = styled.p` 
    
    font-size: 24px;
    color: #fff;
    margin-bottom: 40px;
    font-weight: bold;
    text-align: left;

    &:hover {
        color:pink;
        transition: 350ms ease-in-out;
    }
`;

export const YoutubeLink = styled.a` 
    
    color: white;
    margin-bottom: 20px;
    font-size: 18px;
    text-decoration: none;
    letter-spacing: 1px;

    &:hover {
        color: red;
        transition: 250ms ease-in-out;
        text-decoration: none;
    }
`;

export const FacebookLink = styled.a` 
    
    color: white;
    margin-bottom: 20px;
    font-size: 18px;
    text-decoration: none;
    letter-spacing: 1px;

    &:hover {
        color: #4267B2;
        transition: 250ms ease-in-out;
        text-decoration: none;
    }
`;

export const InstagramLink = styled.a` 
    
color: white;
margin-bottom: 20px;
font-size: 18px;
text-decoration: none;
letter-spacing: 1px;

&:hover {
    color: #4267B2;
    transition: 250ms ease-in-out;
    text-decoration: none;
}
`;