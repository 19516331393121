import React, { FC } from "react";
import { Curso } from "../../../../constants/types";
import { useAuthUser } from "../../../../Services/useAuthUser";
import { ModuleLessonCreator } from "./ModuleLessonCreator";
import { ModulePreview } from "./ModulePreview";

import { Container } from "./styles";

type CursoIndiceProps = {
  nombreCurso: string;
  isDiplomado: boolean;
  courseData: Curso;
};

export const Indice: FC<CursoIndiceProps> = ({
  nombreCurso,
  isDiplomado,
  courseData,
}) => {
  const { isAdmin } = useAuthUser();
  //Si no hay modulos y no es admin no renderizar nada, si no hay modulos y es admin renderizar pero con aviso de que no hay modulos
  return (
    <>
      {(courseData!.previewModulos!.length > 0 || isAdmin) && (
        <Container>
          <h3>Índice</h3>
          <p>
            Contenido del {isDiplomado ? "diplomado" : "curso"} de {nombreCurso}
          </p>
          <ul>
            {courseData.previewModulos?.map((modulo, index) => (
              <ModulePreview
                courseId={courseData.id}
                index={index}
                moduleId={modulo.id}
                titulo={modulo.data.moduloTitulo}
                moduloData={modulo}
                key={index}
                imagen={modulo.data.imgDecorativa}
              />
            ))}
            {isAdmin && <ModuleLessonCreator key="modulePreview-creator" />}
          </ul>
        </Container>
      )}
    </>
  );
};
