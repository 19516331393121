import React, { useContext } from "react";
import styled from "styled-components";
import BottonCompra from "../BottonCompra";
import { CursoPreviewData } from "../..";

const Container = styled.div`
  margin-top: 96px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    font-family: "Montserrat";
    max-width: 494px;
    margin-bottom: 72px;
    text-align: center;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;

    color: #000000;
  }

  summary {
    background: #ededed;
    border-radius: 7px;
    width: 100%;
    min-height: 44px;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    display: flex;
    align-items: center;
    padding-left: 20px;
    margin-bottom: 12px;
  }
  details {
    width: 90%;
  }

  img {
    width: 100%;
    height: auto;
    padding-bottom: 12px;
  }

  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    padding-left: 20px;
  }

  @media screen and (max-width: 550px) {
    h3 {
      font-family: "Montserrat";
      max-width: 70%;
      font-size: large;
      margin-bottom: 40px;
      text-align: center;
      font-weight: 900;
    }
    p {
      font-size: 14px;
      text-align: justify;
      padding: 0 20px 0 20px;
    }
  }
`;

export const AlgunasPreguntasBazi = () => {
  const cursoData = useContext(CursoPreviewData)
  return (
    <Container>
      <h3>
        Sé que la astrologia BAZI puede traer grandes cambios en mi vida, pero
        tengo algunas preguntas:
      </h3>

      <details>
        <summary>
          ¿Tendré lecturas de mi carta Bazi y de mis familiares?
        </summary>
        <p>
          De hecho es requisito para obtener tu diploma de acreditaón con
          nosotros presentar la lectura de tu carta Bazi y la de dos personas
          más. En las clases y en las asesorías personalizadas se estudiará tu
          carta bazi con distintos enfoques de acuerdo al avance del curso.
        </p>
      </details>

      <details>
        <summary>¿Tendré asesoría sobre mi carta Bazi?</summary>
        <p>
          Conforme avancen los temas del diplomado podrás ver de forma grupal
          cómo se experimentn los temas de tu carta bazi en tu vida, acompañada
          de tus compañeros del diplomado. Trataremos temas específicos y nos
          enfocaremos en tomar acciones precisas para que puedas ver un cambio
          desde que inicies el diplomado.
        </p>
      </details>

      <details>
        <summary>¿Cómo sé si este diplomado es para mi?</summary>
        <p>
          Si tienes interés en encontrar un alivio para tu alma, encontrando tu
          trauma raíz, tomando acciones precisas con personas, emociones y
          cambios de carácter; todo esto para revolucionar a positivo tu vida,
          entonces, este diplomado es para ti.
        </p>
      </details>

      <details>
        <summary>
          Temo que sea mucha información y no pueda aprovechar el contenido del
          curso.
        </summary>
        <p>
          Así es, va a haber mucha información, no solo desde la mente sino
          también desde las emociones. Descuida, he procurado conformar un grupo
          amoroso para que puedas maximizar el contenido del diplomado y veas
          resultados durante el desarrollo del mismo.
        </p>
      </details>

      <details>
        <summary>¿Qué métodos de pago hay?</summary>
        <p>
          Desde esta página web puedes realizar una compra con tarjeta de
          crédito o débito a través de la pasarela de pago segura Stripe.
        </p>
      </details>

      <details>
        <summary>Fechas y horarios del diplomado</summary>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/web-arlene.appspot.com/o/assets%2FFechas%20diplomados%2FWhatsApp%20Image%202024-02-06%20at%2009.41.26.jpeg?alt=media&token=d28fc53d-61f4-4432-941b-ffd9b705dabd"
          alt="Fechas"
        />
      </details>

      <details>
        <summary>Precios</summary>
        <p>
          El diplomado tiene un costo de $14700 MXN con pago único, o siete
          mensualidades de $2100 MXN. Inscríbete antes del 15 de febrero e
          Ingresa el código "BAZI2024" para obtener un descuento del 10% en tu
          compra.
        </p>
      </details>

      <BottonCompra cursoData={cursoData} cursoId={cursoData?.id || ""} isGratuito={cursoData?.isGratuito || false} />
    </Container>
  );
};
