import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const ref = useRef<any>(null);

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [ref, pathname]);

  return (
    <div ref={ref} id="scroll-top"></div>
  );
}
