import React from 'react';
import { useHistory } from 'react-router-dom';


import { useFirebaseContext } from "../../Services/Firebase"

const SignOutButton = () => {
  const firebase = useFirebaseContext()
  const history = useHistory();
  
  function handleClick() {
    firebase.doSignOut();
    // ver como actualizar el estado de la app para no hacer redirect?
    history.push("/signin");
  }

  return (
  <button className="nav-link" href="#" onClick={handleClick}>Cerrar sesión</button>
)};

export default SignOutButton;