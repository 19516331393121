import * as ROLES from "../constants/roles";

export const useAuthUser = () => {

  const authUser = JSON.parse(localStorage.getItem("authUser") as string);
  const isAdmin = authUser?.roles?.includes(ROLES.ADMIN) || false;

  return {
    authUser,
    isAdmin
  }
}