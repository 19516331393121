import React from "react";
import { Helmet } from "react-helmet";

export const HelmetMetas = ({ titulo, img }: { titulo: string, img: string }) => {


    return (
     <Helmet>
        {/* <title>Cursos Arlene Ruiz Metafísica</title>
<meta name="description" content="Aprende Metafísica China con Arlene Ruiz" /> */}

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://cursos.arleneruizfengshui.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={titulo} />
        <meta
          property="og:description"
          content="Aprende Metafísica China con Arlene Ruiz"
        />

        <meta
          property="og:image"
          content={img}
        />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="cursos.arleneruizfengshui.com" />
        <meta
          property="twitter:url"
          content="https://cursos.arleneruizfengshui.com/"
        />
        <meta name="twitter:title" content={titulo} />
        <meta
          name="twitter:description"
          content="Aprende Metafísica China con Arlene Ruiz"
        />

        <meta
          name="twitter:image"
          content={img}
        />
      </Helmet>
    )
}