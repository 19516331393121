import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useFirebaseContext } from "../../Services/Firebase"
import * as ROUTES from '../../constants/routes';


const TablonVista = () => {
  const history = useHistory()
  const params = useParams()
  const firebase = useFirebaseContext()

  const [tablon, setTablon] = useState(null)
  const [checkMateriales, setCheckMateriales] = useState(false)

  useEffect(() => {
    const tablonid = params.id
    const products = JSON.parse(localStorage.getItem('authUser')).products;

    if (!products.includes(tablonid)) {
      history.push(ROUTES.HOME);
    }

    async function tablonData() {
      const tablon = await firebase.tablon(tablonid).get()
      const data = await tablon.data()
      if (data.hasOwnProperty('materiales')) {
        setCheckMateriales(true)
        setTablon(data)
      } else {
        setCheckMateriales(false)
        setTablon(data)
      }
    }
    tablonData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div className="fondo">
      <div className="material-container">
        <div className="btn-wrapper">
          <Link className="btn-back" to={ROUTES.HOME}>Regresar</Link>
        </div>
        <div className="material-name">
          {tablon ? <h1>{tablon.name}</h1> : <h1>Cargando ...</h1>}
        </div>
      </div>

      <div className="docs">
        {tablon && checkMateriales ? tablon.materiales.map((material, index) => (
          <div className="cardDoc" key={index}>
            <img src={material.image} alt="imagen material" />
            <div className="info">
              <h1>{material.name}</h1>
              <p>{material.description}</p>
              <button>
                <a href={material.file} rel="noopener noreferrer" target="_blank">
                  Abrir
                </a>
              </button>
            </div>
          </div>)) :
          (<div className="docs no-material-container">
            <div className="no-material">No hay material disponible por el momento.</div>
          </div>)}
      </div>
    </div>
  )
}

export default TablonVista

