import React, { FC } from "react";
import styled from "styled-components";
import { userMaterial } from "../../hooks/useUsersMaterials";
import { ListaMateriales } from "./ListaMateriales";

type StudentsMaterialsProps = {
  usersData: userMaterial[];
  authUserEmail: string;
  refresh: () => void;
};
export const StudentsMaterials: FC<StudentsMaterialsProps> = ({
  usersData,
  authUserEmail,
  refresh,
}) => {
  return (
    <StudentsMaterialsContainer>
      <ul>
        {usersData.map((user, index) =>
          ((user.email === authUserEmail) || (user?.data?.urlMateriales.length < 1)) ? null : (
            <li key={user.email + index}>
              <h3>{user.data.nombreUsuario}</h3>
              <ListaMateriales userData={user} refresh={refresh} />
            </li>
          )
        )}
      </ul>
    </StudentsMaterialsContainer>
  );
};

const StudentsMaterialsContainer = styled.div`
  margin: 40px 0;
  padding: 0px 8%;

  & ul {
    padding: 0;
  }

  & li {
    margin-bottom: 20px;
    list-style: none;

    & h3 {
      font-family: "Montserrat", sans-serif;
      font-size: 15px;
      margin-bottom: 2px;
    }

    & div:first-of-type {
      margin-left: -5px;
    }
  }
`;
