import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";
import { Helmet } from "react-helmet";

import Navigation from "./components/Navigation";
import { LoadingScreen } from "./components/common";
import SignUpPage from "./components/Registro";
import SingInPage from "./components/InicioSecion";
import PasswordForgetPage from "./components/PasswordForget";
import TablonVista from "./components/Tablones/TablonVista";

import * as ROUTES from "./constants/routes";
import { withAuthentication } from "./components/Session";

import { InicioTiendaPage } from "./pages/InicioTienda";
import { CursoPageFull } from "./pages/curso";
import cursoPreview from "./pages/curso-preview/index";
import NotFound from "./pages/not-found";
import Cuenta from "./pages/cuenta";

import FengShuiCerrado from "./pages/diplomados-cerrados/fengShui";
import BaziCerrado from "./pages/diplomados-cerrados/bazi";

import { GraciasSuscripcion } from "./pages/diplomados-cerrados/GraciasSuscripcion";
import { CoursoExamPage } from "./pages/curso-examen";
import ScrollToTop from "./pages/curso/hooks/ScrollToTop";
import { SuccessPage } from "./pages/PaymentStatus/SuccessPage";

//asinc components
const MinGuaApp = Loadable({
  loader: () => import("./pages/minGua"),
  loading: LoadingScreen,
});
const ReglaApp = Loadable({
  loader: () => import("./components/reglaPuerta"),
  loading: LoadingScreen,
});
const AdminPage = Loadable({
  loader: () => import("./pages/Admin"),
  loading: LoadingScreen,
});

const routes = [
  {
    path: ROUTES.SIGN_UP,
    component: SignUpPage,
  },
  {
    path: ROUTES.SIGN_IN,
    component: SingInPage,
  },
  {
    path: ROUTES.PASSWORD_FORGET,
    component: PasswordForgetPage,
  },
  {
    noExact: true,
    path: ROUTES.ADMIN,
    component: AdminPage,
  },
  {
    path: "/account",
    component: Cuenta,
  },
  {
    path: "/",
    component: InicioTiendaPage,
  },
  {
    path: ROUTES.DIPLOMADO_CERRADO_FENG_SHUI,
    component: FengShuiCerrado,
  },
  {
    path: ROUTES.DIPLOMADO_CERRADO_ASTROLOGIA_BAZI,
    component: BaziCerrado,
  },
  {
    path: "/gracias-suscripcion",
    component: GraciasSuscripcion,
  },
  {
    path: "/curso/preview/:courseUrl",
    component: cursoPreview,
  },
  {
    path: "/curso/:courseUrl/examen",
    component: CoursoExamPage,
  },
  // {
  //   path: "/curso/:courseUrl/:moduleName",
  //   component: CoursePage,
  // },
  {
    noExact: true,
    path: "/curso/:courseUrl",
    component: CursoPageFull,
  },
  {
    path: ROUTES.TABLON,
    component: TablonVista,
  },
  {
    path: ROUTES.REGLAPUERTA,
    component: ReglaApp,
  },
  {
    path: ROUTES.MINGUA,
    component: MinGuaApp,
  },
  { path: ROUTES.SUCCESS_PAGE, component: SuccessPage },
  {
    path: "*",
    component: NotFound,
  },
];

const App = () => {
  return (
    <Router>
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}
        <title>Cursos Arlene Ruiz Metafísica</title>
        <meta
          name="description"
          content="Aprende Metafísica China con Arlene Ruiz"
        />

        {/* <!-- Facebook Meta Tags --> */}
        <meta
          property="og:url"
          content="https://cursos.arleneruizfengshui.com/"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Cursos Arlene Ruiz Metafísica" />
        <meta
          property="og:description"
          content="Aprende Metafísica China con Arlene Ruiz"
        />

        <meta
          property="og:image"
          content="https://ogcdn.net/2c2c6737-47d4-4459-9969-e711eb48394c/v1/cursos.arleneruizfengshui.com/Cursos%20Arlene%20Ruiz%20Metaf%C3%ADsica/Aprende%20Metaf%C3%ADsica%20China%20con%20Arlene%20Ruiz/https%3A%2F%2Fcdn.theopengraph.com%2Fproduction%2Fdocuments%2Fd564a463-1c51-4259-991d-0d17bf3e0391.jpg%3Ftoken%3D-hKrjcO4EFDmPh0lgxSanJdH2MZ9gYnfw4V87WUckbA%26height%3D800%26width%3D1200%26expires%3D33239188519/og.png"
        />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="web-arelene-dev.web.app" />
        <meta
          property="twitter:url"
          content="https://cursos.arleneruizfengshui.com/"
        />
        <meta name="twitter:title" content="Cursos Arlene Ruiz Metafísica" />
        <meta
          name="twitter:description"
          content="Aprende Metafísica China con Arlene Ruiz"
        />

        <meta
          name="twitter:image"
          content="https://ogcdn.net/2c2c6737-47d4-4459-9969-e711eb48394c/v1/cursos.arleneruizfengshui.com/Cursos%20Arlene%20Ruiz%20Metaf%C3%ADsica/Aprende%20Metaf%C3%ADsica%20China%20con%20Arlene%20Ruiz/https%3A%2F%2Fcdn.theopengraph.com%2Fproduction%2Fdocuments%2Fd564a463-1c51-4259-991d-0d17bf3e0391.jpg%3Ftoken%3D-hKrjcO4EFDmPh0lgxSanJdH2MZ9gYnfw4V87WUckbA%26height%3D800%26width%3D1200%26expires%3D33239188519/og.png"
        />
      </Helmet>

      <ScrollToTop />
      <div id="outer">
        <Navigation />
        <Switch>
          {/* TODO poner en el array por que se pierde */}
          {routes.map((route, index) => (
            <Route
              exact={!route.noExact}
              path={route.path}
              component={route.component}
              key={index}
            />
          ))}
        </Switch>
      </div>
    </Router>
  );
};

export default withAuthentication(App);
