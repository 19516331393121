import React from "react";
import cutArlene from "./cut-arlene.png";
import frame from "./Frame.svg";

import { Wrapper, Content, List, ConAmor } from "./styles";

export const GraciasSuscripcion = () => {
  return (
    <Wrapper>
      <h1>Gracias por interesarte en nuestros diplomados</h1>

      <Content>
        <img src={cutArlene} alt="Arlene" />
        <div>
          <List>
            <div>
              <img src={frame} alt="bullet" />
              <p>En cuanto iniciemos inscripciones te avisaremos por medio de un correo electrónico.</p>
            </div>

            <div>
              <img src={frame} alt="bullet" />
              <p>
                Si tienes alguna duda o quieres saber acerca de los servicios que ofrecemos, escríbenos a hola@arleneruizfengshui.com y mi equipo te
                ayudará.
              </p>
            </div>
          </List>
          <ConAmor>
            <p>
              Con amor <br /> <b>Arlene Ruiz</b>
            </p>
          </ConAmor>
        </div>
      </Content>
    </Wrapper>
  );
};
