import styled from "styled-components";


export const Wrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  height: 100%;
`;

export const PreguntaInput = styled.input`
  width: 512px;
  border: none;
  border-bottom: 1px solid gray;
  margin-bottom: 16px;
`;

export const Respuestas = styled.div`
  > p {
    width: min-content;
    min-width: 512px;
    padding: 4px;
    background-color: rgba(0, 0, 100, 0.12);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  > p.correcta {
    background-color: rgba(0, 100, 0, 0.4);
  }
`;

export const InputFile = styled.div`
  width: 512px;
  height: 256px;
  position: relative;
  cursor: pointer;
  margin: 16px 0;

  input {
    color: transparent;
  }
  input[type="file"] {
    cursor: pointer;
    opacity: 0;
    height: inherit;
    width: inherit;
  }

  > img {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    position: none;
    pointer-events: none;
  }

  .module-background-label {
    font-size: 20px;
    width: 100%;
    height: 100%;
    text-align: center;
    position: absolute;
    top: 0;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dotted gray;
  }
`;
