import React, { FC, useContext } from "react";
import styled from "styled-components";
import { CursoPreviewData } from "..";
import BottonCompra from "./BottonCompra";
import { InputComponent } from "./Header/InputComponent";
import { useAuthUser } from "../../../Services/useAuthUser";

type AboutCursoProps = {
  cursoNombre: string;
  textoBoton: string;
};
const AboutCurso: FC<AboutCursoProps> = ({ cursoNombre, textoBoton }) => {
  const cursoData = useContext(CursoPreviewData);
  const { isAdmin } = useAuthUser();

  return (
    <Container>
      <h3>
        Sé que {cursoNombre} puede
        <br />
        traer grandes cambios en mi vida, pero
        <br />
        tengo algunas preguntas:
      </h3>

      {/* {cursoData?.tipoCurso === "diplomado" && (
        <>
          <h6>¿Cuánto tiempo dura el diplomado?</h6>
          <p>
            Debido a la cantidad de informacion que se maneja en el diplomado el
            tiempo de este es de 1 año. Repartido en 3 dias de 3 horas cada mes.
            Pero si tienes la necesidad de hacerlo con rapidez te facilitamos el
            diplomado intensivo, el cual puedes desarrollar en el tiempo que tú
            convengas.
          </p>
        </>
      )} */}
      <h6>¿Cuál es el costo y qué formas de pago hay?</h6>

      <p>
        {!cursoData?.faqPagos && (
          <>
            {cursoData?.tipoCurso === "diplomado"
              ? `La inversión por este diplomado trasformador es de $${cursoData.precioMensual}.00 pesos mexicanos mensuales,
          con una duración de seis meses o puedes hacer un pago único de $${cursoData.precio}.00 MXN`
              : `El costo de nuestro curso es de $` +
                cursoData?.precio +
                " MXN"}
            <br />
            <br />
            Se puede pagar con tarjeta de credito.
            <br />
            <br />
          </>
        )}

        <InputComponent
          nombreDato="faqPagos"
          InputFieldType="textbox"
          containerClassName="test"
        >
          <p>
            {cursoData?.faqPagos ||
              (isAdmin && "(Admin) Añadir texto personalizado")}
          </p>
        </InputComponent>
      </p>

      <BottonCompra cursoData={cursoData} cursoId={cursoData?.id || ""} isGratuito={cursoData?.isGratuito || false} />
    </Container>
  );
};

const Container = styled.div`
  margin-top: 78px;
  font-family: "Montserrat";
  padding: 0 5%;
  line-height: 20px;

  h3,
  h6 {
    font-weight: 700;
  }

  h3 {
    text-align: center;
    font-size: min(3.7vw, 20px);
    margin-bottom: min(8%, 54px);
  }

  h6 {
    font-size: min(3.2vw, 18px);
  }

  p {
    word-break: break-word;
    font-size: min(2.9vw, 14px);
    text-align: justify;
    margin-bottom: min(6%, 24px);
  }

  p:last-of-type {
    margin-bottom: 34px;
  }

  button {
    margin: auto;
  }
`;

export default AboutCurso;
