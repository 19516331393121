import React, { FC, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import * as ROUTES from "../../../../constants/routes";
import SignOutButton from "../../../SignOut";

import { useAuthUser } from "../../../../Services/useAuthUser";

const UserMenu = () => {
  const [enableMenu, setMenuState] = useState<boolean>(false);
  const [blurEnable, setBlurState] = useState<boolean>(false);
  const { isAdmin } = useAuthUser();
  const menuRef = useRef<HTMLDivElement>(null);

  function toggleMenu() {
    setMenuState(!enableMenu);
  }

  useEffect(() => {
    if (enableMenu) menuRef.current?.focus();
  }, [enableMenu]);

  function disableMenu(event: any) {
    if (blurEnable) setMenuState(false);
  }

  return (
    <Container tabIndex={-1} ref={menuRef} onBlur={disableMenu}>
      <UserMenuMainInfo clickHandler={toggleMenu} />
      <UserMenuListContainer
        menuState={enableMenu}
        onMouseEnter={() => setBlurState(false)}
        onMouseLeave={() => setBlurState(true)}
      >
        <ul>
          <li>
            <Link to={ROUTES.ACCOUNT} onClick={() => setMenuState(false)}>
              Cuenta
            </Link>
          </li>
          {isAdmin && (
            <li>
              <Link to={ROUTES.ADMIN} onClick={() => setMenuState(false)}>
                Admin
              </Link>
            </li>
          )}
          <li>
            <SignOutButton />
          </li>
        </ul>
      </UserMenuListContainer>
    </Container>
  );
};

const Container = styled.li`
  padding: 0 !important;
  margin: 6px 0;
  position: relative;

  :focus {
    outline: none;
  }
`;

const UserMenuListContainer = styled.div<{ menuState: boolean }>`
  button {
    border: none;
    background: none;
    font-size: 14px;
    font-weight: 300;
    color: red;
    margin-top: 12px;
    margin-bottom: 12px;

    &:focus {
      outline: none;
    }
  }

  position: absolute;
  background: #f6f6f6;
  top: 160%;
  width: 90%;
  border: 1px solid #d2d2d2;
  box-shadow: 0px 2px 4px 0px #0000001a;
  z-index: 1;
  border-radius: 0 0 3px 3px;
  overflow: hidden;
  ${({ menuState }) =>
    menuState ? "display: block;" : "display: none; border: none;"}

  ul {
    margin: 12px 7% 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    li {
      line-height: 11px;
      padding: 0 0 12px;

      :last-of-type {
        padding: 0;
      }

      button {
        padding: 0;
      }
    }
  }
`;

// TODO: Mas de un componente por file... 
const UserMenuMainInfo: FC<{ clickHandler: any }> = ({ clickHandler }) => {
  const { authUser } = useAuthUser();

  return (
    <InfoContainer onClick={clickHandler} className="user-menu">
      <div className="user-info-name">{authUser.username}</div>
      <div className="user-info-imageWrapper">
        <div className="user-info-image">
          <img src={authUser.avatar || authUser?.socialMediaPhotoUrl || "https://firebasestorage.googleapis.com/v0/b/web-arlene.appspot.com/o/assets%2FdefaultUserImg.jpg?alt=media&token=c49c3148-1de6-4f76-9efd-7f577903273d"} alt="Imagen de usuario" />
        </div>
      </div>
    </InfoContainer>
  );
};

const InfoContainer = styled.div`
  padding: 6px 1.1rem 0;
  display: flex;
  user-select: none;

  font-family: "Buda";
  font-weight: 300;
  font-size: 11px;
  text-transform: uppercase;

  .user-info-imageWrapper {
    width: 25px;
    margin-left: 6px;

    .user-info-image {
      position: absolute;
      height: 100%;
      width: 23px;
      top: 1px;
      bottom: 0;

      img {
        border-radius: 50%;
        max-width: 100%;
        height: 100%;
        width: 100%;
        display: block;
        cursor: pointer;
      }
    }
  }

  .user-info-name {
    color: #c11f6d;
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }
`;

export default UserMenu;
