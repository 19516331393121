import React, { useRef, FC, useEffect } from "react";
import { ElBotton } from "../../../components/common/ElBotton";

import diplomabase from './diploma-bazi.jpeg';

type DiplomaBaziProps = {
  nombre: string;
  generacion: string;
};

export const DiplomaBazi: FC<DiplomaBaziProps> = ({
  nombre,
  generacion,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    function generar() {
      if (!canvasRef.current) return;

      const context = canvasRef.current.getContext("2d");
      const diploma = new Image();
      diploma.crossOrigin = "anonymous"

      diploma.onload = function () {
        if (!context) return;
        context.drawImage(diploma, 0, 0);
        context.font = "52pt serif";
        context.fillStyle = "black";
        context.fillText(nombre, 300, 620);

        context.font = "14pt sans-serif";
        context.fillText('GENERACION '+generacion, 550, 950);
      };

      diploma.src = diplomabase;
    }

    generar();
  }, [generacion, nombre]);

  function download() {
    var link = document.createElement("a");
    link.download = "diploma.png";
    link.href = canvasRef?.current?.toDataURL() as string;
    link.click();
  }

  return (
    <>
      <canvas
        width={1280}
        height={993}
        ref={canvasRef}
        style={{ width: "auto", height: "512px", marginBottom: '16px' }}
      ></canvas>
      <ElBotton onClick={download}>descargar</ElBotton>
    </>
  );
};