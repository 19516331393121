import React, { useState } from "react";
import PasswordChangeForm from "../../components/PasswordChange";
import SignOutButton from "../../components/SignOut";
import { useAuthUser } from "../../Services/useAuthUser";
import { Container } from "./styles";
import { FormularioAvatar } from "./FormularioAvatar";
import { ElBotton } from "../../components/common/ElBotton";
import { useFirebaseContext } from "../../Services/Firebase";

const Cuenta = () => {
  const { authUser } = useAuthUser();
  const firebase = useFirebaseContext();

  const [editUsername, setEditUsername] = useState(false);

  async function updateUsername() {
    const username = document.getElementById("username") as HTMLInputElement;
    if (username.value) {
      if (username.value.length < 3 || username.value.length > 15) {
        alert('El nombre de usuario debe tener al menos 3 caracteres y no más de 15.')
        return
      }
      await firebase.user(authUser.email).update({ username: username.value });

      // udpate auth user in localstorage
      localStorage.setItem('authUser', JSON.stringify({...authUser, username: username.value}))

      alert('se actualizó el nombre con éxito')
      setEditUsername(false);
    }
  }


  return (
    <Container>
      <h1 className="user-accout-title">Tu Perfil</h1>
      <div className="user-accout-data">
        <div className="user-accout-details">
          <h4>Usuario:</h4>
          {editUsername ? (
            <>
              <input
                className="form-control"
                type="text"
                name=""
                id="username"
              />
              <div style={{display: 'flex', marginTop: '12px'}}>
                <ElBotton onClick={() => setEditUsername(false)}>cancelar</ElBotton>
                <ElBotton color="verde" onClick={updateUsername}>Guardar</ElBotton>
              </div>
            </>
          ) : (
            <>
              <p>{authUser?.username}</p>
              <ElBotton onClick={() => setEditUsername(true)}>
                Cambiar nombre
              </ElBotton>
            </>
          )}
          <br />
          <h4>Correo Electrónico:</h4>
          <p>{authUser?.email}</p>
          <h4>Cambiar contraseña:</h4>

          <PasswordChangeForm />

          <br />
          {/* TODO: haber qp con esto */}
          {/* {authUser?.stripeCustomerId && <ElBotton onClick={() => createCustomerPortalSession(authUser)}>Administrar mis subscripciones.</ElBotton>} */}
        </div>

        <FormularioAvatar />
      </div>
      <SignOutButton />
    </Container>
  );
};

export default Cuenta;
