import React, { useState, FC } from "react";
import { ElBotton } from "../../../../components/common/ElBotton";
import { RespuestaPreguntaExamen } from "../../../../constants/types";
import { useFirebaseContext } from "../../../../Services/Firebase";
import { useAuthUser } from "../../../../Services/useAuthUser";
import { useExamenContext } from "../../ExamenContext";

import { Pregunta, Respuestas } from "../../components/UserQuestionaryStyles";

export const UserQuestionary: FC<{ irResultados: () => void }> = ({ irResultados }) => {
  const firebase = useFirebaseContext();
  const { examenData, idCourse, curso, userRespuestas, getUserData } =
    useExamenContext();
  const { authUser } = useAuthUser();

  // const currentPregunta = userRespuestas?.respuestas.length;
  const [currentPregunta, setCurrentPregunta] = useState(
    userRespuestas?.respuestas.length
  );

  const [mostrarResultado, setMostrarResultado] = useState(false);

  const [respuestaSelected, setRespuestaSelected] = useState<null | number>(
    null
  );

  async function guardarRespuesta() {
    if (typeof respuestaSelected !== "number") {
      return;
    }

    // guardar la informacion nueva del state en el document
    const nuevasRespuestas: RespuestaPreguntaExamen[] =
      userRespuestas.respuestas;
    nuevasRespuestas.push(
      examenData?.questions[currentPregunta].respuestas[respuestaSelected]
    );

    let updateDocument: any = {
      respuestas: nuevasRespuestas,
    };
    // revisar si ya se completo el examen

    if (nuevasRespuestas.length === examenData.questions.length) {
      const respuestasCorrectas = nuevasRespuestas.filter(
        (respuesta) => respuesta.correcta
      );
      const calificacion =
        (respuestasCorrectas.length / nuevasRespuestas.length) * 100;

      updateDocument.completado = true;
      updateDocument.calificacion = calificacion;
    }

    const snapshoot = firebase
      .cursoExamenRespuestas(idCourse)
      .doc(authUser.email);

    await snapshoot.update(updateDocument);

    // setRespuestaSelected(null);
    setMostrarResultado(true);

    getUserData();
  }

  function continuar() {
    if (currentPregunta + 1  === examenData.questions.length) {
      // ir a resultados
      irResultados()
    } else {
      // siguiente pregunta
      setMostrarResultado(false);
      setRespuestaSelected(null);
      setCurrentPregunta(currentPregunta + 1);
    }
  }

  return (
    <div>
      <Pregunta>
        {/* Estatus ber, show examen y pregunta */}
        <div className="status">
          <p>
            Pregunta {currentPregunta + 1} de {examenData?.questions.length}
          </p>
          <p>Examen {curso?.nombreCurso}</p>
        </div>
        <h3>{examenData?.questions[currentPregunta]?.pregunta}</h3>

        {examenData?.questions[currentPregunta]?.imagen !== "" && (
          <img src={examenData?.questions[currentPregunta]?.imagen} alt="" />
        )}

        <Respuestas>
          <p>Selecciona una respuesta</p>
          {examenData?.questions[currentPregunta]?.respuestas.map(
            (respuesta: RespuestaPreguntaExamen, key: number) => (
              <button
                key={key}
                onClick={() => setRespuestaSelected(key)}
                className={[
                  key === respuestaSelected ? "selected" : "",
                  mostrarResultado && respuesta.correcta ? "correcta" : "",
                  mostrarResultado && !respuesta.correcta ? "incorecta" : "",
                  mostrarResultado && key !== respuestaSelected
                    ? "ocultar"
                    : "",
                ].join(" ")}
              >
                {key + 1}. {respuesta.texto}
                {mostrarResultado && respuesta.correcta ? ' - Correcta!' : ''}
                {mostrarResultado && !respuesta.correcta ? ' - Incorrecta' : ''}
              </button>
            )
          )}
        </Respuestas>

        {!mostrarResultado && (
          <ElBotton
            disabled={typeof respuestaSelected !== "number"}
            color="gris"
            onClick={guardarRespuesta}
          >
            Guardar Respuesta
          </ElBotton>
        )}

        {mostrarResultado && (
          <ElBotton color="gris" onClick={continuar}>
            Continuar
          </ElBotton>
        )}
      </Pregunta>
    </div>
  );
};
