import React, {
  createContext,
  useState,
  FC,
  useContext,
  useEffect,
} from "react";
import { useParams } from "react-router-dom";
import { ExamenCurso, UserExamenRespuestas } from "../../constants/types";

import { useFirebaseContext } from "../../Services/Firebase";
import { useAuthUser } from "../../Services/useAuthUser";

const ExamenContext = createContext<any>(null);

const ExamenContextProvider: FC = ({ children }) => {
  const firebase = useFirebaseContext();

  const params = useParams<any>();
  const idCourse = params?.courseUrl;

  const { authUser, isAdmin } = useAuthUser();

  const [examenData, setExamenData] = useState<ExamenCurso | undefined>(
    undefined
  );
  const [curso, setCurso] = useState<any>(undefined);

  const [userRespuestas, setUserRespuestas] = useState<
    UserExamenRespuestas | undefined
  >(undefined);

  async function getCourseInfo() {
    // fetch the curso for the titulo
    const curso = await (await firebase.curso(idCourse).get()).data();
    if (curso?.nombreCurso) {
      setCurso(curso);
    }
  }

  async function getExamen() {
    // Checa si existe un examen para el curso
    const check = await firebase.cursoExamen(idCourse).get();
    if (check.empty) {
      // Create inital examen state
      const newExamen = await firebase.cursoExamen(idCourse).doc();

      const initialExamen = {
        questions: [],
        id: newExamen.id,
        activo: false,
        diploma: { formato: "no", generacion: "2022" },
      };

      setExamenData(initialExamen);

      newExamen.set(initialExamen);
    } else {
      // save the examen to state
      setExamenData(check.docs[0].data() as ExamenCurso);
    }
  }

  async function guardarExamen(updateObject: ExamenCurso) {
    if (!updateObject) return;
    try {
      const examen = firebase.cursoExamen(idCourse).doc(updateObject?.id);
      await examen.update(updateObject);
      console.log("se guardo el examen con exito");
    } catch (error: any) {
      console.error("Fallo guardar el examen", error.message);
    }
  }

  async function getUserData() {
    if (!idCourse || !authUser.email) return;
    const snapshoot = firebase
      .cursoExamenRespuestas(idCourse)
      .doc(authUser.email);

    const data = await snapshoot.get();

    if (data.exists) {
      setUserRespuestas(data.data() as UserExamenRespuestas);
    } else {
      // Gaurda el documento inicial si no exite
      snapshoot.set({ respuestas: [], completado: false, calificacion: 0 });
      setUserRespuestas({
        respuestas: [],
        completado: false,
        calificacion: 0,
        mencionHonorifica: false,
        nombreDiploma: "",
        comentario: "",
      });
    }
  }

  async function getUsersSubmitions(cursoId: string) {
    const snapshoot = await firebase.cursoExamenRespuestas(cursoId).get();

    const documents = snapshoot.docs.map((doc) => ({
      ...doc.data(),
      email: doc.id,
    }));

    return documents;
  }

  async function syncAlumnos() {
    // Este proceso compara los usuarios con el curso adquirido
    // vs las respuestas del examen y crea los usuarios faltantes
    const alumnos = (await firebase.users().get()).docs
      .map((u) => u.data())
      .filter((doc: any) => !!doc.products.includes(idCourse))
      .filter((a: any) => !a?.roles?.includes("ADMIN"));

    console.log(alumnos);

    const submitions = await getUsersSubmitions(idCourse);

    // filter alumnos que no estan en submitions
    const faltantes = alumnos.filter(
      (alumno: any) =>
        !submitions?.find((sub: any) => sub.email === alumno.email)
    );
    faltantes.forEach(async (f: any) => {
      const snapshoot = firebase.cursoExamenRespuestas(idCourse).doc(f.email);
      await snapshoot.set({
        respuestas: [],
        completado: false,
        calificacion: 0,
        mencionHonorifica: false,
        nombreDiploma: "",
        comentario: "",
        username: f.username,
      });
    });

    // TODO: borrar des pues de correr un rato en produccion.
    // actualiza el nombre de todos los alumnos (por si cambio o algo)
    alumnos.forEach(async (alumno: any) => {
      const snapshoot = firebase
        .cursoExamenRespuestas(idCourse)
        .doc(alumno.email);
      await snapshoot.update({ username: alumno.username });
    });
  }

  useEffect(() => {
    async function loadContext() {
      await getExamen();
      await getCourseInfo();

      if (!isAdmin) {
        await getUserData();
      }

      if (isAdmin) {
        // Check and create alumnos submitions
        // This is logic only, nothing for the FE
        await syncAlumnos();
      }
    }
    loadContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ExamenContext.Provider
      value={{
        examenData,
        idCourse,
        curso,
        userRespuestas,
        // Funciones
        getExamen,
        getUserData,
        guardarExamen,
        getUsersSubmitions,
      }}
    >
      {children}
    </ExamenContext.Provider>
  );
};

const useExamenContext = () => {
  const context = useContext(ExamenContext);

  if (!context) {
    throw Error("No puedes usar este hook fuera del Examen Context Provider");
  } else {
    return context;
  }
};

export { ExamenContextProvider, useExamenContext };
