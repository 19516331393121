import React, { FC, useState } from "react";
import styled, { css } from "styled-components";
import { useCourses } from "../../hooks/useCourses";
import { useAuthUser } from "../../../../Services/useAuthUser";
import { useCursoContext } from "../../CursoContext";

type LessonCompleteButtonProps = {
  isComplete: boolean;
  wasCompleted: boolean;
  idCourse: string | undefined;
  idLesson: string | undefined;
};

export const LessonCompleteButton: FC<LessonCompleteButtonProps> = ({
  isComplete,
  wasCompleted,
  idCourse,
  idLesson,
}) => {
  const [completed, setCompleted] = useState(false);
  const { addUserProgress } = useCourses();

  const { doModuleLessonRefresh: refresh, goNextLessonModule: nextLesson } = useCursoContext();

  const { authUser } = useAuthUser();

  function saveCompleted() {
    if (idCourse && idLesson) {
      addUserProgress(authUser.email, idCourse, idLesson).then(() => {
        setCompleted(true);
        nextLesson();
        refresh();
      });
    }
  }

  return (
    <Container isCompleted={isComplete} wasCompleted={wasCompleted}>
      {isComplete && !wasCompleted ? (
        <p onClick={saveCompleted}>Completar lección</p>
      ) : wasCompleted || completed ? (
        <p onClick={nextLesson}>Lección completada</p>
      ) : (
        <p>Completar lección</p>
      )}
    </Container>
  );
};

const wasCompletedStyle = css`
  border: 2px solid #04852a;
  background: #009b0c;
  color: white;
  font-weight: bold;
  box-shadow: 0px 1px 2px 0px #00db0c inset;
`;
const completedStyle = css`
  border: 2px solid #2a7dab;
  background: #67bae1;
  color: #1a638f;
  cursor: pointer;
  font-weight: bold;
`;

const Container = styled.div`
  height: auto;

  & p {
    padding: 5px 10px;
    border: 2px solid #838383;
    background: #cfcfcf;
    border-radius: 5px;
    margin: 0;
    color: #838383;

    ${({ isCompleted, wasCompleted }) =>
      isCompleted && !wasCompleted ? completedStyle : null}
    ${({ wasCompleted }) => (wasCompleted ? wasCompletedStyle : null)}
  }
`;
