import React, { FC, useEffect, useState } from "react";
import CourseCard from "../CourseCard";
import useCursosCards from "../../hooks/useCursosCards";
import { CursoTablon } from "../../../../constants/types";
import { useAuthUser } from "../../../../Services/useAuthUser";
import { TablonCourseSeparator } from "./TablonCourseSeparator";
import styled from "styled-components";

type TablonesListProps = {
  enableCourseEditor: any;
};

export const TablonesList: FC<TablonesListProps> = ({ enableCourseEditor }) => {
  const { getTablonesCards } = useCursosCards();
  const [isLoading, setIsLoading] = useState(true);
  const [tablonesCardsList, setTablonesCardsList] = useState<CursoTablon[]>([]);
  const { authUser } = useAuthUser();

  // use efect to fetch tablones
  useEffect(() => {
    async function getTablones() {
      const products = authUser.products;
      if (products.length > 0) {
        try {
          const resolve = await getTablonesCards();
          // here filter by the ones we have already
          const owned = resolve.filter((c) => products.includes(c.id));
          setTablonesCardsList(owned);
          setIsLoading(false);
        } catch (error: any) {
          console.error("Failed to load Tablones", error.message);
        }
      }
    }
    if (authUser && isLoading) {
      getTablones();
    }
  }, [authUser, isLoading, getTablonesCards]);

  return (
    <>
      {tablonesCardsList.length > 0 && !isLoading && <TablonCourseSeparator />}
      <TablonesListContainer className="container__tablones">
        {!isLoading &&
          tablonesCardsList.map((tablon) => (
            <CourseCard
              key={tablon.id}
              id={tablon.id}
              author={tablon.autor}
              title={tablon.nombreCurso}
              background={tablon.background}
              cursoUrl={tablon.cursoUrl}
              description={tablon.descripcion}
              isBought={tablon.isBought || false}
              subtitulo={tablon.subtitulo}
              enableCourseEditor={enableCourseEditor}
              isTablon={true}
            />
          ))}
      </TablonesListContainer>
    </>
  );
};

const TablonesListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

  @media screen and (width <= 763px) {
    & {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media screen and (width <= 695px) {
      grid-template-columns: 1fr;
    }
`;
