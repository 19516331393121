
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { FlattenSimpleInterpolation } from "styled-components";
import { useCoursePreview } from "../../hook/useCoursePreview";
import { EditPreviewProps, CursoPreviewData } from "../../index";
import { InputContainerStyle } from "./styles";
import { useAuthUser } from "../../../../Services/useAuthUser";

type InputComponentProps = {
  nombreDato: string;
  containerClassName: string;
  customStyle?: FlattenSimpleInterpolation;
  InputFieldType: string;
  value?: string;
};

export const InputComponent: FC<InputComponentProps> = ({
  nombreDato,
  children,
  containerClassName,
  customStyle,
  InputFieldType,
  value,
}) => {
  const { isAdmin } = useAuthUser();
  const { addCoursePreviewData } = useCoursePreview();
  const cursoData = useContext(CursoPreviewData);
  const updateFunction = useContext(EditPreviewProps).update;

  const inputReference = useRef<any>(null);
  
  const [modifingText, setModifingText] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  function enableEdition(event: any) {
    if (event.target !== inputReference.current && isAdmin) {
      setModifingText(!modifingText);
    }
  }

  function addInformationToFirebase(event: any) {
    if (event.key === "Enter" && isAdmin && cursoData) {
      addCoursePreviewData(cursoData.id, { [nombreDato]: event.target.value })
        .then((resolve) => {
          window.alert(resolve);
          updateFunction();
        })
        .catch((reject) => {
          window.alert(reject);
        });

      setModifingText(false);
    }
  }

  function onLoseFocus() {
    setModifingText(false);
  }

  return (
    <InputContainerStyle
      className={containerClassName}
      onClick={enableEdition}
      customStyle={customStyle}
    >
      {!modifingText ? (
          children
        ) : InputFieldType === "input" ? (
          <input
            autoFocus
            ref={inputReference}
            onBlur={onLoseFocus}
            onChange={(event) => setInputValue(event.target.value)}
            placeholder="Introduzca el titulo del encabezado"
            value={inputValue}
            onKeyPress={addInformationToFirebase}
          />
        ) : (
          <textarea
            autoFocus
            ref={inputReference}
            onBlur={onLoseFocus}
            value={inputValue}
            onChange={(event) => setInputValue(event.target.value)}
            placeholder="Introduzca la descripcion"
            onKeyPress={addInformationToFirebase}
          ></textarea>
        )
      }
    </InputContainerStyle>
  );
};