import styled from "styled-components";

export const Promos = styled.div`
  background-color: rgba(255,246,226,255);
  border-radius: 15px;
  width: 100%;
  margin-bottom: 24px;
  padding-top: 26px;

  @media screen and (width <= 425px){
  margin-top: 12px;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  .row__listaCursos {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;
    margin: 0 auto;
    padding: 9px;
  }

  h5 {
    text-align: center;
    font-weight: 700;
    color: #c11f6d;
    margin-bottom: 17px;
  }

  @media screen and (width <= 763px) {
    & {
      margin: 0 auto;
      width: 75%;
    }

    h5 {
      margin: 25px 0px 16px;
    }

    .row__listaCursos {
      margin-left: 20px;
    }
  }

  @media screen and (width <= 695px) {
    .row__listaCursos {
      gap: 10px;
      width: 80%;
      justify-content: center;
      margin: 0 auto;
    }
  }

`;

export const Loading = styled.div`
  margin: auto;
  text-align: center;
  padding-top: 10vh;

  img {
    width: 150px;
    opacity: 0.5;
  }
`;
