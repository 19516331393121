import { useFirebaseContext } from "../../../Services/Firebase";

export function useUploadFile(): (
  file: any,
  cursoId: string
) => Promise<string> {
  const firebase = useFirebaseContext();

  const uploadFile: (file: any, cursoId: string) => Promise<string> = (
    file,
    cursoId
  ) => {
    return new Promise(async (resolve, reject) => {
      let date = new Date().getSeconds();
      const { name } = file;
      const storageRef = await firebase.storage.ref(
        `cursos-preview-images/${cursoId}/${date}${name}`
      );
      const task = storageRef.put(file);

      task.on(
        "state_changed",
        (snapshot: any) => {
          // let percentage =
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error: any) => {
          reject(error);
          console.error(error);
        },
        () => {
          const url = task.snapshot.ref.getDownloadURL();
          resolve(url);
        }
      );
    });
  };

  return uploadFile;
}
