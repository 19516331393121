import styled from "styled-components";

export const Container = styled.div`
  width: 720px;
  overflow-y: scroll;
  padding: 0 1.5px;
  margin: 0 auto;
  &::-webkit-scrollbar-thumb {
    background-color: white; /* color of the scroll thumb */
  }

  > h1 {
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 36px;
    margin: 88px 0;
    text-align: center;
  }

  .user-accout-data {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .user-accout-details {
      flex-grow: 1;
      margin-right: 20%;

      p,
      h4 {
        font-family: "Montserrat";
      }

      h4 {
        font-weight: 700;
        font-size: 16px;
      }
      p {
        font-weight: 400;
        font-size: 14px;
      }

      input {
        height: 30px;
        font-size: 14px;
      }
      button {
        font-family: "Montserrat";
        font-size: 14px;
        padding: 7px 10px;
        border-radius: 6px;
      }

      form {
        padding: 0;
        max-width: 100%;
      }
    }
  }



  > button:last-of-type {
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    margin: 88px auto;
  }
`;