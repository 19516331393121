import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { useCourses } from "../../hooks/useCourses";
import { useAuthUser } from "../../../../Services/useAuthUser";

type PdfMaterialProps = {
  idCourse: string;
  idModule: string;
  idLesson: string;
  pdfUrl: string;
  refresh: () => void;
};

const PdfMaterial: FC<PdfMaterialProps> = ({
  idCourse,
  idModule,
  idLesson,
  pdfUrl,
  refresh,
}) => {
  const { uploadPDF } = useCourses();
  const [fileUploaded, setFileUploaded] = useState("");
  const [subiendo, setSubiendo] = useState(false);

  const { isAdmin } = useAuthUser();

  async function handleUpload(e: any) {
    e.stopPropagation();

    setSubiendo(true);
    const file = e.target.files[0];

    const resolve = await uploadPDF(idCourse, idModule, idLesson, file);
    setFileUploaded(resolve);

    
    setSubiendo(false);
    refresh();
  }
  
  useEffect(() => {
    setFileUploaded(pdfUrl);
  }, [pdfUrl]);

  return (
    <Container heightProp={480}>
      {subiendo && <i className="fas fa-spinner fa-spin"></i>}

      {isAdmin &&
        (fileUploaded === "" || pdfUrl === "" ? (
          <div className="FormLabelCreator">
            <InputFile>
              <input type="file" accept=".pdf" onChange={handleUpload} />
            </InputFile>
            <div>
              <div>
                <strong>Esta lección aun no contiene materiales PDF</strong>
                <p>Presione esta sección para agregar uno</p>
                {`${idCourse} ${idModule} ${idLesson}`}
              </div>
            </div>
          </div>
        ) : (
          <PdfForm heightProp={960}>
            <InputFile>
              <input type="file" accept=".pdf" onChange={handleUpload} />
              <p className="pdf-material-label">Cambiar PDF</p>
            </InputFile>
            <iframe
              title="1"
              src={fileUploaded}
              width="100%"
              height={960}
            ></iframe>
          </PdfForm>
        ))}

      {!isAdmin && pdfUrl && (
        <PdfForm heightProp={960}>
          <iframe
            src={fileUploaded !== undefined ? fileUploaded + "#toolbar=0" : ""}
            title="2"
            width="100%"
            height={960}
          ></iframe>
        </PdfForm>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 20px;

  & .FormLabelCreator {
    position: relative;
    height: calc(${({ heightProp }) => heightProp + "px - 20px"});
    border: 2px dashed #525252;
    display: flex;
    justify-content: center;
    align-items: center;

    & div {
      font-size: 20px;
      text-align: center;
    }
  }
`;
const InputFile = styled.div`
  width: 100%;
  position: absolute;
  cursor: pointer;
  height: inherit;

  & input {
    color: transparent;
  }
  input[type="file"] {
    cursor: pointer;
    opacity: 0;
    height: inherit;
    width: inherit;
  }

  & .pdf-material-label {
    width: 100%;
    text-align: center;
    pointer-events: none;
    color: white;
    position: absolute;
    top: 0;
    background: black;
  }
`;
const PdfForm = styled.div`
  position: relative;
  height: auto;
`;

export default PdfMaterial;
