import React, { FC } from "react";
import { Link } from "react-router-dom";

import * as ROUTES from "../../../../constants/routes";
import SignOutButton from "../../../SignOut";

import { useAuthUser } from "../../../../Services/useAuthUser";
import { SideDrawerHeader } from "./SideDrawerHeader";
import styled from "styled-components";

type SideDrawerProps = {
  show: boolean;
  click: () => void;
};

// TODO: que pedo con este click, ln se que es inutil pero no me eh dado el tiempo.
const SideDrawer: FC<SideDrawerProps> = ({ show, click }) => {
  const { authUser, isAdmin } = useAuthUser();

  let drawerClasses = "side-drawer";
  if (show) {
    drawerClasses = "side-drawer open";
  }

  return (
    <>
      <SideDrawerStyle className={drawerClasses}>
        <SideDrawerHeader />
        <ul>
          <li onClick={click}>
            <a className="nav-link" href={ROUTES.LANDING}>
              Home
            </a>
          </li>
          <li onClick={click}>
            <a className="nav-link" href={ROUTES.ABOUT}>
              Sobre mi
            </a>
          </li>
          <li onClick={click}>
            <Link className="nav-link" to={ROUTES.HOME}>
              Cursos
            </Link>
          </li>
          <li onClick={click}>
            <a className="nav-link" href={ROUTES.SERVICIOS}>Servicios</a>
          </li>
          <li onClick={click}>
            <a className="nav-link" href={ROUTES.APLICACIONES}>
              Aplicaciones
            </a>
          </li>
          <li onClick={click}>
            <a className="nav-link" href={ROUTES.BLOG}>Blog</a>
          </li>
          {isAdmin && (
            <li onClick={click}>
              <Link className="nav-link" to={ROUTES.ADMIN}>
                Admin
              </Link>
            </li>
          )}
          <div className="side-drawer-footerShadow"></div>
        </ul>
        <div className="side-drawer-footer">
          {authUser ? (
            <div onClick={click}>
                <SignOutButton />
            </div>
          ) : (
            <Link className="nav-link" to={ROUTES.SIGN_IN} onClick={click}>
              Iniciar Sesión
            </Link>
          )}
        </div>
      </SideDrawerStyle>
      {show && <Backdrop onClick={click} />}
    </>
  );
};

const SideDrawerStyle = styled.nav`
  button {
    border: none;
    background: none;
    font-size: 14px;
    font-weight: 300;
    color: red;
    margin-top: 12px;
    margin-bottom: 12px;

    &:focus {
      outline: none;
    }
  }

  height: 100%;
  background: white;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(-101%);
  transition: transform 0.3s ease-out;

  &.open {
    transform: translateX(0);
  }

  .side-drawer-footer {
    height: 7%;
    background-color: white;
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;
  }
  ul {
    position: relative;
    height: 79%;
    list-style: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 31px 0 0;
    overflow: auto;
  }

  .side-drawer-footerShadow {
    position: sticky;
    width: 100%;
    min-height: 15%;
    bottom: 0;
    background: linear-gradient(
      0deg,
      #ffffff 21.25%,
      rgba(255, 255, 255, 0.239418) 69.17%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  ul > li {
    background: rgb(228, 228, 228);
    margin: 0.6rem 1rem;
    padding-bottom: 5px;
    padding-top: 5px;
    border-radius: 20px;
    text-align: center;
  }

  a {
    color: rgb(70, 70, 70);
    text-decoration: none;
    width: 100%;
    font-family: "Buda";
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
  }
  a:hover,
  a:active {
    color: #1b1b1b;
    text-decoration: none;
  }

  @media (min-width: 920px) {
    display: none;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
`;

export { SideDrawer };
