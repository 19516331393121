import {useState} from "react"

import { Error } from "../../constants/types"


export function useErrorSingIn(error: any){
  const [state,setState] = useState(error)
  
  function errorType(err: Error) {
    switch (err.code) {
      case 'auth/user-not-found':
        setState("No existe ningún registro de usuario que corresponda al proporcionado.");
        break;
      case 'auth/wrong-password':
        setState("Contraseña incorrecta");
        break; 
        case 'auth/invalid-password':
        setState("La contraseña debe contener por lo menos 6 caracteres.");
        break;
      case 'auth/wrong-email':
        setState("Correo electrónico incorrecto.");
        break;
        case 'auth/invalid-email':
        setState("El correo electrónico debe ser una dirección válida.");
        break;
      case 'auth/email-already-exists':
        setState("Otro usuario ya está utilizando este correo.")
        break;
        case 'auth/too-many-requests':
        setState("Demasiadas peticiones, espere un momento.")
        break;
      default:
        setState("Datos incorrectos.");
    }
    
  }
 return [state,errorType]
}