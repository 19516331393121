import styled, { FlattenSimpleInterpolation } from "styled-components";
import React, { FC } from "react";

export const Container = styled.div`
  /* max-width: 1024px;
    margin: 0 auto; */

  main {
    max-width: 720px;
    margin: 0 auto;
  }
`;
export const Header = styled.div`
  max-width: initial !important;
  background-color: #919788;
  color: white;
  padding: 3em 0 2em;
  div {
    max-width: 1024px;
    margin: auto;
    padding: 0 10%;

    h1,
    p {
      font-family: "Montserrat";
      text-align: center;
    }
    h1 {
      font-weight: 700;
      font-size: clamp(20px, 5.4vw, 30px);
      margin-bottom: 30px;
    }

    p {
      font-weight: 700;
      font-size: clamp(15px, 4.1vw, 20px);
    }
  }
`;

export const InscripcionesContainer = styled.div`
  text-align: center;
  margin: 3.4rem auto !important;

  h3,
  p {
    font-family: "Montserrat";
  }

  h3 {
    margin-bottom: 19px;
    font-weight: 700;
    font-size: clamp(14px, 4.6vw, 22px);
    line-height: 29px;
  }
  p {
    margin-bottom: 3rem;
    font-style: italic;
    font-weight: 400;
    font-size: clamp(11px, 3.5vw, 17px);
    line-height: 24px;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffeded;
    margin: 0 10%;
    padding: 4rem 0;

    label,
    div,
    input {
      font-family: "Montserrat";
    }
    .form-error {
      color: red;
    }

    div:last-of-type {
      font-weight: 400;
      font-size: clamp(12px, 3.5vw, 14px);
      line-height: 17px;

      input {
        margin-bottom: 30px;
        margin-right: 8px;
      }
    }

    .inscripciones-form-input {
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      label {
        width: 100%;
        text-align: left;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
      }

      input {
        margin-bottom: 15px;
        width: 100%;
        background: #f7f7f7;
        border: 1px solid #c8c8c8;
        border-radius: 3px;

        :focus {
          outline: 1px solid #c8c8c8;
        }
      }
      :nth-of-type(2) input {
        margin-bottom: 52px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    > p {
      margin-bottom: 6%;
    }

    form {
      padding: 10% 0;

      .inscripciones-form-input {
        width: 200px;
        max-width: 80%;
      }

      div:last-of-type {
        width: 80%;

        input {
          margin-bottom: 10px;
        }
      }

      button {
        margin: 5% auto;
        font-size: clamp(11px, 2.6vw, 13px);
      }
    }
  }
`;

export const IndiceContainer = styled.div`
  text-align: center;

  > * {
    margin: 0 5%;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
  }

  h3,
  p {
    font-family: "Montserrat";
  }

  h3 {
    font-weight: 700;
    font-size: clamp(14px, 4.6vw, 20px);
    line-height: 29px;
    margin-bottom: 12px;
  }

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }

  @media screen and (max-width: 768px) {
    > div {
      flex-wrap: nowrap;
      justify-content: flex-start;
      overflow: auto;
    }
  }
`;

export const IndiceComponentContainer = styled.div`
  text-align: initial;
  max-width: 270px;
  width: 100%;
  margin-top: 3%;
  margin-right: 3%;

  h5 {
    background: #919788;
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 11px;
    line-height: 15px;
    color: #ffffff;
    padding: 5px 13px;
    margin: 0;
  }

  ul {
    list-style-type: none;
    padding: 6px 14px;
    margin: 0;
    min-height: 250px;
    border: 1px solid #919788;

    p {
      margin: 0;
      font-family: "Montserrat";
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
    }
  }

  ${({ estilos }: { estilos?: FlattenSimpleInterpolation }) =>
    estilos ? estilos : null}

  @media screen and (max-width: 768px) {
    min-width: 270px;
    height: 100%;
  }
`;

export const DudasWrapper = styled.div`
  margin: 10% auto !important;

  h3 {
    font-family: "Montserrat";
    font-weight: 700;
    font-size: clamp(14px, 4.6vw, 20px);
    padding: 0 10%;
    line-height: 29px;
    text-align: center;
    margin-bottom: 40px;
  }

  ul {
    margin: 0 10%;
    padding: 0;
    list-style-type: none;

    li {
      margin-bottom: 11px;
      display: flex;
      align-content: center;
      flex-direction: column;

      h5 {
        background: #ededed;
        border-radius: 7px;
        cursor: pointer;

        font-family: "Montserrat";
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        width: 100%;
        margin: auto 0;
        padding: 9px 16px;

        @media screen and (max-width: 768px) {
          font-size: clamp(11px, 3vw, 15px) !important;
          text-align: center;
        }
      }

      p {
        transition: 0.5s;
        max-height: 0;
        overflow: hidden;
        margin: 0;

        font-family: "Montserrat";
        font-weight: 400;
        font-size: clamp(12px, 2.25vw, 14px);
        padding: 0 15px;

        @media screen and (max-width: 768px) {
          text-align: center;
        }
      }
    }
  }

  ${({ selectedIndice }: { selectedIndice: number }) =>
    selectedIndice &&
    `ul li:nth-child(${selectedIndice}) p{
            max-height: 200px;
            padding: 15px;
        }`}
`;

export const IndiceComponent: FC<{ title: string }> = ({ title, children }) => {
  return (
    <IndiceComponentContainer>
      <h5>{title}</h5>
      <ul>{children}</ul>
    </IndiceComponentContainer>
  );
};
