import styled from 'styled-components'

export const Pregunta = styled.div`
    padding: 16px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > .status {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0px 32px;
        margin-bottom: 32px;
    }

    > img {
        max-height: 512px;
        width: auto;
        max-width: 90vw;
        margin: 16px;
    }

`

export const Respuestas = styled.div`
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    gap: 16px;
    margin-bottom: 32px;
    margin-left: 32px;

    > button {
        padding: 4px 8px;
        border-radius: 4px;
        min-width: 512px;
        width: min-content;
        border: none;
        text-align: left;
        transition: background-color 500ms;
        transition: opacity 500ms;
    
        &.selected {
            background-color: rgba(0,0,70, 0.2);
        }

        &.selected.correcta {
            background-color: rgba(0,100,0,0.40);
        }

        &.selected.incorecta {
            background-color: rgba(100,0,0,0.40);
        }

        &.ocultar {
            display: none;
        }



    }
`