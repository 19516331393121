import React, { useCallback, useState } from "react";
import Confetti from "react-confetti";
import styled from "styled-components";
import { useExamenContext } from "../../ExamenContext";
import debounce from "lodash.debounce";
import { useFirebaseContext } from "../../../../Services/Firebase";
import { useAuthUser } from "../../../../Services/useAuthUser";
import { ElBotton } from "../../../../components/common/ElBotton";
import { DiplomaMencionHonorifica } from "../../diplomas/MensionHonorifica";
import { DiplomaFengshui } from "../../diplomas/Fengshui";
import { DiplomaBazi } from "../../diplomas/bazi";
import { DiplomaMillonarios2 } from "../../diplomas/Millonarios2";
import { DiplomaSanandoAmor } from "../../diplomas/SanandoAmor";
import { DiplomaMetafisicaSalud } from "../../diplomas/MetafisicaSalud";
import { DiplomaDragonMadera } from "../../diplomas/DragonMadera2024";
import { DiplomaCartasDeReemplazo } from "../../diplomas/CartasReemplazo";
import { DiplomaDesactivandoAflicciones } from "../../diplomas/DesactivandoAflicciones";

export const Resultados = () => {
  const firebase = useFirebaseContext();
  const { userRespuestas, idCourse, curso, examenData } = useExamenContext();
  const { authUser } = useAuthUser();

  const [generado, setGenerado] = useState(false);

  const aprobado = userRespuestas?.calificacion >= 70;

  const [nombreDiploma, setNombreDiploma] = useState(
    userRespuestas.nombreDiploma || ""
  );

  function handleChange(e: any) {
    setNombreDiploma(e.target.value);
    debouncedInput(e.target.value);
  }

  async function guardarNombreDiploma(value: string) {
    const snapshoot = firebase
      .cursoExamenRespuestas(idCourse)
      .doc(authUser.email);

    await snapshoot.update({ nombreDiploma: value });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedInput = useCallback(
    debounce((value: string) => {
      guardarNombreDiploma(value);
    }, 1000),
    []
  );

  const formatoDiploma = examenData?.diploma?.formato;

  const mencionHonorifica = userRespuestas?.mencionHonorifica;

  return (
    <Wrapper>
      {aprobado && <Confetti />}
      <h1>{curso.nombreCurso}</h1>

      {aprobado && <h3>¡Felicidades! ¡Aprobaste tu examen!</h3>}

      {!aprobado && <h3>Debiste haber estudiado más</h3>}

      <p>Tu calificación: {userRespuestas?.calificacion.toFixed(2)}</p>

      {!aprobado && <p>La calificación mínima aprobatoria es 70.</p>}

      {!generado && formatoDiploma && aprobado && (
        <>
          <input value={nombreDiploma} onChange={handleChange} />
          <label>
            Ingresa el nombre que quieres que aparezca en tu diploma
          </label>
        </>
      )}

      {aprobado && !generado && formatoDiploma && (
        <ElBotton color="verde" onClick={() => setGenerado(true)}>
          Generar
        </ElBotton>
      )}

      {formatoDiploma === "fengshui" && (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "16px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {generado && mencionHonorifica && (
            <DiplomaMencionHonorifica
              nombre={nombreDiploma}
              generacion={examenData.diploma.generacion}
            />
          )}

          {generado && (
            <DiplomaFengshui
              nombre={nombreDiploma}
              generacion={examenData.diploma.generacion}
            />
          )}
        </div>
      )}

      {generado && formatoDiploma === "bazi" && (
        <DiplomaBazi
          nombre={nombreDiploma}
          generacion={examenData.diploma.generacion}
        />
      )}

      {generado && formatoDiploma === "millonarios2" && (
        <DiplomaMillonarios2
          nombre={nombreDiploma}
          generacion={examenData.diploma.generacion}
        />
      )}

      {generado && formatoDiploma === "sanandoAmor"&& (
        <DiplomaSanandoAmor
          nombre={nombreDiploma}
          generacion={examenData.diploma.generacion}
        />
      )}

      {generado && formatoDiploma === "metafisicaSalud" && (
        <DiplomaMetafisicaSalud
          nombre={nombreDiploma}
          generacion={examenData.diploma.generacion}
        />
      )}

      {generado && formatoDiploma === "dragonMadera" && (
        <DiplomaDragonMadera
          nombre={nombreDiploma}
          generacion={examenData.diploma.generacion}
        />
      )}

      {generado && formatoDiploma === "cartasReemplazo" && (
        <DiplomaCartasDeReemplazo 
          nombre={nombreDiploma}
          generacion={examenData.diploma.generacion}
        />
      )}

      {generado && formatoDiploma === "desactivandoAflicciones" && (
        <DiplomaDesactivandoAflicciones
          nombre={nombreDiploma}
          generacion={examenData.diploma.generacion}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > h1 {
    margin-bottom: 64px;
  }

  > h3 {
    margin-bottom: 64px;
  }

  > input {
    text-align: center;
    border: none;
    border-bottom: 1px solid gray;
    width: 512px;
    margin-top: 64px;
    font-size: 24px;

    &:focus {
      outline: none;
    }
  }
`;
