import styled from 'styled-components'

export const Wrapper = styled.div`
    max-width: 750px;
    margin: 172px auto;
    padding: 16px;

    > h1 {
        font-family: 'Montserrat';
        font-size: 24px;
        text-align: center;
        margin-bottom: 128px;


        @media (max-width: 650px) {
            margin-bottom: 64px;
        }
    }
`

export const Content = styled.div`
    display: flex;

    > img {
        margin-right: 96px;
        height: 228px;
        width: 228px;
    }

    @media (max-width: 650px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        > img {
            margin-bottom: 64px;
        }
    }
`

export const List = styled.div`
    > div {
        display: flex;
        position: relative;
    }

    > div > img {
        position: absolute;
        top: 6px;
    }

    > div > p {
        margin-left: 32px;
    }
`

export const ConAmor = styled.div`
    margin-left: 32px;
`