import { useFirebaseContext } from "../../../Services/Firebase/context";

export type userMaterial = {
  email: string;
  data: any;
};

export const useUsersMaterials = () => {
  const firebase = useFirebaseContext();

  function getUsersMaterials(idCourse: string) {
    return new Promise<userMaterial[]>(async (resolve, reject) => {
      const materialsSnapshoot = await firebase
        .cursoAlumnosMateriales(idCourse)
        .get();
      let respuesta: userMaterial[] = [];
      materialsSnapshoot.forEach((user) => {
        respuesta.push({ email: user.id, data: user.data() });
      });
      resolve(respuesta);
    });
  }

  function uploadUserMaterial(path: string, file: any) {
    return new Promise<string>(async (resolve, reject) => {
      const storeRef = firebase.storage.ref(path);

      await storeRef.put(file);
      const url = await storeRef.getDownloadURL();

      resolve(url);
    });
  }

  function updateValuesUserMaterials(
    idCourse: string,
    emailUser: string,
    data: {}
  ) {
    return firebase.cursoAlumnoMaterial(idCourse, emailUser).set(data);
  }

  return {
    getUsersMaterials,
    uploadUserMaterial,
    updateValuesUserMaterials,
  };
};
