import React from "react";
import styled from "styled-components";
import { QuieroElLibroModal } from "../../../components/QuieroElLibroModal";

import libroImg from "../images/Curso_ebook.png";

const Container = styled.article`
  width: 100%;
  text-align: center;
  display: flex;
  margin-bottom: 16%;
  padding: 2.5%;
  background: #ffeded;
  justify-content: center;

  section {
    width: 380px;

    h3,
    p {
      font-family: "Montserrat";
      margin-bottom: 10%;
    }

    h3 {
      font-weight: 700;
      font-size: 22px;
      line-height: 24px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }

    @media screen and (max-width: 768px) {
      margin-left: 25px;
      h3 {
        font-size: clamp(16px, 3vw, 22px);
        line-height: normal;
      }

      p {
        line-height: normal;
        font-size: clamp(11px, 3vw, 14px);
      }
    }
  }

  section:last-of-type {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 70%;
      max-height: 100%;
      display: block;
      position: absolute;
    }
  }

  @media screen and (max-width: 768px) {
    padding-top: max(15px, 2.5%);
    padding-bottom: max(15px, 2.5%);
  }
`;
const ServicioBookSection = () => {
  return (
    <Container>
      <section>
        <h3>Yo soy de la tierra y mi casa de la luna.</h3>
        <p>
          Manual para entender a tu casa y no morir en el intento.
          <br />
          <br />
          Cómo convertir a tu casa en un socio de éxito.
        </p>
        <QuieroElLibroModal />
      </section>
      <section>
        <img src={libroImg} alt="Convierte tu casa en un iman de exito" />
      </section>
    </Container>
  );
};

export default ServicioBookSection;
