import styled from 'styled-components'

export const Wrapper = styled.div`
    max-width: 630px;
    margin: auto;
    padding: 24px;
    border-radius: 8px;
    background-color: white;

    p, h1, label { font-family: 'Montserrat'; }

    .description {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        margin-bottom: 15px;
        z-index: 2;
    }

    > p:last-of-type{ 
        :last-of-type{ margin-top: 20px; }
    }
    
    .libro-modal-mainContainer{
        display: flex;
        flex-direction: row;

        .libro-modal-formulario { 
            width: 60%;

            > h1 {
                margin-bottom: 24px;
                font-weight: 700;
                font-size: 22px;
                line-height: 29px;
                text-align: center;
                max-width: 300px;
            }
        }

        .libro-modal-imagen{
            width: 40%;
            padding: 0 5% 0 10%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img{
                width: 100%;
            }
        }
    }

    @media screen and (max-width: 425px){
        max-width: 90%;


        .description { line-height: normal; font-size: min(3.14vw, 14px); }
        .libro-modal-mainContainer{ 

            .libro-modal-formulario{
                width: 100%;
                z-index: 2;
                > h1{ 
                    line-height: normal;
                    font-size: min(4.4vw, 22px);} 
                }
            

            .libro-modal-imagen{ 
                display: none;
            }
        }
    }
`

export const FormFields = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    &>label {
        font-weight: bold;
        font-size: 14px;
    }

    &>input {
        background: #F7F7F7;
        border: 1px solid #DFDBDB;
        border-radius: 3px;
        margin-bottom: 16px;

        height: 29px;
    }

    @media screen and (max-width: 425px){
        margin: 0;
    }
`

export const PrivacidadCheck = styled.label`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    cursor: pointer;

    &>input {
        cursor: pointer;
    }

    &>p{
        margin: 0;
        font-size: 12px;
        margin-left: 12px;
    }
`