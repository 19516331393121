import React, { useState } from "react";
import {
  DudasWrapper,
} from "../styles";

export const DudasBazi = () => {
  const [selectedIndice, setSelectedIndice] = useState<number>();

  return (
    <DudasWrapper selectedIndice={selectedIndice}>
      <h3>Quizás tengas algunas dudas con respecto al curso</h3>
      <ul>
        <li>
          <h5 onClick={() => setSelectedIndice(1 === selectedIndice ? 0 : 1)}>
            ¿Tendré lecturas de mi carta Bazi y de mis familiares?
          </h5>
          <p>
            De hecho es requisito para tu certificación presentar la lectura de
            tu carta Bazi y la de dos personas mas. En las clases y en las
            asesorias personalizadas se estudiara tu carta bazi con distintos
            enfoques deacuerdo al avance del curso.
          </p>
        </li>
        <li>
          <h5 onClick={() => setSelectedIndice(2 === selectedIndice ? 0 : 2)}>
            ¿Tendré asesoría sobre mi carta Bazi?
          </h5>
          <p>
            Conforme avancen los temas del diplomado podras ver de forma grupal
            como se experimenta tu carta Bazi en tu vida y en la de los demas
            compañeros del diplomado. Trataremos temas especificos y nos
            enfocaremos en tomar acciones precisas para que puedas ver un cambio
            desde que inicies el diplomado.
          </p>
        </li>
        <li>
          <h5 onClick={() => setSelectedIndice(3 === selectedIndice ? 0 : 3)}>
            ¿Cómo se si este diplomado es para mi?
          </h5>
          <p>
            Si tienes interes en encontrar un alivio a tu alma encontrando tu
            trauma raiz y como tomando acciones precisas con personas concretar
            poder potencializar tu vida en todos los aspectos. Este diplomado es
            para ti.
          </p>
        </li>
        <li>
          <h5 onClick={() => setSelectedIndice(4 === selectedIndice ? 0 : 4)}>
            Temo que sea mucha información y no pueda aprovechar el contenido
            del curso.
          </h5>
          <p>
            Así es, va a haber mucha información, no solo desde la mente sino
            también desde las emociones. Descuida, he procurado conformar un
            grupo amoroso para que puedas maximizar el contenido del diplomado y
            veas resultados durante el desarrollo del mismo.
          </p>
        </li>
        <li>
          <h5 onClick={() => setSelectedIndice(5 === selectedIndice ? 0 : 5)}>
            ¿Qué métodos de pago hay?
          </h5>
          <p>
            Desde esta página web puedes realizar una compra con tarjeta de
            crédito o débito a través de la pasarela de pago segura Stripe. No
            es posible actualmente realizar la compra por Paypal, pero sí deseas
            solicitar alguna condición especial a través del correo
            hola@arleneruizfengshui.com
          </p>
        </li>
      </ul>
    </DudasWrapper>
  );
};