import React, { useState } from "react";
import Toolbar from "./components/Toolbar";
import { SideDrawer } from "./components/SideDrawer";

const Navigation = () => {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  function drawerClickHandler() {
    setSideDrawerOpen(!sideDrawerOpen);
  }

  function backdropClickHandler() {
    setSideDrawerOpen(false);
  }

  return (
    <>
      <Toolbar drawerClickHandler={drawerClickHandler} />
      <SideDrawer show={sideDrawerOpen} click={backdropClickHandler} />
    </>
  );
};

export default Navigation;
