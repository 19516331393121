import { useFirebaseContext } from "../../../Services/Firebase/context";

export const useCourses = () => {
  const firebase = useFirebaseContext();

  async function getCourseByUrl(cursoUrl: string) {
    const snapshotCourse = await firebase
      .cursos()
      .where("cursoUrl", "==", cursoUrl)
      .get();
    let respuesta: any[] = [];

    snapshotCourse.forEach((doc: any) => {
      respuesta.push({ id: doc.id, data: doc.data() });
    });

    return respuesta[0];
  }

  async function getModulesById(idCourse: string) {
    const snapshotModules = await firebase
      .cursoModulos(idCourse)
      .orderBy("indexPos")
      .get();
    let respuesta: any[] = [];
    snapshotModules.forEach((doc: any) => {
      respuesta.push({ id: doc.id, data: doc.data() });
    });
    return respuesta;
  }

  async function getModulesLessonsById(idCourse: string, isAdmin: Boolean) {
    const snapshotModules = await firebase
      .cursoModulos(idCourse)
      .orderBy("indexPos")
      .get();
    let respuesta: any[] = [];
    snapshotModules.forEach((doc: any) => {
      respuesta.push({ id: doc.id, data: doc.data() });
    });

    await Promise.all(
      respuesta.map(
        async (module: { id: string; data: any }, index: number) => {
          const snapshootLessons = await firebase
            .cursoModuloLecciones(idCourse, module.id)
            .orderBy("indexPos")
            .get();
          let respuestaLessons: any[] = [];
          snapshootLessons.forEach((doc: any) => {
            //No devolver si la leccion no posee todos los materiales y el destinatario no es un admin
            if (
              /*doc.data().pdfs !== undefined && */ doc.data().video &&
              doc.data().descripcion
            )
              respuestaLessons.push({ id: doc.id, data: doc.data() });
            else if (isAdmin)
              respuestaLessons.push({ id: doc.id, data: doc.data() });
          });

          respuesta[index] = { ...module, lessonsData: respuestaLessons };
        }
      )
    );

    return isAdmin
      ? respuesta
      : respuesta.filter((module) => module.lessonsData.length > 0);
  }

  async function deleteModuleById(idCourse: string, idModule: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const moduleRef = firebase.cursoModulo(idCourse, idModule).delete();
        resolve(moduleRef);
      } catch (error: any) {
        reject(`No se pudo eliminar el modulo` + error.message);
      }
    });
  }

  async function deleteLessonById(
    idCourse: string,
    idModule: string,
    idLesson: string
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        const moduleRef = firebase
          .cursoModuloLeccion(idCourse, idModule, idLesson)
          .delete();
        await firebase.storage
          .ref(`/cursos/${idCourse}/${idModule}/${idLesson}/`)
          .child(idLesson)
          .delete();

        resolve(moduleRef);
      } catch (error: any) {
        reject(`No se pudo eliminar la leccion` + error.message);
      }
    });
  }

  async function getCourseLessonsByModule(idCourse: string, idModule: string) {
    const snapshootLessons = await firebase
      .cursoModuloLecciones(idCourse, idModule)
      .orderBy("indexPos")
      .get();
    let respuesta: any[] = [];
    snapshootLessons.forEach((doc: any) => {
      respuesta.push({ id: doc.id, data: doc.data() });
    });
    return respuesta;
  }

  async function createModuleForCourse(idCourse: string, moduleName: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const courseRef = firebase.cursoModulos(idCourse);
        const snapshoot = await courseRef.get();

        courseRef.add({
          indexPos: snapshoot.size + 1,
          nombreModulo: moduleName,
        });

        resolve(`Se creo el modulo ${moduleName} con exito`);
      } catch (error: any) {
        reject(`No se pudo crear el modulo ${moduleName}` + error.message);
      }
    });
  }

  async function createLessonForModule(
    idCourse: string,
    idModule: string,
    lessonName: string
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        const courseRef = firebase.cursoModuloLecciones(idCourse, idModule);
        const snapshoot = await courseRef.get();

        courseRef.add({
          indexPos: snapshoot.size + 1,
          nombreLeccion: lessonName,
        });

        resolve(`Se creo la leccion ${lessonName} con exito`);
      } catch (error: any) {
        reject(`No se pudo crear la leccion ${lessonName}` + error.message);
      }
    });
  }

  async function updateValuesOfModule(
    idCourse: string,
    idModule: string,
    value: any
  ) {
    return await firebase.cursoModulo(idCourse, idModule).update(value);
  }

  async function updateValuesOfLesson(
    idCourse: string,
    idModule: string,
    idLesson: string,
    value: any
  ) {
    return firebase
      .cursoModuloLeccion(idCourse, idModule, idLesson)
      .update(value);
  }

  // TODO: Check this one, where is used?
  // no me gusta su url
  function uploadPDF(
    idCourse: string,
    idModule: string,
    idLesson: string,
    File: any
  ) {
    return new Promise<string>(async (resolve, reject) => {
      const storeRef = firebase.storage.ref(
        `/cursos/${idCourse}/${idModule}/${idLesson}/${idLesson}`
      );

      await storeRef.put(File);
      const url = await storeRef.getDownloadURL();
      updateValuesOfLesson(idCourse, idModule, idLesson, { pdfs: url });

      resolve(url);
    });
  }

  function uploadFile(storagePath: string, File: any) {
    return new Promise<string>(async (resolve, reject) => {
      const storeRef = firebase.storage.ref(storagePath);

      await storeRef.put(File);
      const url = await storeRef.getDownloadURL();

      resolve(url);
    });
  }

  async function getUserProgress(userEmail: string, idCourse: string) {
    const completedLessons = await firebase
      .leccionesCompletas(userEmail, idCourse)
      .get();
    let respuesta: any[] = [];
    completedLessons.forEach((doc: any) => {
      respuesta.push({ id: doc.id, data: doc.data() });
    });
    return respuesta;
  }

  function addUserProgress(
    userEmail: string,
    idCourse: string,
    idLesson: string
  ) {
    return firebase.leccionesCompletas(userEmail, idCourse).doc(idLesson).set({
      completado: true,
    });
  }

  function uploadLessonFirebaseVideo(
    videoFile: any,
    idCurso: string,
    idModulo: string,
    idLesson: string,
    setStatusPercentage: (x: number) => void,
    videoUrl: (x: string) => void
  ) {
    const storageRef = firebase.storage
      .ref(`cursos/${idCurso}/${idModulo}/${idLesson}/video_${idLesson}`)
      .put(videoFile);

    storageRef.on(
      "state_changed",
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setStatusPercentage(progress);

        switch (snapshot.state) {
          case "paused":
            console.log("Se ha pausado la subida del video");
            break;
          case "running":
            console.log("Se esta subiendo el video");
            break;
        }
      },
      (error) => {
        window.alert("Error al subir el video\n" + error.message);
        setStatusPercentage(-1);
      },
      () => {
        storageRef.snapshot.ref
          .getDownloadURL()
          .then((value) => videoUrl(value));
        setStatusPercentage(-1);
        return;
      }
    );
  }

  return {
    getCourseByUrl,
    getModulesById,
    getModulesLessonsById,
    getCourseLessonsByModule,
    createModuleForCourse,
    deleteModuleById,
    deleteLessonById,
    createLessonForModule,
    updateValuesOfModule,
    updateValuesOfLesson,
    uploadPDF,
    uploadFile,
    getUserProgress,
    addUserProgress,
    uploadLessonFirebaseVideo,
  };
};
