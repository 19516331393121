import styled from "styled-components";

export const CourseCreatorForm = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  background: #fbfbfb;
  height: 100%;
  width: 540px;
  right: ${(props) => (props.enable ? "0" : "-540px")};
  transition: 0.5s;
  border-left: 1px solid #c0c0c0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const BackgroundUpload = styled.div`
  min-height: 340px;
  background: green;
  height: 35%;
`;

export const CreatorForm = styled.div`
  height: 100%;
  display: flex;

  & form input {
    background: none;
    border: none;
    font-family: "Titillium Web", sans-serif;
    text-shadow: 0px 1px 1px rgb(0 0 0 / 15%);

    &:focus {
      outline-width: 0;
      border-bottom: 2px solid #c3c3c3;
    }
  }

  & .formWrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    & input:last-of-type {
      font-size: 18px;
      color: #414141;
    }

    & textarea {
      background-color: #f9f9f9;
      height: 30%;
      color: #414141;
      padding: 15px;
      border: 1px solid #f2f2f2;
      border-top: 1px solid #e2e2e2;
      border-bottom: 1px solid white;
      font-family: "Titillium Web", sans-serif;
      text-shadow: 0px 1px 1px rgb(0 0 0 / 15%);

      &:focus {
        outline-width: 0;
        border-bottom: 2px solid #c3c3c3;
      }
    }

    & input:first-child {
      font-size: 34px;
      color: #c11f6d;
      margin: 5px 0 15px;
    }

    & select {
      border: 2px solid #bebebe;
    }
  }

  & form {
    width: 100%;
    display: flex;
    flex-direction: column;

    & .formWrapper {
      padding: 20px 26px 0px;
    }

    & label {
      font-family: "Titillium Web", sans-serif;
      font-size: 13px;
    }
  }

  & p {
    display: inline;
    margin: auto;
    text-align: center;
  }

  & button {
    height: 48px;
    border-radius: 10px;
    border: none;
    margin: 0px 28px 11px;
    background-color: #c11f6d;
    color: white;
    font-family: 'Montserrat';
    font-weight: 600;
    cursor: pointer;
    
    &:hover{
      transform: scale(1.04);
    }

    &:disabled {
      background-color: #c3c3c3;
      cursor: not-allowed;
    }
  }
`;

export const DivBottom = styled.div`
  flex-grow: 1;
  display: flex;
  padding: 15px 8px;
  justify-content: space-between;

  div {
    display: flex;
    flex-direction: column;

    select {
      width: 70%;
      padding: 5px 10px;
      text-align: center;
      font-weight: bold;
      color: #4e4e4e;
    }
  }

  div:first-child {
    width: 50%;

    p {
      margin: 0;
      text-align: justify;
      font-size: 12px;
    }

    label:last-of-type {
      margin: 15px 0 0.5em 0;
    }
  }

  div:last-child {
    margin-top: 20px;

    label {
      margin-bottom: 8px;
    }

    input {
      text-align: right;
    }
  }
`;
