import styled from "styled-components";

export const CursoPreviewStyles = styled.div`
  min-width: 800px;
  width: 800px;
  margin: 0 auto;
  margin-bottom: 50px;
  padding: 0 30px;
  transition: 0.5s;
  opacity: 0;

  @media screen and (max-width: 768px) {
    padding: 0;
    width: 100%;
    min-width: initial;
  }
`;
