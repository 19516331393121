
export async function postData(url = "", data = {}) {
  try {

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
    });
    
    if (response.status !== 200) {
      throw new Error(response.statusText);
    }

    const dataResponse = await response.json();

    return dataResponse;
    
  } catch (error) {
    console.error("Failed to Post Error:", error);
  }
}

export const createCustomerPortalSession = async (authUser: any) => {

	const stripeSubscriptionLink = process.env.REACT_APP_BACKEND_SERVER_IP + '/create-customer-portal-session'

    const dataCompra = {
      customerId: authUser?.stripeCustomerId,
    };

    postData(stripeSubscriptionLink, dataCompra).then((data) => {
      if (data.redirectUrl) {
        window.open(data.redirectUrl, "_self");
      }
    }).catch((error: any) => {
      console.error('Failed to create customer portal session: ', error.message);
    });
}