import React, { useState, useContext, useRef } from "react";
import styled from "styled-components";
import { CursoPreviewData, EditPreviewProps } from "../../index";
import { useCoursePreview } from "../../hook/useCoursePreview";
import { InputContainer } from "./styles";

type moduloData = {
  id: string;
  data: { index: number; lecciones: string[]; moduloTitulo: string };
};

export const ModuleLessonCreator = ({ moduleData }: { moduleData?: moduloData }) => {
  const [isCreatingModule, setCreatingModule] = useState(false);
  const CursoData = useContext(CursoPreviewData);
  const EditData = useContext(EditPreviewProps);
  const inputReference = useRef<any>(null);
  const { addPreviewModuleLessonData } = useCoursePreview();

  function enableModuleCreation(event: any) {
    if (event.target !== inputReference.current) {
      setCreatingModule(!isCreatingModule);
    }
  }

  function onLoseFocus() {
    setCreatingModule(false);
  }

  function doCreateModule(event: any) {
    if (event.key === "Enter" && CursoData && CursoData.previewModulos) {
      //Se esta creando una leccion
      if (moduleData) {
        let leccionesArray: string[] = [];

        if (!Array.isArray(moduleData.data.lecciones))
          leccionesArray = [event.target.value];
        else if (moduleData.data.lecciones.length > 0) {
          leccionesArray = moduleData.data.lecciones.concat([
            event.target.value,
          ]);
        } else leccionesArray = [event.target.value];

        addPreviewModuleLessonData(
          { lecciones: leccionesArray },
          CursoData.id,
          moduleData.id
        )
          .then(() => {
            EditData.update();
          })
          .catch((reject) => {
            window.alert(reject);
            EditData.update();
          });
      } else
        addPreviewModuleLessonData(
          {
            index: CursoData.previewModulos.length + 1,
            moduloTitulo: event.target.value,
          },
          CursoData.id
        )
          .then(() => {
            EditData.update();
          })
          .catch((reject) => {
            window.alert(reject);
            EditData.update();
          });

      setCreatingModule(false);
    }
  }

  return (
    <ContainerCreator
      onClick={enableModuleCreation}
      isLessonCretor={moduleData}
    >
      {!isCreatingModule ? (
        <li id="course-module-creator">
          <p>{moduleData ? "Crear Leccion" : "Crear Modulo"}</p>
        </li>
      ) : (
        <InputContainer>
          <input
            autoFocus
            ref={inputReference}
            onBlur={onLoseFocus}
            onKeyPress={doCreateModule}
          />
        </InputContainer>
      )}
    </ContainerCreator>
  );
};



export const ContainerCreator = styled.div`
    margin-bottom: 10px;
    height: ${({isLessonCretor}) => isLessonCretor ? 'auto; padding-bottom: max(8px,2%);' : '38px'};

    #course-module-creator {
        display: flex;
        padding: 5px 16px;
        justify-content: flex-start;
        align-items: center;
        height: inherit;
        cursor: pointer;
        background-color: #618af17d;;
        border-radius: 5px;
        transition: 0.25s;

        p {
            margin: 0;
            text-align: left;
            line-height: 17px;
            font-family: 'Montserrat',sans-serif;
            font-size: 17px;
            font-weight: 500;
            color: white;
        }

        :hover{
            background-color: #909689;
        }
    }
 
    :last-of-type {
        margin-bottom: 0px;
    }
`