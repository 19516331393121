import React from "react";
import { useCursoContext } from "../CursoContext";
import { useAuthUser } from "../../../Services/useAuthUser";
import { useParams } from "react-router-dom";

export const PaginaDebateForo = () => {
  const { courseData } = useCursoContext();
  const authUser = useAuthUser();
  const { idDebate } = useParams<{ idDebate: string }>();

  const data = {
    cursoId: courseData?.id,
    nombreCurso: courseData?.data.nombreCurso,
    user: {
      isAdmin: authUser?.isAdmin,
      email: authUser?.authUser.email,
      username: authUser?.authUser.username,
    },
  };
  // convert to a base64 string
  const codedData = Buffer.from(JSON.stringify(data)).toString("base64");

  return (
    <iframe
      title="foro"
      src={"http://localhost:5173/forum/" + idDebate + "?data=" + codedData}
      width="100%"
      height="100%"
      frameBorder={0}
    ></iframe>
  );
};
