
import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 16px;

  > div {
    display: flex;
    justify-content: space-between;
  }
`;

export const AlumnosListContainer = styled.div`
    margin-top: 15px;
    background: #FCFCFC;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    /* pointer: cursor; */
    cursor: pointer;

    .list-element {
        height: max-content;
        width: 100%;
        text-decoration-line: none;
        border-bottom: 1px solid #BFBFBF;
        font-size: 12px;
        font-family: 'Montserrat', sans-serif;
        padding: 4px;

        .resumen {
          display: flex;
          align-items: center;
          padding: 0 20px;
           p {
            margin: 0;
            flex: 1;
            text-align: left;
           }
           span {
            margin-right: 12px;
           }
        }

        :nth-child(2n){
            background: #F9F9F9;
        }

        :hover{
            text-decoration-line: none;

            .list-details-label{
                color: #E00E0E;
            }
        }

        span {
            color: black;
        }

        p {
            color: #858585;
        }
    }
`;

export const Submition = styled.div`
  /* display: flex;
  flex-direction: column;
  border-bottom: 1px solid gray;
  padding-top: 8px;
  
  > .resumen {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 16px;
      margin-bottom: 16px;
      align-items: center;
      > p {
        margin: 0;
      }
  } */
`;

export const InformacionExtra = styled.div`
  height: max-content;
  z-index: 100;
  display: flex;
  flex-direction: column;
  padding: 16px;

    p {
        padding-left: 16px;
        width: 512px;
        border-radius: 4px;
    }

    p.correcta {
        background-color: rgba(0,100,0,0.40);
    }

    p.incorrecta {
        background-color: rgba(100,0,0,0.40);
    }

    h6 {
        font-size: 18px;
    }
  
  #respuestas {
    display: flex;
    flex-direction: column;
  }
`;