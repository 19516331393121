const landingIp = "https://arleneruiz.com";

// RUTAS A LANDING
export const LANDING = landingIp;
export const ABOUT = landingIp+"/sobre-mi";

//Servicios

export const APLICACIONES = landingIp + "/aplicaciones";
export const SERVICIOS = landingIp + "/servicios";
export const BLOG = landingIp + "/blog";
//Legals
export const LEGAL = landingIp + "/legal";
export const AVISO_PRIVACIDAD = landingIp + "/aviso-de-privacidad-pagina-web";
export const POLITICA_COOKIES = landingIp + "/politica-de-cookies";

// --- Terminan rutas externas ---

export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const ACCOUNT = "/account";
export const HOME = "/";
export const PASSWORD_FORGET = "/pw-forget";
export const ADMIN = "/admin";
export const ADMIN_USER_DETAILS = "/admin/user/:id";


export const SUCCESS_PAGE = "/compra-exitosa";

//Cursos paga
export const CURSOS_COMPRAR = "/cursos/comprar/";

//Diplomados Cerrados
export const DIPLOMADO_CERRADO_FENG_SHUI = "/curso/closed/feng-shui-clasico";
export const DIPLOMADO_CERRADO_ASTROLOGIA_BAZI = "/curso/closed/astrologia-bazi";

//user documents
export const USERDOCUMENTS = "/documents";

//Diplomados
export const DIPLOMADO2019 = "/diplomado2019";
export const ADMIN_DIPLOMADO = "/admin/diplomado";
// NOTA: Tengo mis dudas de si esta ruta es necesaria

//tablones
export const TABLON = "/tablon/:id";
export const ADMIN_TABLONES = "/admin/tablones";
export const ADMIN_TABLON_DETAILS = "/admin/tablon/:id";

//APPs
export const REGLAPUERTA = "/apps/regla";
export const MINGUA = "/apps/minggua";

//Streaming
// export const VIDEOSTREAMING = "/live";

// export const CALENDARIO = "/calendario";
// export const CALENDARIO_ACTIVIDADES = "/calendario/actividades";
// export const CALENDARIO_GLOSARIO = "/calendario/glosario";
