import React, { FC, useRef } from "react";
import { Link } from "react-router-dom";
import { useAuthUser } from "../../../../Services/useAuthUser";

import {
  CardContainer,
  CardHead,
  CardImageContainer,
  CardImage,
  CardInfoContainer,
  CardInfo,
  CardFooter,
  CreateCourseButton,
  AdminCardContainer,
} from "./styles";

type CourseCardProps = {
  id: string;
  author: string;
  title: string;
  description: string;
  background: string;
  cursoUrl: string;
  subtitulo: string;
  enableCourseEditor: (idCourse: string, isTablon?: boolean) => void;
  isBought: boolean;
  completePercentage?: number;
  isTablon?: boolean;
  errorValues?: string[];
  tipoCurso?: string;
  isClosed?: string;
};

const CourseCard: FC<CourseCardProps> = ({
  id,
  isBought,
  author,
  title,
  description,
  completePercentage,
  background,
  cursoUrl,
  subtitulo,
  isTablon,
  enableCourseEditor,
  errorValues,
  tipoCurso,
  isClosed,
}) => {
  const cardRef = useRef<HTMLDivElement>();
  // let urlToRedirect = isTablon ? `tablon/${id}` : isBought ? `curso/${cursoUrl}` : isClosed ? `curso/${isClosed}` : `curso/preview/${cursoUrl}`

  const { isAdmin } = useAuthUser();

  let urlToRedirect;

  if (isTablon) {
    urlToRedirect = `tablon/${id}`;
  } else if (isBought) {
    urlToRedirect = `curso/${cursoUrl}`;
  } else {
    // no comprado y curso cerrado
    if (isClosed) {
      urlToRedirect = `curso/${isClosed}`;
      // si esta abierto, normal
    } else {
      urlToRedirect = `curso/preview/${cursoUrl}`;
    }
  }

  function renderFailingValues() {
    let message =
      "A esta tarjeta de curso le falta agregarle los siguientes valores en el editor de cursos:";

    errorValues?.forEach((value) => (message = message + `\n- ${value}`));

    return errorValues ? (errorValues.length > 0 ? message : "") : "";
  }

  // Los tablones solo se cargan si los teniand comprados.
  if (isTablon) {
    isBought = true;
  }

  return (
    <CardContainer
      className="courseCard"
      isAdmin={isAdmin}
      ref={cardRef}
      isBought={isBought}
      isFailing={errorValues ? errorValues.length > 0 : false}
      title={renderFailingValues()}
    >
      <CardHead>
        <CardImageContainer>
          <div className="brightHeaderCard"></div>
          <CardImage backgroundImage={background} isBought={isBought}>
            <span>
              {!isBought && (
                <Link to={urlToRedirect}>
                  <i className="fas fa-shopping-cart"></i>
                  Adquirir este{" "}
                  {tipoCurso
                    ? tipoCurso.charAt(0).toUpperCase() + tipoCurso.slice(1)
                    : "Curso"}
                </Link>
              )}
            </span>
            <span>{author || ""}</span>
          </CardImage>
          <div className="shadowHeaderCard"></div>
        </CardImageContainer>
      </CardHead>

      <CardInfoContainer>
        <CardInfo>
          {(isAdmin && !isTablon) ? (
            <div id="opciones-admin">
            <h3>{title || 'Sin titulo'}</h3>

            <div>
              <Link to={`curso/preview/${cursoUrl}`}>
                <h3>Pagina Preview</h3>
              </Link>
              <Link to={`curso/${cursoUrl}`}>
                <h3>Pagina Contenido</h3>
              </Link>
            </div>
            </div>
          ) : (
            <Link to={urlToRedirect}>
              <h3>{title || "Sin titulo"}</h3>
            </Link>
          )}
          <p className="card-info">{description || "Sin descripcion"}</p>
        </CardInfo>
        <div className="shadow-card"></div>
      </CardInfoContainer>
      <CardFooter
        isAdmin={isAdmin}
        onClick={() => enableCourseEditor(id, isTablon)}
        percentage={completePercentage}
      >
        <div className="CardFooterPreview">
          <div>
            <p>{subtitulo || "Sin Subtitulo"}</p>
          </div>
        </div>
        {completePercentage && completePercentage >= 0 ? (
          <div className="CardFooter-percentage"></div>
        ) : null}
      </CardFooter>
    </CardContainer>
  );
};


type CreateCardProps = {
  toggleCreatorCard: () => void;
}

export const CreateCard: FC<CreateCardProps> = ({ toggleCreatorCard }) => {
  return (
    <AdminCardContainer className="courseCard">
      <CreateCourseButton
        onClick={toggleCreatorCard}
      >
        <div>
          <div className="circle">
            <i className="fas fa-plus-circle"></i>
          </div>
          <div className="admin-card-label">
            <p>Crear Curso</p>
          </div>
        </div>
      </CreateCourseButton>
    </AdminCardContainer>
  );
};

export default CourseCard;
