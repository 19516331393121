import React, { FC } from "react";

import loadingImage from "../loading-waiting.gif";
import CourseCard, { CreateCard } from "../CourseCard";
import { Container, Loading, Promos } from "./styles";
import { TablonesList } from "./TablonesList";
import { useAuthUser } from "../../../../Services/useAuthUser";
import { filtroPromos, filterCourses } from "./filterUtils";
import CoursesNotFoundComponent from "../CoursesNotFound";

type CourseListProps = {
  search: string;
  categoryFilter: string;
  toggleCourseCreator: () => void;
  enableCourseEditor: (idCourse: string, ref: any) => void;
  cardsList: any[];
  isLoading: boolean;
};

const CourseList: FC<CourseListProps> = ({
  search,
  categoryFilter,
  toggleCourseCreator,
  enableCourseEditor,
  cardsList,
  isLoading,
}) => {
  const { isAdmin } = useAuthUser();

  const filteredCards = filterCourses(
    cardsList,
    isAdmin,
    search,
    categoryFilter
  );

  const promos = filtroPromos(filteredCards);

  return (
    <Container>
      {isLoading && (
        <Loading>
          <img alt="loading" src={loadingImage} />
        </Loading>
      )}

      {/* TODO this is its own component, move to own file. */}
      {/* logic for Promos */}
      {!isLoading && categoryFilter === "" && (
        <Promos>
          <h5>Novedades</h5>

          <div className="row__listaCursos">
            {promos.map((promoCourse, index) => (
              <CourseCard
                key={index}
                id={promoCourse.id}
                author={promoCourse.autor}
                title={promoCourse.nombreCurso}
                description={promoCourse.descripcion}
                background={promoCourse.background}
                cursoUrl={promoCourse.cursoUrl}
                subtitulo={promoCourse.subtitulo}
                enableCourseEditor={enableCourseEditor}
                isBought={promoCourse.isBought || false}
                completePercentage={promoCourse.completePercentage || -1}
                errorValues={promoCourse.errorValues}
                tipoCurso={promoCourse.tipoCurso}
                isClosed={promoCourse.isClosed}
              />
            ))}
          </div>
          {promos.length === 0 && (
            <CoursesNotFoundComponent
              search={search}
              toggleCourseCreator={toggleCourseCreator}
            />
          )}
        </Promos>
      )}
      {/* finish promo component. */}

      {/* logic for courses */}
      {!isLoading && (
        <div className="row__listaCursos">
          {filteredCards
            .filter((course) => !course.promocionado)
            .map((course, index) => (
              <CourseCard
                key={index}
                id={course.id}
                author={course.autor}
                title={course.nombreCurso}
                description={course.descripcion}
                background={course.background}
                cursoUrl={course.cursoUrl}
                subtitulo={course.subtitulo}
                enableCourseEditor={enableCourseEditor}
                isBought={course.isBought || false}
                completePercentage={course.completePercentage || -1}
                errorValues={course.errorValues}
                tipoCurso={course.tipoCurso}
                isClosed={course.isClosed}
              />
            ))}
          {promos.map((promoCourse, index) => (
            <CourseCard
              key={index}
              id={promoCourse.id}
              author={promoCourse.autor}
              title={promoCourse.nombreCurso}
              description={promoCourse.descripcion}
              background={promoCourse.background}
              cursoUrl={promoCourse.cursoUrl}
              subtitulo={promoCourse.subtitulo}
              enableCourseEditor={enableCourseEditor}
              isBought={promoCourse.isBought || false}
              completePercentage={promoCourse.completePercentage || -1}
              errorValues={promoCourse.errorValues}
              tipoCurso={promoCourse.tipoCurso}
              isClosed={promoCourse.isClosed}
            />
          ))}
          {isAdmin && (
            <CreateCard key={"cc-1"} toggleCreatorCard={toggleCourseCreator} />
          )}
        </div>
      )}

      {!isLoading && filteredCards.length === 0 && (
        <CoursesNotFoundComponent
          search={search}
          toggleCourseCreator={toggleCourseCreator}
        />
      )}

      <TablonesList enableCourseEditor={enableCourseEditor} />
    </Container>
  );
};

export default CourseList;
