import React, { useRef, FC, useEffect } from "react";
import styled from "styled-components";
import { ElBotton } from "../../../components/common/ElBotton";

import diplomabase from './diploma-honorifico.jpeg';

const Wrapper = styled.div`
  padding: 8px;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

type DiplomaMencionHonorificaProps = {
  nombre: string;
  generacion: string;
};

export const DiplomaMencionHonorifica: FC<DiplomaMencionHonorificaProps> = ({
  nombre,
  generacion,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    function generar() {
      if (!canvasRef.current) return;

      const context = canvasRef.current.getContext("2d");
      const diploma = new Image();
      diploma.crossOrigin = "anonymous"

      diploma.onload = function () {
        if (!context) return;
        context.drawImage(diploma, 0, 0);
        context.font = "52pt serif";
        context.fillText(nombre, 150, 512);

        context.fillStyle = "white";

        context.font = "32pt sans-serif";
        context.fillText(generacion, 320, 968);
      };

      diploma.src = diplomabase;
    }

    generar();
  }, [generacion, nombre]);

  function download() {
    var link = document.createElement("a");
    link.download = "diploma.png";
    link.href = canvasRef?.current?.toDataURL() as string;
    link.click();
  }

  return (
    <Wrapper>
      <h4>Felicidades!, Eres un alumno destacado!</h4>
      <canvas
        width={1280}
        height={993}
        ref={canvasRef}
        style={{ width: "auto", height: "512px", marginBottom: '16px' }}
      ></canvas>
      <ElBotton onClick={download}>descargar</ElBotton>
    </Wrapper>
  );
};
