import React from "react";
import { useHistory } from "react-router-dom";
import { ElBotton } from "../../components/common/ElBotton";
import ServicioBookSection from "../servicios/components/ServicioBookSection";

import { Container, Titulo, Wrapper } from "./styles";

const NotFound = () => {
  const history = useHistory();

  return (
    <Wrapper>
      <Container>
        <Titulo>¡ Esto es una vergüenza !</Titulo>

        <p>La página que buscas no está. Es una página de error 404.</p>
        <p>
          Lo que sí vas a encontrar en la página principal es contenido
          interesante sobre metafísica china.
        </p>

        <ElBotton style={{marginLeft: 0, marginTop: '55px'}} onClick={() => history.push("/")}>
          Quiero ir a la página principal
        </ElBotton>
      </Container>

      {/* Buscar eh importar el fotter del libro */}
      <ServicioBookSection />
    </Wrapper>
  );
};
<h1>Página no encontrada</h1>;

export default NotFound;
