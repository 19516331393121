import React from "react";
import styled from "styled-components";
import { arrowBullet2 } from "../../assets/arrowBullet2";

const PromoPlataformaStyles = styled.div`
  display: flex;
  margin-top: 96px;

  .main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .flex {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }
  svg {
    display: flex;
    justify-content: flex-start;
    max-width: 20px;
    min-width: 20px;
    margin-right: 20px;
  }

  h3 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;

    color: #795e44;

    margin-bottom: 43px;
    margin-left: 40px;
  }

  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;

    color: #000000;

    margin-bottom: 32px;
  }

  > div:last-of-type {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 361px;
      height: 265px;
      /* height: auto; */
      margin: 0 auto;
      /* margin-left: 36px; */
    }
  }
  @media screen and (max-width: 550px) {
    padding: 0 30px 0 30px;
    flex-direction: column;
    align-items: center;
    h3 {
      text-align: center;
      margin: 24px auto 24px auto;
    }
    .image-container {
      margin-top: 30px;
    }
  }
`;

export const PromoPlataforma = () => {
  return (
    <PromoPlataformaStyles>
      <div className="main-container">
        <h3>Fácil, intuitivo y práctico</h3>
        <div className="flex">
          {arrowBullet2}
          <p>Área de alumnos con clase en vivo grabada y documentos de soporte.</p>
        </div>
        <div className="flex">
          {arrowBullet2}
          <p>Acceso por tres años al curso.</p>
        </div>
        <div className="flex">
          {arrowBullet2}
          <p>Plantillas descargables con infografías y documentos de respaldo.</p>
        </div>
        <div className="flex">
          {arrowBullet2}
          <p>Acceso a los proyectos de los compañeros del curso para reforzar el contenido del curso.</p>
        </div>
      </div>
      <div className="image-container">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/web-arlene.appspot.com/o/assets%2Fdownload-799x565%201.png?alt=media&token=bc1b2af7-9888-4a17-ae6e-bb166d5f167a"
          alt="Plataforma"
        />
      </div>
    </PromoPlataformaStyles>
  );
};
