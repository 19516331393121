import React from "react";
import styled from "styled-components";


const ArlenePresentation = ({ addMargin }: { addMargin?: boolean }) => {
  return (
    <Container style={{ marginTop: "78px" }}>
      <ContentWrapper>
        <div className="p-container">
          <p>
            Hola, soy Arlene Ruiz. Apasionada del crecimiento personal y la metafísica china. Brindo técnicas milenarias para usar la energía a tu
            favor. 15 años y cientos de clientes son la prueba de que no solo doy conocimiento, transmito resultados.
          </p>
        </div>
        <div className="img-container">
          <img src="https://firebasestorage.googleapis.com/v0/b/web-arlene.appspot.com/o/assets%2Fcursos-preview%2FPAGINA-02.jpg?alt=media&token=b8e558e0-f0ff-441d-b607-59a2a58c8c9f" alt="Arlene" />
        </div>
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.div`
  border: 2px dashed #fd0000;
  border-radius: 15px;
  padding: 25px;
  ${({ addMargin }) => (addMargin ? "margin-top: 78px;" : "")}

  @media screen and (max-width: 550px) {
    margin: 0 4%;
  }
`;
const ContentWrapper = styled.div`
  max-width: 80%;
  margin: auto;
  max-height: 160px;
  display: flex;
  align-items: center;

  .p-container {
    height: 100%;
    max-width: 63%;
    display: flex;
  }
  p {
    height: fit-content;
    margin: auto;
    font-family: "Montserrat";
    font-weight: 400;
    font-size: clamp(12px, 2.25vw, 14px);
  }
  .img-container {
    padding-left: 2%;
    max-width: 37%;
    display: flex;
  }
  img {
    margin: auto;
    max-height: 100%;
    width: 100%;
    display: block;
  }
  @media screen and (max-width: 550px) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    text-align: justify;
    max-height: none;

    .p-container {
      margin-top: 20px;
      max-width: 70%;
    }

    .img-container {
      padding-left: 2%;
      max-width: 70%;
      display: flex;
    }
  }
`;

export default ArlenePresentation;
