import React, { useContext, useRef } from "react";
import { EditPreviewProps, CursoPreviewData } from "../../index";
import { useCoursePreview } from "../../hook/useCoursePreview";
import {
  CursoHeaderStyles,
  MainHeaderContent,
} from "./styles";
import { useAuthUser } from "../../../../Services/useAuthUser";
import { ElBotton } from "../../../../components/common/ElBotton";
import { useUploadFile } from "../../hook/useUploadFile";
import { InputComponent } from "./InputComponent";
import { CallToAction } from "./CallToAction";


const CursoHeader = ({ id }: { id: string }) => {
  const cursoData = useContext(CursoPreviewData);
  const { addCoursePreviewData } = useCoursePreview();
  const EditData = useContext(EditPreviewProps);

  const { isAdmin } = useAuthUser();

  const imageUploadRef = useRef<HTMLInputElement>(null);
  const uploadFile = useUploadFile();
  function uploadImage() {
    if (imageUploadRef.current) {
      imageUploadRef.current.click();
    }
  }

  async function upload(e: any) {
    if (e.target.files[0] && cursoData?.id) {
      try {
        const url = await uploadFile(e.target.files[0], cursoData.id);
        const resolve = await addCoursePreviewData(cursoData.id, {
          previewImageAlt: url,
        });
        EditData.update();
        window.alert(resolve);
      } catch (e: any) {
        console.error("Failed to upload PreviewImageAlt", e.message);
      }
    }
  }

  return (
    <CursoHeaderStyles>
      <MainHeaderContent isAdmin={isAdmin}>
        <img
          id="portada-curso"
          src={cursoData?.previewImageAlt || cursoData?.background}
          alt="Portada del curso"
        />
        {isAdmin && (
          // le ponemos un position absolute a esto va?
          <div id="admin-portada-curso">
            <input
              type="file"
              accept=".jpg, .png"
              ref={imageUploadRef}
              style={{ opacity: 0 }}
              onChange={upload}
            />
            <ElBotton style={{ margin: 0 }} onClick={uploadImage}>
              Cambiar Imagen
            </ElBotton>
          </div>
        )}

        <div id="otra-mitad">
          <InputComponent
            nombreDato={"previewTitulo"}
            value={cursoData?.previewTitulo}
            containerClassName="header-preview-title"
            InputFieldType={"input"}
          >
            <h3>
              {cursoData?.previewTitulo ||
                (isAdmin ? "Asigne un Título" : "Sin título")}
            </h3>
          </InputComponent>

          <InputComponent
            nombreDato={"previewDescripcion"}
            containerClassName="header-preview-description"
            InputFieldType={"textarea"}
            value={cursoData?.previewDescripcion}
          >
            <p>
              {cursoData?.previewDescripcion ||
                (isAdmin ? "Asigne una descripcion" : "Sin descripción")}
            </p>
          </InputComponent>
        </div>
      </MainHeaderContent>

      <CallToAction id={id} />
    </CursoHeaderStyles>
  );
};

export default CursoHeader;
