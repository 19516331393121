import React from "react";

import { withFirebase } from "../../Services/Firebase";
import { useAuthUser } from "../../Services/useAuthUser";
const card = {
  backgroundColor: "white",
  marginTop: "30px",
  width: "70%",
  maxWidth: "400px",
  padding: "15px",
  borderRadius: "2%",
  margin: "0 auto",
};

const withEmailVerification = (Component) => {
  const WithEmailVerification = (props) => {
    const [isSend, setIsSend] = React.useState(false);

    const { authUser } = useAuthUser();

    const onSendEmailVerification = () => {
      props.firebase.doSendEmailVerification().then(() => setIsSend(true));
    };

    return needsEmailVerification(authUser) ? (
      <div style={card}>
        {isSend ? (
          <p>
            Correo de verificación enviado: Revisa tu bandeja de entrada por un
            correo de confirmacion.
          </p>
        ) : (
          <p>
            Verifica tu Correo electronico, te mandamos un Correo para confirmar
            tu cuenta. ¿no has recibido el correo? te mandamos otro.
          </p>
        )}

        <button
          className="boton"
          type="button"
          onClick={onSendEmailVerification}
          disabled={isSend}
        >
          Mandar correo de confirmacion.
        </button>
      </div>
    ) : (
      <Component {...props} />
    );
  };
  return withFirebase(WithEmailVerification);
};

const needsEmailVerification = (authUser) =>
  authUser && !authUser.emailVerified;

export default withEmailVerification;
