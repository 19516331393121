import React, { FC, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import { useCourses } from "../../hooks/useCourses";
import { useCursoContext } from "../../CursoContext";
import { useAuthUser } from "../../../../Services/useAuthUser";

export const ImagenBanner: FC<{ moduloImgUrl: string }> = ({
  moduloImgUrl,
}) => {
  const { isAdmin } = useAuthUser();
  const { uploadFile, updateValuesOfModule } = useCourses();

  const { courseData, doModuleLessonRefresh: refresh } = useCursoContext();

  const urlParams = useParams<any>();
  const courseId = courseData?.id as string;

  const [subiendo, setSubiendo] = useState(false);

  async function handleUpload(e: any) {
    e.stopPropagation();

    setSubiendo(true);
    const file = e.target.files[0];
    const path = `/cursos/${courseId}/${urlParams.idModule}/${urlParams.idModule}`;

    const resolve = await uploadFile(path, file);
    // const resolve = await uploadFile(path, file).then((resolve) => {
    await updateValuesOfModule(courseId, urlParams.idModule, {
      moduloImgUrl: resolve,
    });

    setSubiendo(false);

    refresh();
  }
  return (
    <ImageContainer pageHeight={480} bgImage={moduloImgUrl}>
      {subiendo && <i className="fas fa-spinner fa-spin"></i>}
      {isAdmin && (
        <InputFile>
          <input
            type="file"
            accept=".jpg, .png"
            title="Subir Imagen de Fondo"
            onChange={handleUpload}
          />
          {!moduloImgUrl && (
            <div className="module-background-label">
              <strong>Este modulo aun no contiene una imagen</strong>
              <p>Presione esta sección para agregar uno</p>
            </div>
          )}
        </InputFile>
      )}
    </ImageContainer>
  );
};

const ImageContainer = styled.div`
  height: ${({ pageHeight }) => (pageHeight ? pageHeight : 0)}px;
  background-size: cover;

  ${({ bgImage }) =>
    bgImage
      ? `background-image: url(${bgImage});`
      : "border: 2px dashed black;"}
`;

const InputFile = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;
  height: inherit;

  input {
    color: transparent;
  }
  input[type="file"] {
    cursor: pointer;
    opacity: 0;
    height: inherit;
    width: inherit;
  }

  .module-background-label {
    font-size: 20px;
    width: 100%;
    text-align: center;
    pointer-events: none;
    position: absolute;
    top: 0;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
