import styled from "styled-components";

export const ModifyDeleteContainer = styled.span`
  transition: 0.5s;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  padding: 0 5px;
  align-items: center;
  justify-content: center;
  right: -58px;

  i {
    margin: 0 5px;
    color: gray;
    cursor: pointer;

    :first-of-type:hover {
      color: #618af17d;
    }
    :last-of-type:hover {
      color: crimson;
    }
  }
`;

export const InputContainer = styled.div`
  background-color: #909689;
  border-radius: 5px;
  padding: 1px 8px 1px;
  height: inherit;

  input {
    margin-top: 2px;
    height: 100%;
    outline-width: 0;
    border: none;
    width: 100%;
    background: none;

    font-family: "Montserrat", sans-serif;
    font-size: 17px;
    color: white;
  }

  input:focus {
    height: 100%;
    outline-width: 0;
    border: none;
  }
`;

export const Container = styled.div`
  margin: 78px 0;

  @media screen and (max-width: 768px) {
    padding: 0 8%;
  }

  h3 {
    font-family: "Montserrat";
    font-weight: 700;
    font-size: min(4.7vw, 22px);
  }

  p {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: min(3.2vw, 14px);
  }

  ul {
    padding: 0;

    .menu-element {
      display: flex;
      flex-flow: row;
      align-content: center;
      flex-direction: column;

      > h6 {
        position: relative;
        font-family: "Montserrat";
        font-weight: 600;
        font-size: min(2.86vw, 15px);
        line-height: 20px;
        height: fit-content;
        margin: auto 0;
        padding: 0 16px;
        background: #ededed;
        border-radius: 7px;
        margin-bottom: max(8px, 2%);
        height: 38px;
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;
        overflow: hidden;

        :hover {
          .module-delete-button {
            right: 0;
          }
        }
      }
    }
  }
`;
