import React, { useState } from "react";
import { useExamenContext } from "../../ExamenContext";
import { Resultados } from "./Resultados";
import { UserQuestionary } from "./UserQuestionary";
import { UserWellcomeView } from "./UserWellcomeView";

export const UserHome = () => {
  // keep state to kown where the user is
  const [currentView, setCurrentView] = useState("inicio");
  function comenzarExamen() {
    setCurrentView("examen");
  }

  function irResultados() {
    setCurrentView("resultado");
  }

  // if examen completado ir a respuestas
  const { userRespuestas, examenData } = useExamenContext();

  return (
    <>
      {examenData && userRespuestas ? (
        <>
          {currentView === "inicio" && (
            <UserWellcomeView
              comenzarExamen={comenzarExamen}
              irResultados={irResultados}
            />
          )}
          {currentView === "examen" && (
            <UserQuestionary irResultados={irResultados} />
          )}
          {currentView === "resultado" && <Resultados />}
        </>
      ) : (
        <></>
      )}
    </>
  );
};
