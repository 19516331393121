import React from "react";
import { Link, useHistory } from "react-router-dom";

import * as ROUTES from "../../../../constants/routes";

import styled, { css } from "styled-components";

import { useAuthUser } from "../../../../Services/useAuthUser";

export const SideDrawerHeader = () => {
  const redirect = useHistory();
  const { authUser } = useAuthUser();

  function redirectToAccount() {
    if (authUser) {
      redirect.push(ROUTES.ACCOUNT);
    } else {
      redirect.push(ROUTES.SIGN_IN);
    }
  }


  return (
    <SideDrawerHeaderStyle
      isAuth={!!authUser}
      onClick={redirectToAccount}
    >
      {authUser ? (
        <>
          <div className="drawer-header-user">
            <div className="drawer-user-image">
              <img src={authUser.avatar || authUser.socialMediaPhotoUrl || "https://firebasestorage.googleapis.com/v0/b/web-arlene.appspot.com/o/assets%2FdefaultUserImg.jpg?alt=media&token=c49c3148-1de6-4f76-9efd-7f577903273d"} alt="Imagen de usuario" />
            </div>
            <div className="drawer-header-userInfo">
              <h4>{authUser.username}</h4>
              <div className="drawer-header-blueLabel">
                <Link to={ROUTES.ACCOUNT}>
                  <p>Configuración de Cuenta</p>
                </Link>
              </div>
            </div>
          </div>
          <div className="drawer-header-shadow"></div>
          <div className="drawer-header-light"></div>
        </>
      ) : (
        <>
          <div className="drawer-header-user">
            <div className="drawer-header-userInfo">
              <Link to={ROUTES.SIGN_IN}>
                <h4>Iniciar Sesión</h4>
              </Link>
            </div>
          </div>
        </>
      )}
    </SideDrawerHeaderStyle>
  );
};

const noAuthStyle = css`
  padding: 0;

  .drawer-header-userInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0 !important;

    h4 {
      margin: 0 !important;
      text-shadow: none !important;
    }
  }
`;

const SideDrawerHeaderStyle = styled.div<{isAuth: boolean}>`
  ${({ isAuth }) => (isAuth ? `background-image: url(https://firebasestorage.googleapis.com/v0/b/web-arlene.appspot.com/o/assets%2Fmontains.png?alt=media&token=a613f338-1708-4266-8fd3-8c7951dd6a6e);` : null)}
  position: relative;
  background-size: cover;
  background-position-y: 8%;

    height: 12%;
    background-color: gray;

  .drawer-header-shadow {
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.62) 11.35%,
      rgba(0, 0, 0, 0.375385) 28.54%,
      rgba(0, 0, 0, 0) 57.71%
    );
  }

  .drawer-header-light {
    position: absolute;
    top: 1px;
    height: 30%;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.6956) 0%,
      rgba(255, 255, 255, 0.94) 10.31%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .drawer-header-user {
    padding: 20px 10% 12px;
    z-index: 2;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    ${({ isAuth }) => (isAuth ? null : noAuthStyle)}

    .drawer-user-image {
      height: auto;
      width: 46px;
      height: 46px;
      border-radius: 50%;

      img {
        filter: drop-shadow(0px 0px 9px #000000);
        border-radius: 50%;
        border: 2px solid #ffffff;
        max-height: 100%;
        max-width: 100%;
        height: 100%;
        width: 100%;
      }
    }

    .drawer-header-userInfo {
      flex-grow: 1;
      margin-top: 4px;
      position: relative;

      h4 {
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: #ffffff;
        text-shadow: -2px -2px 4px #000000, -2px 1px 4px #000000;
        margin-bottom: 4px;
        margin-right: 7%;
      }

      div {
        position: absolute;
        width: 113%;
        left: -13%;
        height: 56%;
        z-index: -1;
        background: #257ed0;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          font-family: "Montserrat";
          font-weight: 600;
          font-size: 11px;
          line-height: 13px;
          text-align: center;
          color: #ffffff;
          margin: 0 0 0 13px;
        }
      }

      @media screen and (max-width: 425px) {
        h4 {
          font-size: min(20px, 5vw);
        }
        div p {
          font-size: min(11px, 2.7vw);
        }
      }
    }
  }
`;
