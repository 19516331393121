import React, { FC, useState, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ModuleListElement, { ModuleCreator } from "./ModuleListElement";
import { BottonExamen } from "./BottonExamen";
import { CourseProgress } from "../CursoProgress";
import { useAuthUser } from "../../../../Services/useAuthUser";
import { BurgerMenu } from "./BurgerMenu";
import { useCursoContext } from "../../CursoContext";
import { useHistory, useParams } from "react-router-dom";

export const CourseNavigation: FC = () => {
  const { isAdmin } = useAuthUser();
  const [openNav, setOpenNav] = useState(false);
  const navRef = useRef<HTMLElement>(null);

  const { courseUrl } = useParams<{
    courseUrl: string;
  }>();

  const history = useHistory();

  const {
    courseData,
    courseModules,
    doModuleLessonRefresh: refresh,

    // TODO: por que el nombre tan parecido??
    percentageCompleted,
    userCourseProgress,
  } = useCursoContext();

  const foroFF = process.env.REACT_APP_FORUM_FF === "true";

  return (
    <>
      <BurgerMenu setOpenNav={setOpenNav} />
      <CourseNav navMenuWidth={navRef.current?.clientWidth}>
        <div
          className={
            openNav
              ? "course-nav-container--open course-nav-container"
              : "course-nav-container"
          }
        >
          <header>
            <h1>{courseData?.data.nombreCurso.toUpperCase()}</h1>
          </header>
          <p>Tu avance</p>
          {isAdmin ? null : <CourseProgress percentage={percentageCompleted} />}
          <Link
            to={`/curso/${courseData?.data.cursoUrl}/materiales`}
            className="course-subtitle"
            style={{ color: "black" }}
          >
            <h6 title="Trabajos de usuarios">{courseData?.data.subtitulo}</h6>
          </Link>
          <nav ref={navRef}>
            <ul>
              {courseModules?.map((module: any) => {
                return (
                  <ModuleListElement
                    idCourse={courseData?.id || ""}
                    courseURL={courseData?.data?.cursoUrl || ""}
                    idModule={module.id}
                    key={module.id}
                    moduleData={module.data}
                    refresh={refresh}
                    lessonData={module.lessonsData}
                    userCourseProgress={userCourseProgress}
                  />
                );
              })}

              {isAdmin ? (
                <ModuleCreator
                  key={courseData?.id || ""}
                  onCreation={refresh}
                  idCourse={courseData?.id || ""}
                />
              ) : null}
            </ul>
          </nav>

          {/* Aqui va el FF check */}
          {foroFF && (
            <ForoButton
              onClick={() => {
                history.push(`/curso/${courseUrl}/foro`);
              }}
            >
              Foro - P & R
            </ForoButton>
          )}

          <BottonExamen courseId={courseData?.id || ""} />
        </div>

        {openNav && (
          <div
            className={
              openNav ? "curso-backdrop curso-backdrop--open" : "curso-backdrop"
            }
            onClick={() => {
              setOpenNav(false);
            }}
          ></div>
        )}
      </CourseNav>
    </>
  );
};

const ForoButton = styled.button`
  border-radius: 5px;
  border: none;
  width: 217px;
  margin-bottom: 12px;
  background: #909689;
  text-align: left;

  color: #fdfdfd;

  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const CourseNav = styled.div`
  height: 100%;
  padding: 0 15px;
  background: #f8f8f8;
  max-height: 100%;
  max-width: 280px;

  .course-nav-container {
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;

    nav {
      padding: 15px 0;
      flex-grow: 1;
      overflow-y: overlay;
      width: fit-content;
      min-width: 250px;
      ${({ navMenuWidth }) => (navMenuWidth ? "width: auto;" : null)}
      border-radius: 8px;
    }

    > p {
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      margin: 15px auto 0;
    }
  }

  header {
    padding-top: 16px;
    margin: auto;
    max-width: fit-content;
  }

  h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    text-align: center;
    margin: 0;
    font-weight: 700;
  }

  h6 {
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    font-size: 20px;
    text-align: center;
    padding-top: 5px;
    margin: 0;
    font-weight: 400;
    color: black;
  }

  ul {
    padding: 0;
    list-style-type: none;
    margin: 0;
  }
  @media screen and (max-width: 550px) {
    position: absolute;
    padding: 0;

    .course-nav-container {
      position: absolute;
      height: inherit;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 24;
      background-color: #f8f8f8;
      padding: 0 20px 0 20px;
      transition: all 0.3s ease-in-out;
      transform: translateX(-800px);
    }
    .course-nav-container--open {
      transform: translateX(0px);
    }
  }
  .curso-backdrop {
    position: absolute;
    width: 100vw;
    height: 100%;
    z-index: 23;
    background-color: #00000000;
    transform: translateX(-800px);
    transition: all 0.3s;
  }
  .curso-backdrop--open {
    display: flex;
    transform: translateX(0px);
    background-color: #00000050;
  }
`;
