import { useFirebaseContext } from "../../../Services/Firebase/context";
import { Curso, CursoTablon } from "../../../constants/types";

type Response = {
  id: string;
  data: Curso;
};

const useCursosCards = () => {
  const firebase = useFirebaseContext();

  //Obtiene un array de objectos que contiene cada documento de curso en formato de CourseCard
  async function getCourseCards() {
    const snapshot = await firebase.cursos().orderBy("tipoCurso", "desc").orderBy('createdAt', 'desc').get();
    let respuesta: Response[] = [];
    snapshot.forEach((doc: any) => {
      respuesta.push({
        id: doc.id,
        data: doc.data(),
      });
    });
    return respuesta;
  }

  function getCourseDataById(idCourse: string) {
    return new Promise<{ id: string; data: any }>(async (resolve, reject) => {
      const snapshot = await firebase.curso(idCourse).get();
      resolve({ id: snapshot.id, data: snapshot.data() });
    });
  }

  function getTablonesCards() {
    return new Promise<CursoTablon[]>(async (resolve, reject) => {
      try {
        const snapshoot = await firebase.getCollectionRef("tablones").get();
        let tablones: CursoTablon[] = [];

        snapshoot.forEach((tablon: CursoTablon | any) => {
          tablones.push({
            id: tablon.id,
            autor: tablon.data().autor || tablon.data().author,
            nombreCurso: tablon.data().nombreCurso || tablon.data().name,
            descripcion: tablon.data().descripcion || tablon.data().description,
            background: tablon.data().background || tablon.data().image,
            subtitulo: tablon.data().subtitulo,
            cursoUrl: tablon.data().cursoUrl,
            precio: tablon.data().precio || tablon.data().price,
            tipoCurso: "tablon",
          });
        });

        resolve(tablones);
      } catch (error) {
        reject(error);
      }
    });
  }

  function getTablonById(tablonId: string) {
    return new Promise<{ id: string; data: any }>(async (resolve, reject) => {
      try {
        const tablon: CursoTablon | any = await firebase
          .getCollectionRef("tablones")
          .doc(tablonId)
          .get();


        if (!tablon.id) {
          throw new Error(`No se encontro el tablon ${tablonId}`);
        }

        console.log('haber por que vergas tablon?', tablon.data());

        const res: { id: string; data: any } = {
          id: tablon.id,
          data: {
            autor: tablon.data().autor || tablon.data().author,
            nombreCurso: tablon.data().nombreCurso || tablon.data().name,
            descripcion: tablon.data().descripcion || tablon.data().description,
            background: tablon.data().background || tablon.data().image,
            subtitulo: tablon.data().subtitulo,
            cursoUrl: tablon.data().cursoUrl,
            precio: tablon.data().precio || tablon.data().price,
            tipoCurso: "tablon",
          },
        };

        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  }

  return {
    getCourseCards,
    getCourseDataById,
    getTablonesCards,
    getTablonById,
  };
};

export default useCursosCards;
