import React, { useContext, useRef, useState } from "react";
import styled from "styled-components";
import { CursoPreviewData, EditPreviewProps } from "../../index";
import { useCoursePreview } from "../../hook/useCoursePreview";
import { InputContainer, ModifyDeleteContainer } from "./styles";
import { arrowBullet } from "../../assets/arrowBullet";

type moduloData = {
  id: string;
  data: { index: number; lecciones: string[]; moduloTitulo: string };
};

export const LessonPreview = ({
  titulo,
  index,
  lessonArray,
  moduleData,
}: {
  titulo: string;
  index: number;
  lessonArray: string[];
  moduleData: moduloData;
}) => {
  const [isModifyModule, setModifyModule] = useState(false);
  const { addPreviewModuleLessonData } = useCoursePreview();
  const cursoID = useContext(CursoPreviewData)?.id;
  const EditData = useContext(EditPreviewProps);
  const inputReference = useRef<any>(null);

  function enableModuleModification(event: any) {
    if (event.target !== inputReference.current) {
      setModifyModule(!isModifyModule);
    }
  }

  function onLoseFocus() {
    setModifyModule(false);
  }

  function renameLesson(event: any) {
    if (event.key === "Enter") {
      lessonArray[index] = event.target.value;

      if (cursoID) {
        addPreviewModuleLessonData(
          { ...moduleData, lecciones: lessonArray },
          cursoID,
          moduleData.id
        )
          .then((resolve) => {
            window.alert(resolve);
            EditData.update();
          })
          .catch((reject) => {
            window.alert(reject);
            EditData.update();
          });
        setModifyModule(false);
      }
    }
  }

  function deleteLesson() {
    let nuevoLessons = lessonArray;
    nuevoLessons.splice(index);

    if (cursoID) {
      addPreviewModuleLessonData(
        { ...moduleData, lecciones: nuevoLessons },
        cursoID,
        moduleData.id
      )
        .then((resolve) => {
          EditData.update();
        })
        .catch((reject) => {
          window.alert("ocurrio un error" + reject);
          EditData.update();
        });
    }

    setModifyModule(false);
  }

  return isModifyModule ? (
    <InputContainer style={{ marginBottom: "max(8px,2%)" }}>
      <input
        autoFocus
        ref={inputReference}
        onBlur={onLoseFocus}
        onKeyPress={renameLesson}
        defaultValue={titulo}
      />
    </InputContainer>
  ) : (
    <ListElement className="submenu-element" key={index}>
      {arrowBullet}
      <h6>{titulo}</h6>
      {EditData.isAdmin ? (
        <ModifyDeleteContainer className="module-delete-button">
          <i
            className="fas fa-pen-square"
            onClick={enableModuleModification}
          ></i>
          <i className="fas fa-trash-alt" onClick={deleteLesson}></i>
        </ModifyDeleteContainer>
      ) : null}
    </ListElement>
  );
};

const ListElement = styled.li`
  margin-bottom: max(8px, 2%);
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;

  h6 {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: min(2.9vw, 15px);
    line-height: 20px;
    margin: 0;
    //margin-bottom: 7px;
    padding: 3.5px 0;
  }
  svg {
    min-width: 15px;
    max-width: 15px;
    height: 15px;
    margin-right: 5px;
  }

  :hover {
    .module-delete-button {
      right: 0;
    }
  }
`;
