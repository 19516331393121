import { useEffect, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useCourses } from "./useCourses";
import { Curso } from "../../../constants/types";
import { useAuthUser } from "../../../Services/useAuthUser";
import { Modules } from "../types";

export const useCoursePage = () => {
  const { authUser, isAdmin } = useAuthUser();

  const { courseUrl } = useParams<{
    courseUrl: string;
  }>();

    const location = useLocation();
 
  const history = useHistory();


  const { getCourseByUrl, getModulesLessonsById, getUserProgress } =
    useCourses();

  // Full course Data after a Fetch.
  const [isLoading, setLoading] = useState(true);
  const [courseData, setCourseData] = useState<{ id: string; data: Curso }>();
  const [courseModules, setCourseModules] = useState<Modules>();

  const [userCourseProgress, setCourseProgressData] = useState<any>();
  const [percentageCompleted, setPercentageComplete] = useState(0);
  const [urlsModulesLessons, setUrlsModulesLessons] = useState<string[]>([""]);

  // Load Course information (an also user progress as i see)
  useEffect(() => {
    async function getCourseData() {

      const courseDataResponse = await getCourseByUrl(courseUrl);
      setCourseData(courseDataResponse);

      const courseModulesResponde = await getModulesLessonsById(
        courseDataResponse.id,
        isAdmin
      );
      setCourseModules(courseModulesResponde);

      const userProgress: { id: string; data: any }[] = await getUserProgress(
        authUser.email,
        courseDataResponse.id
      );

      let numberLessonsCourse = 0,
        numberLessonsUser = 0;

      // User course progress
      userProgress.forEach(({ id }) => {
        courseModulesResponde.forEach((module) => {
          module.lessonsData.forEach((lesson: any) => {
            if (lesson.id === id) numberLessonsUser++;
          });
        });
      });
      courseModulesResponde.forEach((module) => {
        numberLessonsCourse += module.lessonsData.length;
      });

      let urls: string[] = [];
      courseModulesResponde.forEach((modules) => {
        const moduleID = modules.id;
        urls.push(moduleID);

        modules.lessonsData.forEach((lesson: any) => {
          urls.push(moduleID + "/" + lesson.id);
        });
      });

      setUrlsModulesLessons(urls);
      setPercentageComplete(
        Math.round((numberLessonsUser * 100) / numberLessonsCourse)
      );
      setCourseProgressData(userProgress);
      setLoading(false);
    }

    // Carga el Curso al iniciar la pagina
    getCourseData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const urlModuleId = location.pathname.split("/")[3];

  // Check if no module is selected and set ups the first one
  useEffect(() => {
    // TODO: esto esta perdiendo nuestro location por el params get
    if (
      !urlModuleId 
    ) {
      if (courseModules) {
        if (
          (courseModules.length === 0 || courseModules.length === undefined) &&
          !isAdmin
        ) {
          history.replace("/not-found");
        } else if (
          courseModules.length > 0 &&
          courseModules[0].lessonsData.length > 0 &&
          courseData
        ) {
          const moduloID = courseModules[0].id;
          if (
            history.location.pathname !==
            `/curso/${courseData.data.cursoUrl}/${moduloID}`
          ) {
            history.replace(`/curso/${courseData.data.cursoUrl}/${moduloID}`);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseData, courseModules]);

  // se llama refreh abajo
  async function doModuleLessonRefresh() {
    if (!courseData) return;

    const courseModulesRespondeData = await getModulesLessonsById(
      courseData.id,
      isAdmin
    );
    const courseModulesResponde = courseModulesRespondeData;

    const userProgressData = await getUserProgress(
      authUser.email,
      courseData.id
    );
    const userProgress: { id: string; data: any }[] = userProgressData;
    let numberLessonsCourse = 0,
      numberLessonsUser = 0;

    userProgress.forEach(({ id }) => {
      courseModulesResponde.forEach((module) => {
        module.lessonsData.forEach((lesson: any) => {
          if (lesson.id === id) numberLessonsUser++;
        });
      });
    });

    courseModulesResponde.forEach((module) => {
      numberLessonsCourse += module.lessonsData.length;
    });

    let urls: string[] = [];
    courseModulesResponde.forEach((modules) => {
      const moduleID = modules.id;
      urls.push(moduleID);

      modules.lessonsData.forEach((lesson: any) => {
        urls.push(moduleID + "?lesson=" + lesson.id);
      });
    });

    setPercentageComplete(
      Math.round((numberLessonsUser * 100) / numberLessonsCourse)
    );
    setCourseProgressData(userProgress);
    setUrlsModulesLessons(urls);
    setCourseModules(courseModulesResponde);
  }


  //Al precionar completar leccion lo renderiza a la siguiente leccion o inicio de modulo (si es que hay)
  function goNextLessonModule() {

    // obtener id del modulo y el id de la leccion del url, unirlos y buscar el index en el array de urls
    const ids = location.pathname.split("/");
    const search = ids[3] + "/" + ids[4];
   
    const currentIndex = urlsModulesLessons.indexOf(search);

    console.log("go next module", currentIndex, urlsModulesLessons)

    if (currentIndex !== -1 && currentIndex + 1 < urlsModulesLessons.length) {
      const str = urlsModulesLessons[currentIndex + 1];

      history.push(`/curso/${courseUrl}/${str}`);
    }
  }

  return {
    // State
    isLoading,
    courseData,
    courseModules,
    userCourseProgress,
    percentageCompleted,
    // Functions
    doModuleLessonRefresh,
    goNextLessonModule,
  };
};
