import styled from "styled-components";

export const Container = styled.div`
  --gradient-color: linear-gradient(to bottom, #ff007a 0%, #a74976 100%);
  margin-top: 14vh;
  display: flex;
  font-family: Montserrat;
  justify-content: center;
  height: 1227px;
  text-align: center;

  @media screen and (max-width: 414px) {
    margin: 14vh 10px;
  }
`;

export const Imagen = styled.img`
  width: 535px;
  height: 496px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const Column = styled.div`
  border: 1px solid #b6b6b6;
  width: 691px;
  height: 496px;
`;

export const Titulo = styled.h1`
  font-size: 20px;
  font-weight: 800;
  color: transparent;
  background: var(--gradient-color);
  margin-top: 28px;
  margin-bottom: 18px;
  -webkit-background-clip: text;
`;

export const Card = styled.div`
  font-size: 14px;

  p {
    margin-top: 21px;
    margin-bottom: 23px;
  }

  a {
    color: transparent;
    background: var(--gradient-color);
    -webkit-background-clip: text;
  }

  a:hover {
    color: #ff007a;
  }

  hr {
    width: 380px;
    margin-bottom: 18px;
  }

  label {
    color: transparent;
    background: var(--gradient-color);
    -webkit-background-clip: text;
    font-weight: 700;
    margin-right: 200px;
    margin-bottom: 8px;
  }

  form {
    width: 207px;
    margin: 0 auto;

    span {
      font-size: 14px;
      font-weight: 400;
    }

    .label__correo {
      margin-left: 50px;
    }
  }

  form:last-child {
    width: 396px;
  }

  input {
    width: 296.52px;
    background-color: #f7f7f7;
    height: 35.4px;
    margin: 0 auto;
    border: none;
    border-bottom: 2px solid #dfdbdb;

    &::placeholder {
      color: #495057;
      opacity: 60%;
      font-weight: 300;
      font-size: 14px;
    }
  }

  .google-login-button {
    height: 35.4px !important;

    span {
      line-height: 100px;
    }
  }
`;

export const Links = styled.div`
  display: flex;
  gap: 20px;
  margin-left: 20px;

  @media screen and (max-width: 414px) {
    padding-left: 17px;
  }

  button {
    background: var(--gradient-color);
    border: 0;
    border-radius: 3px;
    color: white;
    font-size: 16px;
    font-family: Montserrat;
    padding: 10px;
    width: 163px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  p {
    font-size: 12px;
    color: transparent;
    background: var(--gradient-color);
    -webkit-background-clip: text;
    font-weight: 300;
    opacity: 0.9;
  }

  a {
    font-weight: 300;
    opacity: 0.9;
  }
`;

export const ErrorMsg = styled.p`
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
  color: #eb0071;
`;
