import React, { FC, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { LessonsType } from "../../types";
import { useCourses } from "../../hooks/useCourses";

import TrashCanSvg from "./trash-alt-solid.svg";



const DeleteLessonButton = styled.span`
  right: -22%;
  transition: 0.5s;

  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  padding: 0 5px;
  align-items: center;
  justify-content: center;

  & img {
    width: 15px;
    opacity: 0.6;
  }

  & i {
    opacity: 0.7;
  }

  & i,
  img {
    cursor: pointer;
  }
  & img {
    margin-left: 6px;
  }
`;

type LessonListElementProps = {
  idCourse: string;
  courseURL: string;
  idModule: string;
  idLesson: string;
  nombreLeccion: string;
  isAdmin: Boolean;
  update: () => void;
  lessonData: LessonsType;
  lessonsFailing: string[];
  setFailing: (arg0: any) => void;
  userCourseProgress: { id: string; data: any }[];
};

const LessonListElement: FC<LessonListElementProps> = ({
  idCourse,
  courseURL,
  idModule,
  idLesson,
  nombreLeccion,
  isAdmin,
  update,
  lessonData,
  lessonsFailing,
  setFailing,
  userCourseProgress,
}) => {
  const [isModifyLesson, setModifyLesson] = useState(false);
  const { deleteLessonById, updateValuesOfLesson } = useCourses();
  const inputReference = useRef<any>(null);
  const isFailing = !lessonData.descripcion || !lessonData.video;
  const [isSelected, setSelected] = useState<boolean>(false);

  //Aqui tampoco podemos usar useParams por que estamos fuera del switch chido
  const location = useLocation();
  const urlModuleId = location.pathname.split("/")[3] || "";
  const urlLessonId = location.pathname.split("/")[4] || "";

  function toggleLessonList(e: any) {
    setSelected(true);
  }

  useEffect(() => {
    if (!lessonsFailing.includes(nombreLeccion) && isFailing) {
      setFailing(lessonsFailing.concat([nombreLeccion]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFailing]);

  useEffect(() => {
    if (idLesson === urlLessonId && idModule !== urlModuleId) {
      if (!isSelected) setSelected(!isSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function deleteLesson() {
    if (lessonData.pdfs === undefined && lessonData.video === undefined) {
      await deleteLessonById(idCourse, idModule, idLesson).catch(() => {
        update();
      });
      update();
    } else if (
      window.confirm("Esta lección posee contenido, ¿desea eliminarlo?")
    ) {
      await deleteLessonById(idCourse, idModule, idLesson);
      update();
    }
  }

  function enableModuleModification(event: any) {
    if (event.target !== inputReference.current) {
      setModifyLesson(!isModifyLesson);
    }
  }

  function onLoseFocus() {
    setModifyLesson(false);
  }

  async function doCreateModule(event: any) {
    if (event.key === "Enter") {
      await updateValuesOfLesson(idCourse, idModule, idLesson, {
        nombreLeccion: event.target.value,
      });
      setModifyLesson(false);
      update();
    }
  }

  function tellMissingLessonValues() {
    let missingValues: string = "";
    if (!lessonData.descripcion) missingValues += "\n-Descripción de lección";
    if (!lessonData.video) missingValues += "\n-Video";
    /*if(!lessonData.pdfs)
            missingValues += '\n-PDF'*/

    return `\n\nLe falta:${missingValues}`;
  }

  function isLessonCompleted() {
    return userCourseProgress.find((lesson) => lesson.id === idLesson);
  }

  return (
    <Container isModify={isModifyLesson} lessonData={lessonData}>
      <div>
        <div>
          {isModifyLesson ? (
            <InputContainer>
              <input
                autoFocus
                ref={inputReference}
                onBlur={onLoseFocus}
                onKeyPress={doCreateModule}
              />
            </InputContainer>
          ) : (
            <li key={idLesson}>
              <Link
                to={`/curso/${courseURL}/${idModule}/${idLesson}`}
                onClick={toggleLessonList}
                replace={true}
                title={
                  isFailing
                    ? "Esta lección no la podran ver los usuarios." +
                      tellMissingLessonValues()
                    : ""
                }
              >
                {nombreLeccion}
              </Link>
              {isLessonCompleted() && !isAdmin ? (
                <i className="fas fa-check-circle"></i>
              ) : null}
            </li>
          )}
          {isAdmin ? (
            <DeleteLessonButton className="lessons-delete-button">
              <i
                className="fas fa-pen-square"
                onClick={enableModuleModification}
              ></i>
              <img
                src={TrashCanSvg}
                alt=""
                className="fas fa-trash-alt"
                onClick={deleteLesson}
              />
            </DeleteLessonButton>
          ) : null}
        </div>
        {idLesson === urlLessonId ? (
          isAdmin ? (
            <LessonDetailsForm
              onCreation={update}
              idCourse={idCourse}
              idModule={idModule}
              idLesson={idLesson}
              lessonDescription={lessonData.descripcion}
              isAdmin={isAdmin}
            />
          ) : (
            <DescriptionContainer
              isAdmin={isAdmin}
              hasDescription={lessonData.descripcion}
            >
              <p>{lessonData.descripcion ? lessonData.descripcion : null}</p>
            </DescriptionContainer>
          )
        ) : null}
      </div>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  overflow: hidden;
  height: auto;

  & li {
    position: relative;
    & i {
      position: absolute;
      right: 2%;
      top: 50%;
      transform: translateY(-50%);
      color: #00a70d;
    }
  }

  &:hover {
    & .lessons-delete-button {
      background: #0000000d;
      ${({ isModify }) => (isModify ? null : "right: 0;")}
    }
  }

  & a {
    color: ${({ lessonData }) =>
      lessonData.descripcion && lessonData.video ? "black;" : "red;"};

    &:hover {
      color: #d50000;
    }
  }
`;

const DescriptionContainer = styled.div`
  margin: 8px 0 5px;

  & p {
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-weight: 200;
    font-size: 12px;
    word-wrap: break-word;
    ${({ isAdmin }) => (isAdmin ? "cursor: pointer;" : null)}
    text-shadow: 0px 0px 0px ${({ hasDescription }) =>
      hasDescription ? "black" : "#00adff"};
    color: ${({ hasDescription }) => (hasDescription ? "black;" : "#00adff;")}
      ${({ hasDescription }) => (hasDescription ? null : "cursor: pointer;")};
  }

  & textarea {
    font-family: "Montserrat", sans-serif;
    font-weight: 200;
    text-shadow: 0px 0px 0px black;
    font-size: 12px;
    width: 100%;
    height: 120px;

    &:focus {
      border: none;
      outline: none !important;
      border: 1px solid gray;
      resize: none;
    }
  }
`;
type LessonDetailsProps = {
  onCreation: () => void;
  idCourse: string;
  idModule: string;
  idLesson: string;
  lessonDescription: string;
  isAdmin: Boolean;
};

const LessonDetailsForm: FC<LessonDetailsProps> = ({
  onCreation,
  idCourse,
  idModule,
  idLesson,
  lessonDescription,
  isAdmin,
}) => {
  const [isEditEnable, setEditEnable] = useState(false);
  const [text, setText] = useState(lessonDescription);
  const inputReference = useRef<any>(null);
  const { updateValuesOfLesson } = useCourses();
  const textValue = text;

  function toggleLessonDescriptionEdit(event: any) {
    if (event.target !== inputReference.current) {
      setEditEnable(true);
    }
  }

  function onLoseFocus() {
    setEditEnable(false);
  }

  async function doCreatLesson(event: any) {
    if (event.key === "Enter") {
      await updateValuesOfLesson(idCourse, idModule, idLesson, {
        descripcion: text,
      });
      setEditEnable(false);
      onCreation();
    }
  }

  function handleChange(event: any) {
    setText(event.target.value.replace("\n", ""));
  }

  useEffect(() => {
    if (isEditEnable && text)
      inputReference.current.setSelectionRange(text.length, text.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditEnable]);

  return (
    <DescriptionContainer
      hasDescription={lessonDescription}
      onClick={toggleLessonDescriptionEdit}
      isAdmin={isAdmin}
    >
      {isEditEnable ? (
        <textarea
          autoFocus
          ref={inputReference}
          onBlur={onLoseFocus}
          onKeyPress={doCreatLesson}
          value={textValue}
          onChange={handleChange}
        />
      ) : (
        <p>
          {lessonDescription?.length > 0
            ? lessonDescription
            : "Agregar una descripción a la lección"}
        </p>
      )}
    </DescriptionContainer>
  );
};

const LessonCreatorElement = styled.li`
  cursor: pointer;
  color: #00adff;
`;

const ContainerCreator = styled.div`
  margin-bottom: 10px;

  & #course-module-creator {
    padding: 5px 8px;
    cursor: pointer;
    display: block;
    text-align: left;
    line-height: 17px;
    font-family: "Montserrat", sans-serif;
    font-size: 17px;
    font-weight: 500;
    color: white;
    background-color: #618af17d;
    border-radius: 5px;
    transition: 0.25s;

    &:hover {
      background-color: #909689;
    }
  }

  &:last-of-type {
    margin-bottom: 0px;
  }
`;
const InputContainer = styled.div`
  background-color: #ebebeb;
  padding: 1px 8px 1px;

  & input {
    margin-top: 2px;
    height: 100%;
    outline-width: 0;
    border: none;
    width: 100%;
    background: none;

    font-family: "Montserrat", sans-serif;
    font-size: 17px;
    color: black;
  }

  & input:focus {
    height: 100%;
    outline-width: 0;
    border: none;
  }
`;

type CreatorProps = {
  idCourse: string;
  idModule: string;
  onCreation: () => void;
};

export const LessonCreator: FC<CreatorProps> = ({
  idCourse,
  idModule,
  onCreation,
}) => {
  const [isCreatingLesson, setCreatingLesson] = useState(false);
  const { createLessonForModule } = useCourses();
  const inputReference = useRef<any>(null);

  function enableLessonCreation(event: any) {
    if (event.target !== inputReference.current) {
      setCreatingLesson(!isCreatingLesson);
    }
  }

  function onLoseFocus() {
    setCreatingLesson(false);
  }

  async function doCreatLesson(event: any) {
    if (event.key === "Enter") {
      await createLessonForModule(idCourse, idModule, event.target.value);
      setCreatingLesson(false);
      onCreation();
    }
  }

  return (
    <ContainerCreator onClick={enableLessonCreation}>
      {!isCreatingLesson ? (
        <LessonCreatorElement>Crear Lección</LessonCreatorElement>
      ) : (
        <InputContainer>
          <input
            autoFocus
            ref={inputReference}
            onBlur={onLoseFocus}
            onKeyPress={doCreatLesson}
          />
        </InputContainer>
      )}
    </ContainerCreator>
  );
};

export default LessonListElement;
