import React from "react";
import { ElBotton } from "../../components/common/ElBotton";
import styled from "styled-components";
import { useWhatsappMessage } from "../../Services/useWhatsappMessage";
import { useAuthUser } from "../../Services/useAuthUser";

export const SuccessPage = () => {

    // La informacion del url sobre el curso. pero tambien wachar el redirect del server
    const params = new URLSearchParams(window.location.search);

    const cursoUrl = params.get('cursoUrl') || "";

    function IrAlCurso() {
        window.open("/curso/" + cursoUrl, "_self");
    }

    const sendWhatsapp = useWhatsappMessage("")

    const { authUser } = useAuthUser()

  return (
    <SuccessPageStyles>
      <h1>¡ Hola {authUser.username} !</h1>
      <h2>Tu pago ha sido exitoso</h2>

      <div className="primero">
        <div>
          <p>¡Bienvenido/a a tu curso de Metafísica China con Arlene Ruiz!</p>
          <p>Estoy encantada de tenerte a bordo y emocionada de acompañarte en este
          viaje de descubrimiento y crecimiento personal.</p>
          <p>
          Aquí, en nuestra plataforma de aprendizaje en línea, encontrarás todo
          lo que necesitas para aprovechar al máximo este curso. Hemos diseñado
          cada módulo y recurso con cuidado para garantizar que obtengas una
          experiencia de aprendizaje enriquecedora y efectiva.
          </p>
          <br />
          <ElBotton onClick={IrAlCurso}>IR AL CURSO</ElBotton>
        </div>

        <img
          src="https://firebasestorage.googleapis.com/v0/b/web-arlene.appspot.com/o/assets%2FMask%20group.png?alt=media&token=33d70f57-9e51-4d92-bd05-b4533b222d42"
          alt=""
        />
      </div>

      <div className="segundo">
        <p>Lo que puedes esperar:</p>

        <ol>
          <li>
            <b>Contenido de Calidad</b>: Cada lección está respaldada por el
            profundo conocimiento y experiencia de Arlene Ruiz en sus mas de 10
            años en la enseñanza de la metafísica china. El contenido que se
            imparte es valioso y práctico.
          </li>
          <li>
            <b>Comunidad de Aprendizaje</b>: Te podras unir a una comunidad de
            apasionados por la metafísica china que comparten tu interés.
            Aprovecha esta red para intercambiar ideas, hacer preguntas y crecer
            juntos. Esta comunidad sera posible a través de distintos canales
            como whatsapp o el foro.
          </li>
          <li>
            <b>Recursos Descargables</b>: En la pagina del curso podras
            descargar los manuales, las presentaciones, ver los videos de las
            clases grabadas y el material adicional que te ayudarán a aplicar lo
            que aprendas en tu vida diaria.
          </li>
          <li>
            <b>Soporte Continuo</b>: Yo, junto a mi equipo de trabajo estamos
            dispuestos a que consigas tus metas con eficacia.
          </li>
          <li>
            <b>Certificado de Finalización</b>: Al completar el curso, recibirás
            un certificado que respalda tus nuevos conocimientos y logros en
            metafísica china.
          </li>
        </ol>

        <p>
          Recuerda que el aprendizaje es un viaje continuo. Estás dando un gran
          paso hacia la prosperidad personal, espiritual. Aprovecha al máximo
          esta oportunidad y explora cada rincón de nuestra plataforma.
        </p>
      </div>

      <div className="tercero">
        <p>
          Si tienes alguna pregunta o necesitas asistencia, puedes ponerte en
          contacto al whatsapp:
        </p>

        <ElBotton onClick={sendWhatsapp}>IR AL WHATSAPP</ElBotton>

        <br />
        <ul>
          <li>
            al whatsapp: +52 1 477 238 1607 en el horario laboral de lunes a
            viernes de 9am - 4pm (hora mexico centro).
          </li>
        </ul>

        <p>O a través de los siguientes canales:</p>

        <ul>
          <li>al correo: hola@arleneruizfengshui.com</li>
          <li>
            en los distintas redes sociales: @arleneruizfengshui (instagram,
            facebook, youtube)
          </li>
        </ul>

        <p>
          Gracias por confiar en mi. ¡Estamos listos para embarcarnos en este
          viaje juntos!
        </p>

        <p>Con amor, Arlene Ruiz</p>
      </div>
    </SuccessPageStyles>
  );
};

const SuccessPageStyles = styled.div`
  margin: 0 auto;
  max-width: 966px;

  h1,
  h2 {
    text-align: center;
    font-family: "Montserrat", sans-serif;
  }

  h1 {
    margin-top: 40px;
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  h2 {
    margin-top: 15px;
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  p,
  li,
  b {
    font-family: "Montserrat", sans-serif;
  }

  p,
  li {
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  li {
    margin-bottom: 15px;
  }

  .primero {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 52px;

    p {
      max-width: 512px;
      color: #000;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }

    img {
      width: 254px;
      height: 254px;
    }
  }

  .segundo {
    margin-top: 40px;
  }
`;
