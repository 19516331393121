import React, { FC, useState } from "react";

import {
  Wrapper,
  PreguntaInput,
  InputFile,
  Respuestas,
} from "./QuestionsFormStyle";

import { useFirebaseContext } from "../../../../../Services/Firebase";
import { PreguntaExamen } from "../../../../../constants/types";
import { useExamenContext } from "../../../ExamenContext";

type QuestionsFormProps = {
  edicion?: boolean;
  pregunta?: PreguntaExamen;
  index?: number;
  cerrarEdicion?: () => void;
};

export const QuestionsForm: FC<QuestionsFormProps> = ({
  edicion,
  pregunta,
  index,
  cerrarEdicion,
}) => {
  // Valida los parametros necesarios para modo edicion
  if (edicion && (!pregunta || typeof index !== "number")) {
    console.log(pregunta, index);
    throw Error("es necesario los dos parametros para modo edicion");
  }

  // function to save the question
  const firebase = useFirebaseContext();
  const { examenData, idCourse, getExamen } = useExamenContext();

  const InitialState =
    edicion && pregunta
      ? pregunta
      : {
          pregunta: "",
          imagen: "",
          respuestas: [],
        };

  const [nuevaPregunta, setNuevaPregunta] =
    useState<PreguntaExamen>(InitialState);

  const [nuevaRespuesta, setNuevaRespuesta] = useState("");

  // TODO: usar la nueva funcion del contexto
  // async function guardarExamen() {
  //   if (!examenData) return;
  //   try {
  //     const examen = firebase.cursoExamen(idCourse).doc(examenData?.id);
  //     if (edicion) {
  //       // pinche typescript ya te la sabes
  //       if(typeof index !== 'number') return;
  //       // substituye pregunta
  //       // Crea un nuevo array por que el mierda de splice muta el original
  //       let temp = [...examenData.questions];
  //       // substituye el elemento que no queremos
  //       temp.splice(index, 1, nuevaPregunta);
  //       // guarda el nuevo array
  //       await examen.update({ ...examenData, questions: temp })
  //       if (cerrarEdicion) {
  //         cerrarEdicion()
  //       }
  //     } else {
  //       // guardar nuva pregunta
  //       await examen.update({
  //         ...examenData,
  //         questions: [...examenData.questions, nuevaPregunta],
  //       });
  //     }
  //     console.log("se guardo el examen con exito");
  //   } catch (error: any) {
  //     console.error("Fallo guardar el examen", error.message);
  //   }
  // }

  // function nuevaPreguntaAgregarRespuesta() {
  //   // Tomar referencias de los inputs
  //   const correctaElement = document.getElementById(
  //     "nueva-respuesta-correcta"
  //   ) as HTMLInputElement;

  //   // actualizar el stado de nueva pregunta
  //   setNuevaPregunta({
  //     ...nuevaPregunta,
  //     respuestas: [
  //       ...nuevaPregunta.respuestas,
  //       { texto: nuevaRespuesta, correcta: correctaElement.checked },
  //     ],
  //   });

  //   // restablecer los valores de los inputs
  //   setNuevaRespuesta("");
  //   correctaElement.checked = false;
  // }

  async function uploadFile(storagePath: string, File: any) {
    try {
      const storeRef = firebase.storage.ref(storagePath);

      await storeRef.put(File);
      const url = await storeRef.getDownloadURL();

      return url;
    } catch (error: any) {
      throw Error("No se pudo subir la imagen " + error.message);
    }
  }

  const disableGuardar =
    nuevaPregunta.pregunta === "" || nuevaPregunta.respuestas.length < 2;

  const [mostrarFormulario, setMostrarFormulario] = useState(
    !!edicion || false
  );

  return (
    <Wrapper>
      {!mostrarFormulario && (
        <button onClick={() => setMostrarFormulario(true)}>
          Agregar pregunta
        </button>
      )}

      {mostrarFormulario && (
        <>
          <div style={{ display: "flex", gap: "8px" }}>
            <h3>{edicion ? "Editando pregunta" : "Agregar nueva pregunta"}</h3>
            <button
              onClick={() => {
                if (cerrarEdicion) {
                  cerrarEdicion();
                } else {
                  setMostrarFormulario(false);
                }
              }}
            >
              cerrar
            </button>
          </div>
          <PreguntaInput
            type="text"
            placeholder="Titulo Pregunta"
            name="pregunta"
            value={nuevaPregunta.pregunta}
            onChange={(e: any) => {
              setNuevaPregunta({
                ...nuevaPregunta,
                pregunta: e.target.value,
              });
            }}
            id="nueva-pregunta"
          />

          <InputFile>
            <input
              type="file"
              accept=".jpg, .png"
              title="Subir Imagen de Fondo"
              onChange={(e: any) => {
                e.stopPropagation();
                const file = e.target.files[0];
                // if no file was selected exit
                if (!file) return;
                const path = `/cursos/${idCourse}/examen/${file.name}`;

                uploadFile(path, file).then((resolve) => {
                  setNuevaPregunta({ ...nuevaPregunta, imagen: resolve });
                });
              }}
            />
            {nuevaPregunta.imagen === "" ? (
              <div className="module-background-label">
                <strong>Esta pregunta aun no contiene una imagen</strong>
                <p>Presione esta sección para agregar una</p>
              </div>
            ) : (
              <img src={nuevaPregunta.imagen} alt="" />
            )}
          </InputFile>

          {/* Respuestas */}
          <div>
            <p>Agregar respuesta</p>
            <PreguntaInput
              type="text"
              placeholder="Nueva respuesta"
              value={nuevaRespuesta}
              onChange={(e: any) => setNuevaRespuesta(e.target.value)}
              id="nueva-respuesta"
            />
            <br></br>
            <label style={{ marginRight: "12px" }}>Es Correcta</label>
            <input type="checkbox" id="nueva-respuesta-correcta" />
            <br></br>
            <button
              disabled={nuevaRespuesta === ""}
              onClick={() => {
                // Tomar referencias de los inputs
                const correctaElement = document.getElementById(
                  "nueva-respuesta-correcta"
                ) as HTMLInputElement;

                // actualizar el stado de nueva pregunta
                setNuevaPregunta({
                  ...nuevaPregunta,
                  respuestas: [
                    ...nuevaPregunta.respuestas,
                    {
                      texto: nuevaRespuesta,
                      correcta: correctaElement.checked,
                    },
                  ],
                });

                // restablecer los valores de los inputs
                setNuevaRespuesta("");
                correctaElement.checked = false;
              }}
            >
              Agregar respuesta
            </button>

            <p style={{ marginTop: "32px" }}>Repuestas</p>
            <Respuestas>
              {/* respuestas ya guardadas */}
              {nuevaPregunta.respuestas.map((respuesta, index) => (
                <p key={index} className={respuesta.correcta ? "correcta" : ""}>
                  {respuesta.texto} {/* Borrar respueta */}
                  <button
                    onClick={() => {
                      // Crea un nuevo array por que el mierda de splice muta el original
                      let temp = [...nuevaPregunta.respuestas];
                      // remueve el elemento que no queremos
                      temp.splice(index, 1);
                      // guarda el nuevo array
                      setNuevaPregunta({ ...nuevaPregunta, respuestas: temp });
                    }}
                  >
                    X
                  </button>
                </p>
              ))}
            </Respuestas>
          </div>

          <button
            disabled={disableGuardar}
            onClick={async () => {
              if (!examenData) return;

              await guardarExamen(
                examenData,
                idCourse,
                firebase,
                edicion,
                index,
                nuevaPregunta,
                cerrarEdicion
              );

              // reset the state for nueva pregunta
              setNuevaPregunta({
                pregunta: "",
                imagen: "",
                respuestas: [],
              });

              await getExamen();
            }}
          >
            Guardar Pregunta
          </button>
        </>
      )}
    </Wrapper>
  );
};

// TODO: usar la nueva funcion del contexto
async function guardarExamen(
  examenData: any,
  idCourse: string,
  firebase: any,
  edicion?: boolean,
  index?: number,
  nuevaPregunta?: any,
  cerrarEdicion?: () => void
) {
  if (!examenData) return;
  try {
    const examen = firebase.cursoExamen(idCourse).doc(examenData?.id);
    if (edicion) {
      // pinche typescript ya te la sabes
      if (typeof index !== "number") return;
      // substituye pregunta
      // Crea un nuevo array por que el mierda de splice muta el original
      let temp = [...examenData.questions];
      // substituye el elemento que no queremos
      temp.splice(index, 1, nuevaPregunta);
      // guarda el nuevo array
      await examen.update({ ...examenData, questions: temp });
      if (cerrarEdicion) {
        cerrarEdicion();
      }
    } else {
      // guardar nuva pregunta
      await examen.update({
        ...examenData,
        questions: [...examenData.questions, nuevaPregunta],
      });
    }
    console.log("se guardo el examen con exito");
  } catch (error: any) {
    console.error("Fallo guardar el examen", error.message);
  }
}
