import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import VideoForm from "./VideoForm";
import VideoPlayer from "./VideoPlayer";
import { useAuthUser } from "../../../../Services/useAuthUser";

const Container = styled.div`
  position: relative;
  height: ${({ height }) => height + "px"};
  z-index: 20;
  width: 100%;

  .FormLabelCreator {
    border: 2px dashed #525252;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;

    & div {
      font-size: 20px;
      text-align: center;
    }
  }
`;
const VideoFormWrapper = styled.div`
  top: 0;
  z-index: 1000;
  position: absolute;
  ${({ enable }) => (enable ? "display: flex;" : "display: none;")}
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const UpdateVideoButton = styled.button`
  text-align: right;
  background: none;
`;

type VideoMaterialProps = {
  idCourse: string;
  idModule: string;
  idLesson: string;
  submit: () => void;
  video: [string, string] | undefined;
  setLessonComplete: (value: boolean) => void;
};

const VideoMaterial: FC<VideoMaterialProps> = ({
  idCourse,
  idModule,
  idLesson,
  submit,
  video,
  setLessonComplete,
}) => {
  const pageHeight = 960;
  const { isAdmin } = useAuthUser();
  const [formEnable, setFormEnable] = useState(false);
  const firebaseFormProps = {
    idCourse,
    idModule,
    idLesson,
  };

  function toggleForm() {
    console.log("toggleForm", formEnable);
    setFormEnable(!formEnable);
  }

  //Cuando se haya seleccionado otra lección Hacer:
  useEffect(() => {
    setFormEnable(false);
    setLessonComplete(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idLesson]);

  return (
    <Container height={pageHeight / 2}>
      {isAdmin && (
        <>
          {video === undefined && (
            <div className="FormLabelCreator" onClick={toggleForm}>
              <div>
                <strong>Esta lección aun no tiene video</strong>
                <p>Presione esta sección para agregar uno</p>
              </div>
            </div>
          )}

          {video !== undefined && (
            <UpdateVideoButton onClick={toggleForm}>
              Editar video
              <i className="fas fa-pen-square"></i>
            </UpdateVideoButton>
          )}

          <VideoFormWrapper enable={formEnable}>
            <VideoForm
              hasVideo={video !== undefined}
              height={pageHeight}
              formEnable={formEnable}
              toggleForm={toggleForm}
              {...firebaseFormProps}
              submit={submit}
            />
          </VideoFormWrapper>
        </>
      )}

      {video && !formEnable && (
        <VideoPlayer setLessonComplete={setLessonComplete} video={video} />
      )}
    </Container>
  );
};

export default VideoMaterial;
