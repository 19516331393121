import styled from "styled-components";

const BackgroundContainer = styled.div`
  overflow-y: none;
  width: 100%;
  position: relative;
  background: #f8f8f8;
  display: flex;
  z-index: 1;
  min-height: calc(100vh - 50px);
`;

const Background = styled.div`
  background-image: url(${(props) => props.bgImage});
  background-size: 141px;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 0.025;
  z-index: -1;
`;

// TODO: migrar a TS.

//Background fondo
const PageBackground = ({ children, backgroundImage, id, nombrePagina }) => {
  if (nombrePagina !== undefined) document.title = nombrePagina;

  return (
    <BackgroundContainer id={id}>
      {children}
      <Background bgImage={backgroundImage} />
    </BackgroundContainer>
  );
};

export default PageBackground;
