import React, { useState, FC }from "react";
import * as ROUTES from "../../../constants/routes";
import { useHistory, Link } from "react-router-dom";

import { ElBotton } from "../../../components/common/ElBotton";

type dataProps = {
  userName: string;
  userEmail: string;
  privacyPolicies: boolean;
};

export const InscripcionesComponent: FC<{curso: string}> = ({ curso }) => {
  const history = useHistory();
  const initialValues = {
    userName: "",
    userEmail: "",
    privacyPolicies: false,
  };
  const [data, setData] = useState<dataProps>(initialValues);
  const [error, setError] = useState<string>("");

  function handleChange(e: any) {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setData({
      ...data,
      [name]: value,
    });
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    setError("");

    const fetchData = {
      email: data.userEmail,
      nombre: data.userName,
      nombreCurso: curso,
    };

    // TODO: Migrate to firebase
    const response = await fetch(
      process.env.REACT_APP_BACKEND_SERVER_IP + "/interesados-diplomado",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        referrerPolicy: "no-referrer",
        body: JSON.stringify(fetchData),
      }
    );

    if (response.ok) {
      history.push("/gracias-suscripcion");
    } else if (response.status === 406) {
      setError("El usuario ya existe en la lista de interesados");
      history.push("/gracias-suscripcion");
    }
  }

  function validEmail(e: string) {
    // eslint-disable-next-line no-useless-escape
    var filter =
      /^\s*[\w\-+_]+(\.[\w\-+_]+)*@[\w\-+_]+\.[\w\-+_]+(\.[\w\-+_]+)*\s*$/;
    return String(e).search(filter) !== -1;
  }

  const isValid =
    data.userName !== "" && validEmail(data.userEmail) && data.privacyPolicies;

  return (
    <form onSubmit={handleSubmit}>
      <div className="inscripciones-form-input">
        <label htmlFor="userName">Nombre</label>
        <input
          type="text"
          name="userName"
          id="userName-input"
          onChange={handleChange}
          required={true}
        />
      </div>

      <div className="inscripciones-form-input">
        <label htmlFor="userEmail">Correo</label>
        <input
          type="text"
          name="userEmail"
          id="userEmail-input"
          onChange={handleChange}
          required={true}
        />
      </div>

      <div>
        <input
          type="checkbox"
          name="privacyPolicies"
          id="privacyPolicies-checkbox"
          onChange={handleChange}
          required={true}
        />
        He leído y acepto la{" "}
        <Link to={ROUTES.AVISO_PRIVACIDAD}>politica de privacidad</Link>
      </div>

      <p className="form-error">{error}</p>

      <ElBotton disabled={!isValid}>apuntame a la lista de espera</ElBotton>
    </form>
  );
};