import styled, { css } from 'styled-components'

export const CardContainer = styled.div`
    display: flex;
    width: 232px;
    height: 285px;
    border: 1px solid #A5A5A5;
    ${({isBought}) => isBought ? 'border: 2px solid #00dc0d;' : null }
    ${({isFailing}) => isFailing ? 'background: #ffdfdf;' : 'background: #FAFAFA;' }
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    margin-bottom: 26px;
    flex-direction: column;
    overflow: hidden;
`

export const CardHead = styled.div`
    height: 145px;
    background: linear-gradient(90deg, #D14FC4 0%, #D14F4F 100%);
`

export const CardImageContainer = styled.div`
    height: 100%;
    position: relative;
    overflow: hidden;

    & > * {
        height: inherit;
    }

    & .brightHeaderCard, .shadowHeaderCard {
        width: 100%;
        top: 0;
        position: absolute;
    }

    & .shadowHeaderCard{
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.048) 16.15%, rgba(0, 0, 0, 0)
                         50.52%, rgba(0, 0, 0, 0.1) 85.94%, rgba(0, 0, 0, 0.4) 100%);
        //opacity: 0.8;
    }

    & .brightHeaderCard{
        height: 10px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.325) 0%, rgba(255, 255, 255, 0) 100%);
        border-radius: 9px 9px 0px 0px;
    }
`

export const backgroundImgStyle = css`
    background-origin: border-box;
    background-position: top;
    background-size: cover; 
`

export const CardImageCSS = css`
    & span:first-child {
        font-family: 'Titillium Web', sans-serif;
        font-size: 10px;
        color: #fbfbfbe5;
        text-shadow: -1px -1px 1.5px rgba(0, 0, 0, 0.2), 1px 1px 1.5px rgba(0, 0, 0, 0.35)
    }
`

export const CardImage = styled.div`
    display: flex;
    padding: 4px 9px 3px 6px;
    flex-direction: column;
    align-content: space-between;
    justify-content: space-between;
    background: linear-gradient(90deg, #D14FC4 0%, #D14F4F 100%);
    border-radius: 9px 9px 0 0;
    box-sizing: border-box;
    border: 1.5px solid rgba(0, 0, 0, 0.3);

    ${ 
        //Si el prop background no es undefined
        ({backgroundImage}) => backgroundImage? ('background: url('+backgroundImage+');'+ backgroundImgStyle) : ''
    }
    
    span {
        z-index: 1;
    }

    span:first-child{
        margin-top: 2px;
        background: rgb(92 209 0);
        width: fit-content;
        box-shadow: 0px 0px 4px #ffffff66;
        ${ ({ isBought }: { isBought: boolean }) => !isBought ? 'padding: 2px 3px; border: 1px solid #189200;' : null }
        border-top-color: #5afa3b;
        border-bottom-color: #1e7b04;
        border-radius: 5px;
        
        
        a { color: white !important; }
        font-size: 8px;

        i {
            margin-right: 5px;
            //color: #00d100;
        }
    }

    ${ (props) => props.stars === undefined || props.stars === 0 ? CardImageCSS : null}
    span:last-child {
        font-family: 'Montserrat';
        font-size: 10px;
        text-align: right;
        color: #fbfbfbe5;
        text-shadow: -1px -1px 1.5px rgba(0, 0, 0, 0.2), 1px 1px 1.5px rgba(0, 0, 0, 0.35)
    }
`

export const CardInfoContainer = styled.div`
    height: 35px;
    flex-grow: 1;
    border-bottom: 1px solid #E0E0E0;
    position: relative;
    z-index: 1;

    div{
        height: 100%;
        width: 100%; 
    }

    .shadow-card{
        top: 0;
        z-index: -1;
        position: absolute;
        background: linear-gradient(180deg, rgba(250, 250, 250, 0) 90.62%, rgba(241, 241, 241, 0.8) 100%);
    }
`

export const CardInfo = styled.div`
    font-family: 'Montserrat';
    padding: 13px 15px 1px;
    display: flex;
    flex-direction: column;


    #opciones-admin {
        position: relative;
        height: min-content;

        >div {
            position: absolute;
            bottom: 64px;
            background-color: rgba(0, 0, 0, 0.5);
            height: fit-content
        }
    }

    a {
        width: fit-content;

        &:hover {
            color: #C11F6D;
        }
    }

    h3 {
        color: #C11F6D;
        font-size: 14.5px;
        font-weight: 600;
        margin-bottom: 5px;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
        word-wrap: break-word;
    }

    p {
        margin: 0;
        color: #414141;
        text-align: justify;
        text-justify: inter-word;
        padding: 0 5px;
        height: 80%;
        word-wrap: break-word;
        overflow-y: auto;
        font-family: 'Buda';
        font-weight: bold;
        font-size: 12px;
    }
    .card-info::-webkit-scrollbar {
        width: 4px;
    }
    .card-info::-webkit-scrollbar-track {
        background-color: transparent;
    }
    .card-info::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 8px;
    }
`

export const CardFooter = styled.div`
    height: 35px;
    border-top: 1px solid #FFFFFF;
    padding: 0 10px;
    ${({ isAdmin }) => isAdmin ? 'cursor: pointer' : null }
    position: relative;

    > div:first-of-type {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    span {
        margin-left: 10px;
        display: inherit;
    }

    .courseCard-live{
        color: #DD3A3A;
        font-family: 'Inria Sans', sans-serif;
        font-size: 13px;
        padding: 0px 2px 0px;
        border: 2px solid #DD3A3A;
        border-radius: 5px;
        line-height: 16px;
    }

    span i {
        font-size: 22px;
        color: rgba(178, 178, 178, 0.76);
    }

    .CardFooterPreview{
        height: 35px;
        padding: 0 10px;
        z-index: 2;
        position: relative;

        div {
            display: flex;
            align-items: center;
            height: 100%;
            align-items: center;
            justify-content: center;

            font-family: 'Montserrat';
            font-weight: 600;
            font-size: 13px;
            color: #0000006b;

            p { 
                margin: 0;
            }
        }
    }

    .CardFooter-percentage{
        background-color: #5bff65ad;
        z-index: 1;
        position: absolute;
        height: 100%;
        width: ${ ({ percentage }) => percentage ? percentage : 0 }%;
        left: 0;
        top: 0;
    }
`

export const CreateCourseButton = styled.div`
    display: flex;
    width: 232px;
    height: 285px;
    border: 1px solid #A5A5A5;
    background: #FAFAFA;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    margin-bottom: 26px;
    flex-direction: column;
    overflow: hidden;
    opacity: 0.35;
    cursor: pointer;
    transition: 0.2s;
    user-select: none;

    .circle {
        margin: 28px auto 0;
        text-align: center;

        i {
            font-size: 105px;
            background-color: #858585;
            color: transparent;
            text-shadow: 0px 4px 3px rgb(255 255 255 / 50%);
            -webkit-background-clip: text;
        }
    }

    .admin-card-label{
        padding: 0 10px;
    }

    p {
        margin: 0;
        padding: 35px 0;
        text-align: center;
        border-bottom: 1px solid #c3c3c3; 
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 28px;

        background-color: #565656;
        color: transparent;
        text-shadow: 0px 2px 1px rgb(255 255 255 / 50%);
        -webkit-background-clip: text;
    }

    :hover {
        background: #60c4ff;
        opacity: 1;

        i{
            color: #2e80d5;
            background-color: none; 
            color: none;
            text-shadow: none; 
            -webkit-background-clip: none;
        }

        .admin-card-label p{
            color: #2e80d5;
            text-shadow: none;  
            border-bottom: 1px solid #198ee1;
        }
    }

`

export const AdminCardContainer = styled.div`
    position: relative;
`
