import React, { FC } from 'react';
import Firebase from "./firebase"

const FirebaseContext = React.createContext<Firebase | null>(null);

export const withFirebase = (Component: FC) => (props: any) => (
	<FirebaseContext.Consumer>
		{firebase => <Component {...props} firebase={firebase} />}
	</FirebaseContext.Consumer>
);

export const FirebaseProvider: FC = ({ children }) => {
	const firebaseInstance = new Firebase()
	return (
		<FirebaseContext.Provider value={firebaseInstance}>
			{children}
	  	</FirebaseContext.Provider>
	)
}

export const useFirebaseContext = () => {
  const context = React.useContext(FirebaseContext);
	if (!context) {
		throw new Error('Upps');
	}
	return context;
}

export default FirebaseContext;