/* eslint-disable no-useless-escape */
import React, { useState, FC } from "react";
import styled, { css } from "styled-components";
import root from "react-shadow/styled-components";
import ModalCommon from "../../../components/common/ModalCommon";
import { ElBotton } from "../../../components/common/ElBotton";

type CoursePaymentConfigProps = {
  cursoData: any;
  courseId?: string;
  setCursoData: any; //this is a set state
  submit: (event:any) => Promise<void>;
};

export const CoursePaymentConfig: FC<CoursePaymentConfigProps> = ({
  cursoData,
  setCursoData,
  courseId,
  submit,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  // States for the inputs
  const [sectionActiva, setSectionActiva] = useState("resumen");
  const [status, setStatus] = useState("Sin cambios");

  async function handleSubmit() {
    const event = {
      preventDefault: () => {},
    }
    await submit(event);
    setStatus("Cambios guardados");
  }

  function handleChange(event: any) {
    setStatus("Cambios sin guardar");
    if (event.target.type === "checkbox") {
      setCursoData({
        ...cursoData,
        [event.target.name]: event.target.checked,
      });
    } else {
      setCursoData({
        ...cursoData,
        [event.target.name]: event.target.value,
      });
    }
  }

  return (
    <root.div>
      <ModalCommon
        estilos={{ top: "0px", left: "0px" }}
        small
        isOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
      >
        <Wrapper onClick={(event: any) => event.stopPropagation()}>
          <div id="opciones-venta">
            <ElBotton
              color={sectionActiva === "producto" ? "verde" : "azul"}
              onClick={() => setSectionActiva("producto")}
            >
              Información Producto
            </ElBotton>
            <ElBotton 
              color={sectionActiva === "subscripcion" ? "verde" : "azul"}
              onClick={() => setSectionActiva("subscripcion")}>
              Informacion Subscripción
            </ElBotton>
            <ElBotton
              color={sectionActiva === "cupones" ? "verde" : "azul"}
              onClick={() => setSectionActiva("cupones")}>
              Cupones
            </ElBotton>
            <ElBotton
              color={sectionActiva === "resumen" ? "verde" : "azul"}
              onClick={() => setSectionActiva("resumen")}>
              Activación
            </ElBotton>
          </div>

          <Section className={sectionActiva === "producto" ? "active" : ""}>
            <h2>Venta una sola expedición</h2>
            {/* Costo visual */}
            <label htmlFor="precio">Precio (visual):</label>
            <input
              name="precio"
              type="text"
              value={cursoData.precio}
              onChange={handleChange}
              className="createCourse-text"
              placeholder="Precio del curso"
              required={true}
            />

            <h3>Price_id</h3>
            <p>
              Entra a la página de productos de stripe{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://dashboard.stripe.com/products"
              >
                aquí (stripe).
              </a>
              Selecciona el producto o crea uno nuevo
            </p>

            <p>Dentro de la vista del producto selecciona el Price_id</p>
            <img
              style={{ height: "156px", width: "auto" }}
              src="https://firebasestorage.googleapis.com/v0/b/web-arlene.appspot.com/o/assets%2Ftutorial-info-venta-cursos%2FScreen%20Shot%202023-03-21%20at%2015.51.36.png?alt=media&token=4c6cf6c9-8b9c-4d26-b893-5394c740cc4f"
              alt=""
            />
            <label htmlFor="productId">Price_id: </label>
            <input
              name="productId"
              type="text"
              value={cursoData.productId}
              onChange={handleChange}
              className="createCourse-text"
              placeholder="Id del producto en stripe"
              required={true}
            />
          </Section>

          <Section className={sectionActiva === "subscripcion" ? "active" : ""}>
            <h2>Subscripcion</h2>
            <label htmlFor="precioMensual">Precio Mensual (visual):</label>
            <input
              name="precioMensual"
              type="text"
              value={cursoData.precioMensual}
              onChange={handleChange}
              className="createCourse-text"
              placeholder="Precio Mensual del curso"
              required={true}
            />
            <label htmlFor="precioMensual">Número de meses:</label>
            <input
              name="mensualidades"
              type="number"
              value={cursoData.mensualidades}
              onChange={handleChange}
              className="createCourse-text"
              placeholder="Numero de meses"
              required={true}
            />

            {/* Costo mensual visual */}
            <h3>Price_id</h3>
            <p>Crea el producto con la opción pago Recurrente</p>
            <a
              href="https://dashboard.stripe.com/products"
              target="_blank"
              rel="noreferrer"
            >
              Dashboard de productos Stripe
            </a>

            <img
              style={{ height: "156px", width: "auto" }}
              src="https://firebasestorage.googleapis.com/v0/b/web-arlene.appspot.com/o/assets%2Ftutorial-info-venta-cursos%2FScreen%20Shot%202023-03-21%20at%2015.51.36.png?alt=media&token=4c6cf6c9-8b9c-4d26-b893-5394c740cc4f"
              alt=""
            />
            <br />

            <label htmlFor="subscriptionProductId">Price_id: </label>
            <input
              name="subscriptionProductId"
              type="text"
              value={cursoData.subscriptionProductId}
              onChange={handleChange}
              className="createCourse-text"
              placeholder="Id del producto en stripe"
              required={true}
            />
          </Section>

          <Section className={sectionActiva === "cupones" ? "active" : ""}>
            <h2>Cupones</h2>
            <p>
              Para usar cupones, necesitas crear el cupón y un código de
              promoción{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://dashboard.stripe.com/coupons"
              >
                aquí
              </a>
            </p>
            <p>
              Puedes configurar cupones y códigos de promoción que solo
              funcionen para ciertos cursos
            </p>
            <p>
              Esta opción solo es para habilitar que se permita ingresar códigos
              de promoción al momento de adquirir este curso
            </p>

            <label className="switchBtn">
              <input
                type="checkbox"
                name="promocion"
                checked={cursoData.promocion}
                onChange={handleChange}
              />
              <div className="slide round">Promoción</div>
            </label>
          </Section>
          {/* Con cupon? */}

          {/* Se encuentra en venta? */}
          <Section className={sectionActiva === "resumen" ? "active" : ""}>
            <h2>¿Se encuentra en venta?</h2>
            <p>
              Cuando un curso no está a la venta los usuarios todavía pueden
              verlo y acceder a la vista de preview, pero el botón de compra no
              funcionará.
            </p>
            <label className="switchBtn">
              <input
                type="checkbox"
                name="enVenta"
                checked={cursoData.enVenta}
                onChange={handleChange}
              />
              <div className="slide round">En venta</div>
            </label>
          </Section>


          <div id="changes-status">Status: {status}</div>
          <ElBotton onClick={handleSubmit}>Guardar cambios</ElBotton>
          {!cursoData.productId &&
            !cursoData.subscriptionProductId && (
              <p className="warning">Agrega al menos una manera de pagar.</p>
            )}
        </Wrapper>
      </ModalCommon>
      <ElBotton onClick={() => setModalOpen(true)} disabled={!courseId}>
        {courseId
          ? "Administrar información de compra"
          : "Información de compra"}
      </ElBotton>
    </root.div>
  );
};

const switchStyle = css`
  .switchBtn {
    position: relative;
    display: inline-block;
    width: 110px;
    height: 34px;
  }
  .switchBtn input {
    display: none;
  }
  .slide {
    margin-top: 0 !important;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    padding: 8px;
    padding-left: 15px;
    color: #fff;
    border-radius: 8px;
    font-size: 14px;
  }
  .slide:before {
    position: absolute;
    border-radius: 50%;
    content: "";
    height: 26px;
    width: 26px;
    left: 78px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  input:checked + .slide {
    padding-left: 40px;
    background-color: #6dd95c;
    border-radius: 8px;
  }
  input:focus + .slide {
    box-shadow: 0 0 1px #01aeed;
  }
  input:checked + .slide:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    left: -20px;
  }
`;

const Wrapper = styled.div`
  width: 60vw;
  overflow: scroll;
  max-height: 80vh;
  background-color: white;
  border-radius: 8px;
  padding: 16px;

  #opciones-venta {
    display: flex;
    justify-content: space-between;
  }

  #changes-status {
    margin-top: 16px;
    border-top: 1px solid #ccc;
  }

  .warning {
    border: 2px solid red;
    padding: 12px;
  }

  ${() => switchStyle}
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  display: none;

  &.active {
    display: block;
  }

  & > p {
    margin: 0;
  }
`;
