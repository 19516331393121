import React from "react";
import ArlenePresentation from "../curso-preview/components/ArlenePresentation";
import {
  Container,
  Header,
  InscripcionesContainer,
} from "./styles";

import { PromoPlataforma } from "../curso-preview/components/PromoPlataforma";
import { BaziIndiceContainer } from "./components/BaziIndiceContainer";
import { DudasBazi } from "./components/DudasBazi";
import { InscripcionesComponent } from "./components/InscripcionesComponent";

const BaziCerrado = () => {
  return (
    <Container>
      <Header style={{ backgroundColor: "#D6B3B3" }}>
        <div>
          <h1>DIPLOMADO DE ASTROLOGÍA BAZI</h1>
          <p>
            Transforma tu vida con acciones claras
            <br />
            contundentes y precisas.
          </p>
        </div>
      </Header>

      <main>
        <InscripcionesContainer>
          <h3>INSCRIPCIONES CERRADAS</h3>
          <p>¿Quieres apuntarte a la lista de espera?</p>

          <InscripcionesComponent curso="interesados-bazi-2024" />
        </InscripcionesContainer>
        <ArlenePresentation />
        <PromoPlataforma />
      </main>

      <BaziIndiceContainer />

      <DudasBazi />

      <InscripcionesContainer>
        <InscripcionesComponent curso="interesados-bazi-2024"/>
      </InscripcionesContainer>
    </Container>
  );
};

export default BaziCerrado;
