import React, { useContext } from "react";
import styled from "styled-components";
import { CursoPreviewData } from "../../index";
import BottonCompra from "../BottonCompra";

export const AlgunasPreguntasBaziMasFengshui = () => {
  const cursoData = useContext(CursoPreviewData);
  return (
    <Container>
      <h3>
        Sé que el Diplomado Bazi más Feng Shui Clásico puede traer grandes cambios en mi vida, pero
        tengo algunas preguntas:
      </h3>

      {/* <details>
        <summary>¿Podré realizar el estudio de feng shui de mi casa?</summary>
        <p>
          De hecho será requisito para que obtengas tu diploma de acreditación
          con nosotros realizar el estudio de feng shui no solo de tu casa sino
          también de otro inmueble propio o de algún conocido
        </p>
      </details> */}
      {/* <details>
        <summary>¿Tendré asesorías sobre mis estudios de feng shui?</summary>
        <p>
          {" "}
          Tus proyectos y sus avances estarán disponibles en nuestra plataforma
          para que los demás estudiantes puedan recibir retroalimentación.
          Además contarás con asesorías en las clases.
        </p>
      </details> */}
      <details>
        <summary>
          Temo que sea mucha información y no pueda aprovechar el contenido del
          curso.
        </summary>
        <p>
          Así es, habrá mucha información, no solo desde la mente sino también
          desde las emociones. Descuida, he procurado conformar un grupo amoroso
          para que puedas maximizar el contenido del diplomado y veas resultados
          durante el desarrollo del mismo.
        </p>
      </details>
      <details>
        <summary>¿Qué métodos de pago hay?</summary>
        <p>
          Desde esta página web puedes realizar una compra con tarjeta de
          crédito o débito a través de la pasarela de pago segura Stripe.
        </p>
      </details>

      <details>
        <summary>Fechas y horarios del diplomado</summary>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/web-arlene.appspot.com/o/assets%2Fhorarios-diplomado-bazi-fenshui.jpeg?alt=media&token=779589fe-5b28-444d-a368-0a69c60b6b8c"
          alt="Fechas"
        />
      </details>

      <details style={{ marginBottom: "24px" }}>
        <summary>Precios</summary>
        <p>
          El diplomado tiene un costo de $6,300.00 MXN con pago único, o tres
          mensualidades de $2100 MXN.
        </p>
      </details>

      <BottonCompra cursoData={cursoData} cursoId={cursoData?.id || ""} isGratuito={cursoData?.isGratuito || false} />
    </Container>
  );
};

const Container = styled.div`
  margin-top: 96px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    font-family: "Montserrat";
    max-width: 494px;
    margin-bottom: 72px;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;

    color: #000000;
  }

  summary {
    background: #ededed;
    border-radius: 7px;
    width: 100%;
    min-height: 44px;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    display: flex;
    align-items: center;
    padding-left: 20px;
    margin-bottom: 12px;
  }
  details {
    width: 90%;
  }

  img {
    width: 100%;
    height: auto;
    padding-bottom: 12px;
  }

  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    padding-left: 20px;
  }
  button {
    margin: auto;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 550px) {
    h3 {
      font-family: "Montserrat";
      max-width: 70%;
      font-size: large;
      margin-bottom: 40px;
      text-align: center;
      font-weight: 900;
    }
    p {
      font-size: 14px;
      text-align: justify;
      padding: 0 20px 0 20px;
    }
  }
`;