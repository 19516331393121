import React, { createContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { useCoursePreview } from "./hook/useCoursePreview";

import AboutCurso from "./components/AboutCurso";
import AboutMetafisica from "./components/AboutMetafisica";
import ArlenePresentation from "./components/ArlenePresentation";
import CursoHeader from "./components/Header";
import { Indice } from "./components/Indice";
import { Curso } from "../../constants/types";
import Footer from "../../components/generalFooter/footer";
import CursoGoals from "./components/CursoGoals";
import { PromoPlataforma } from "./components/PromoPlataforma";
import { AlgunasPreguntasFengshui } from "./components/AlgunasPreguntas/DiplomadoFengshui";
import { VideoPreview } from "./components/Video";
import { Chat } from "./components/Chat";
import { AlgunasPreguntasBazi } from "./components/AlgunasPreguntas/DiplomadoBazi";
import { CursoPreviewStyles } from "./styles";
import { useAuthUser } from "../../Services/useAuthUser";
import { PopUp } from "./components/PopUp";
import { HelmetMetas } from "./components/HelmetMetas";
import { AlgunasPreguntasBaziMasFengshui } from "./components/AlgunasPreguntas/DiplomadoBaziMasFengshui";

export const CursoPreviewData = createContext<Curso | undefined>(undefined);
export const EditPreviewProps = createContext<{
  isAdmin: boolean;
  update: any;
}>({ isAdmin: false, update: () => {} });

const CursoPreview = () => {
  const [courseData, setCourseData] = useState<Curso>();
  const { getCoursePreviewByUrl } = useCoursePreview();
  const { courseUrl } = useParams<{ courseUrl: string }>();
  const { authUser, isAdmin } = useAuthUser();
  const history = useHistory();

  useEffect(() => {
    getCoursePreviewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function refresh() {
    getCoursePreviewData();
  }

  function getCoursePreviewData() {
    return new Promise(async (revolve, reject) => {
      try {
        const courseDataResponse: Curso = await getCoursePreviewByUrl(
          courseUrl
        );

        if (authUser && !isAdmin) {
          if (authUser.products.includes(courseDataResponse.id)) {
            history.replace("/curso/" + courseDataResponse.cursoUrl);
          }
        } else if (courseDataResponse.isClosed) {
          // Aqui mandamos a la pagina de diplomado cerrado
          history.replace("/curso/" + courseDataResponse.isClosed);
        }
        setCourseData({ ...courseDataResponse });
      } catch (error) {
        console.log(error);
        history.replace("/cursos");
      }
    });
  }

  return (
    <CursoPreviewData.Provider value={courseData}>

      <HelmetMetas titulo={courseData?.nombreCurso || ""} img={courseData?.background || ""} />

      <CursoPreviewStyles style={courseData ? { opacity: 1 } : { opacity: 0 }}>
        <EditPreviewProps.Provider value={{ isAdmin, update: refresh }}>
          <CursoHeader id={courseData?.id || ""} />

          {/* Indice dinamico */}
          {courseData && (
            <Indice
              nombreCurso={courseData.nombreCurso}
              isDiplomado={courseData.tipoCurso === "diplomado"}
              courseData={courseData}
            />
          )}

          {courseData?.previewBeneficios && (
            <CursoGoals
              courseData={courseData}
              cursoTipo={courseData?.tipoCurso || ""}
              cursoBeneficios={courseData.previewBeneficios}
            />
          )}
        </EditPreviewProps.Provider>

        <VideoPreview />

        <ArlenePresentation addMargin={isAdmin} />

        <PromoPlataforma />

        {courseData?.isDiplomadoBazi2023 && <AlgunasPreguntasBazi />}

        {courseData?.isDiplomadoFengshui2023 && <AlgunasPreguntasFengshui />}

        {courseData?.isDiplomadoBaziFengShui && <AlgunasPreguntasBaziMasFengshui />}

        {!courseData?.isDiplomadoBazi2023 &&
          !courseData?.isDiplomadoFengshui2023 && (
            <AboutCurso
              cursoNombre={courseData?.nombreCurso || ""}
              textoBoton={
                courseData?.previewBoton ||
                `Quiero aprender ${courseData?.nombreCurso}`
              }
            />
          )}

        <AboutMetafisica />

        <PopUp cursoId={courseData?.id || ""} />
      </CursoPreviewStyles>
      <Footer />

      {courseData?.isDiplomadoBazi2023 && (
        <Chat message="Hola, Estoy interesade en el diplomado de Astrologia Bazi." />
      )}
      {courseData?.isDiplomadoFengshui2023 && (
        <Chat message="Hola, Estoy interesade en el diplomado de Feng Shui." />
      )}
      {!courseData?.isDiplomadoBazi2023 &&
        !courseData?.isDiplomadoFengshui2023 && (
          <Chat
            message={`Hola, Estoy interesade en el curso de ${courseData?.nombreCurso}.`}
          />
        )}
    </CursoPreviewData.Provider>
  );
};

export default CursoPreview;
