import React, { FC, useContext, useRef, useState } from "react";
import styled from "styled-components";
import { useCoursePreview } from "../../hook/useCoursePreview";
import { CursoPreviewData, EditPreviewProps } from "../../index";
import { InputContainer, ModifyDeleteContainer } from "./styles";
import { ModuleLessonCreator } from "./ModuleLessonCreator";
import { LessonPreview } from "./LessonPreview";
import { useAuthUser } from "../../../../Services/useAuthUser";
import { ModuloData } from "../../../../constants/types";
import { ElBotton } from "../../../../components/common/ElBotton";
import { useUploadFile } from "../../hook/useUploadFile";

type ModulePreviewProps = {
  courseId: string;
  titulo: string;
  moduleId: string;
  index: number;
  moduloData: ModuloData;
  imagen: string | undefined;
};

export const ModulePreview: FC<ModulePreviewProps> = ({
  courseId,
  titulo,
  moduleId,
  moduloData,
}) => {
  const [isModifyModule, setModifyModule] = useState(false);
  const {
    addPreviewModuleLessonData,
    UpdatePreviewModule,
    deletePreviewModule,
  } = useCoursePreview();
  const cursoID = useContext(CursoPreviewData)?.id;
  const EditData = useContext(EditPreviewProps);
  const inputReference = useRef<any>(null);

  const { isAdmin } = useAuthUser();

  // se encarga de abrir y cerrar la opcion de modificar titulos
  function enableModuleModification(event: any) {
    event.stopPropagation();
    if (event.target !== inputReference.current) {
      setModifyModule(!isModifyModule);
    }
  }

  function onLoseFocus() {
    setModifyModule(false);
  }

  function renameLesson(event: any) {
    if (event.key === "Enter") {
      if (cursoID)
        addPreviewModuleLessonData(
          { moduloTitulo: event.target.value },
          cursoID,
          moduleId
        )
          .then((resolve) => {
            EditData.update();
            window.alert("Se guardó con éxito");
          })
          .catch((reject) => {
            window.alert(reject);
            EditData.update();
          });
      setModifyModule(false);
    }
  }

  function deleteModule(event: any) {
    event.stopPropagation();

    if (cursoID)
      deletePreviewModule(cursoID, moduleId)
        .then((resolve) => {
          EditData.update();
        })
        .catch((reject) => {
          window.alert(reject);
          EditData.update();
        });

    setModifyModule(false);
  }

  const imageUploadRef = useRef<HTMLInputElement>(null);
  const uploadFile = useUploadFile();
  function uploadImage() {
    if (imageUploadRef.current) {
      imageUploadRef.current.click();
    }
  }

  async function upload(e: any) {
    if (e.target.files[0] && moduloData.id) {
      try {
        const url = await uploadFile(e.target.files[0], moduloData.id);
        const resolve = await UpdatePreviewModule(courseId, moduloData.id, {
          ...moduloData,
          imgDecorativa: url,
        });
        EditData.update();
        window.alert(resolve);
      } catch (e: any) {
        console.error("Failed to upload PreviewImageAlt", e.message);
      }
    }
  }

  const [open, setOpen] = useState(false);

  return isModifyModule ? (
    <InputContainer style={{ marginBottom: "max(8px,2%)", height: "38px" }}>
      <input
        autoFocus
        ref={inputReference}
        onBlur={onLoseFocus}
        onKeyPress={renameLesson}
        defaultValue={moduloData.data.moduloTitulo}
      />
    </InputContainer>
  ) : (
    <li className="menu-element" key={moduleId}>
      <h6 onClick={() => setOpen(!open)}>
        {titulo}
        {EditData.isAdmin && (
          <ModifyDeleteContainer className="module-delete-button">
            <i
              className="fas fa-pen-square"
              onClick={enableModuleModification}
            ></i>
            <i className="fas fa-trash-alt" onClick={deleteModule}></i>
          </ModifyDeleteContainer>
        )}
      </h6>
      {/* <RenderLessons
        index={index + 1}
        moduleData={moduloData}
        rederingValue={submenuRendering}
        key={moduloData.id + 1}
      /> */}
      <ListContainer open={open}>
        <div className="list-container">
          {moduloData.data?.lecciones?.map((titulo, index) => (
            <LessonPreview
              index={index}
              titulo={titulo}
              key={index}
              lessonArray={moduloData.data.lecciones}
              moduleData={moduloData}
            />
          ))}
          {isAdmin ? (
            <ModuleLessonCreator
              moduleData={moduloData}
              key="lessonPreview-creator"
            />
          ) : null}
        </div>
        {(isAdmin || moduloData.data.imgDecorativa) && (
          <div id="add-image">
            {moduloData.data.imgDecorativa ? (
              <img
                src={moduloData.data.imgDecorativa}
                alt="Portada del curso"
              />
            ) : null}
            <input
              type="file"
              accept=".jpg, .png"
              ref={imageUploadRef}
              style={{ opacity: 0 }}
              onChange={upload}
            />
            {isAdmin && (
              <div className="margin-bottom">
                <ElBotton onClick={uploadImage}>Cambiar Imagen</ElBotton>
              </div>
            )}
          </div>
        )}
      </ListContainer>
    </li>
  );
};

const ListContainer = styled.div<{ open: boolean }>`
  padding: 0 3% !important;
  transition: 0.5s;
  max-height: ${({ open }) => (open ? 500 : 0)}px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 6px auto 24px;
    flex-grow: 4;
  }

  .list-container {
    display: flex;
    flex-direction: column;
    order: 2;
  }
  img {
    width: 200px;
  }
  #add-image {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    align-items: center;
    margin: auto;
    order: 3;
  }
  @media screen and (max-width: 768px) {
    #add-image {
      order: 1;
    }
    .list-container {
      order: 2;
    }
  }
  .image-uploader {
    width: 300px;
    position: relative;
    height: auto;
  }
  .margin-bottom {
    margin-bottom: 20px;
  }
`;
