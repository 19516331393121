import { useState } from "react";
import { useFirebaseContext } from "../../../Services/Firebase";

export const useCourseCreator = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [imgPercentage, setImgPercentage] = useState("");
  const firebase = useFirebaseContext();

  async function createCourse(data: any) {
    const cursosRef = firebase.cursos();
    const { cursoUrl } = data;

    //Comprobar si ya existe un curso con el mismo url que el que se quiere crear
    const isFound = !(await cursosRef.where("cursoUrl", "==", cursoUrl).get())
      .empty;
    if (isFound) throw new Error("Ya existe un curso con el mismo nombre");

    return cursosRef.add({ ...data });
  }

  function updateCourse(data: {}, courseId: string) {
    const cursosRef = firebase.cursos();
    return cursosRef.doc(courseId).update({ ...data });
  }

  function updateCourseById(idCourse: string, data: {}, isTablon: boolean) {
    return new Promise(async (resolve, reject) => {
      let cursoRef;

      if (isTablon) {
          cursoRef = firebase.tablon(idCourse);
      } else {
          cursoRef = firebase.curso(idCourse);
      }

      await cursoRef.update(data);

      resolve("Updated");
    });
  }

  function uploadBackground(file: any, courseId: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      let date = new Date().getSeconds();
      const { name } = file;

      const storageRef = await firebase.storage.ref(
        `cursos/${courseId}/${date}${name}`
      );
      const task = storageRef.put(file);

      const cursosRef = firebase.cursos();

      task.on(
        "state_changed",
        (snapshot: any) => {
          let percentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setImgPercentage(`${percentage}`);
        },
        (error: any) => {
          reject(error);
          console.error(error);
        },
        () => {
          const url = task.snapshot.ref.getDownloadURL();
          resolve(url);
          url.then((url) => {
            cursosRef.doc(courseId).update({
              background: url,
            });
          });
          setImgPercentage("");
        }
      );
    });
  }

  function updateBackground(
    file: any,
    courseId: string,
    isTablon: boolean
  ): Promise<string> {
    return new Promise(async (resolve, reject) => {
      let date = new Date().getSeconds();
      const { name } = file;
      let storageRef;

      if (isTablon)
        storageRef = firebase.storage.ref(
          `tablones/${courseId}/${date}${name}`
        );
      else
        storageRef = firebase.storage.ref(`cursos/${courseId}/${date}${name}`);
      const task = storageRef.put(file);

      task.then(async () => {
        const url = await task.snapshot.ref.getDownloadURL();

        if (isTablon)
          await firebase.tablon(courseId).update({
            background: url,
          });
        else
          await firebase.curso(courseId).update({
            background: url,
          });
        resolve(url);
      });
    });
  }

  function deleteCourse(courseId: string) {
    async function deleteAction() {
      const cursoRef = firebase.cursos().doc(courseId);
      await cursoRef.delete();
      console.log("Se elimino el curso: " + courseId);
    }
    deleteAction();
  }

  return {
    createCourse,
    uploadBackground,
    updateBackground,
    updateCourseById,
    updateCourse,
    deleteCourse,
  };
};
