import React, { useState } from "react";
import ModalCommon from "../../../components/common/ModalCommon";
import { useHistory } from "react-router-dom";
import root from "react-shadow/styled-components";
import * as ROUTES from "../../../constants/routes";
import styled from "styled-components";
import { postData } from "../../../Services/Api/stripe";
import { ElBotton } from "../../../components/common/ElBotton";
import { useAuthUser } from "../../../Services/useAuthUser";
import { useFirebaseContext } from "../../../Services/Firebase";

const stripePaymentLink =
  process.env.REACT_APP_BACKEND_SERVER_IP + "/create-checkout-session";
const stripeSubscriptionLink =
  process.env.REACT_APP_BACKEND_SERVER_IP +
  "/create-checkout-session-subscription";

type BottonCompraProps = {
  // TODO: Typo de curso??
  cursoData: any;
  cursoId: string;
  isGratuito: boolean;
};

const BottonCompra = ({ cursoData, cursoId, isGratuito }: BottonCompraProps) => {
  const { authUser } = useAuthUser();
  const history = useHistory();
  const firebase = useFirebaseContext();

  function createSubscriptionCheckoutSession() {
    const dataCompra = {
      priceId: cursoData?.subscriptionProductId,
      email: authUser.email,
      name: authUser?.username,
      customerId: authUser?.stripeCustomerId,
      idCurso: cursoId,
      cursoUrl: cursoData?.cursoUrl,
      tipoCurso: cursoData?.tipoCurso,
      connect: cursoData?.stripeConnect,
      promocion: cursoData?.promocion,
    };

    postData(stripeSubscriptionLink, dataCompra).then((data) => {
      if (data.redirectUrl) {
        window.open(data.redirectUrl, "_self");
      }
    });
  }

  function createCheckoutSession() {
    const dataCompra = {
      email: authUser.email,
      name: authUser?.username,
      customerId: authUser?.stripeCustomerId,
      idCurso: cursoId,
      tipoCurso: cursoData?.tipoCurso,
      priceId: cursoData?.productId,
      cursoUrl: cursoData?.cursoUrl,
      connect: cursoData?.stripeConnect,
      promocion: cursoData?.promocion,
      cursoPrecio: cursoData?.precio,
    };

    postData(stripePaymentLink, dataCompra).then((data) => {
      if (data?.redirectUrl) {
        window.open(data.redirectUrl, "_self");
      }
    });
  }

    const updateProducts = async (cursoId: string) => {
      const products = authUser.products
      products.push(cursoId)
      await firebase.user(authUser.email).update({
        products
      })
    }

  async function handleButtonClick() {
    if (!authUser) {
      history.replace(ROUTES.SIGN_IN);
      return;
    }

    // Gratuito
    if (isGratuito) {
      // update user products
      await updateProducts(cursoId)
      // Redirect to success page
      history.push(ROUTES.SUCCESS_PAGE + `?cursoUrl=${cursoData?.cursoUrl}`);
      return;
    }

    // if we have both options
    if (
      cursoData.precio &&
      cursoData.productId &&
      cursoData.precioMensual &&
      cursoData.subscriptionProductId
    ) {
      setModalOpen(true);
      return;
    }

    // if we only have one time payments
    if (cursoData.precio && cursoData.productId) {
      createCheckoutSession();
      return;
    }

    // if we have only subscription
    if (cursoData.precioMensua && cursoData.subscriptionProductId) {
      createSubscriptionCheckoutSession();
      return;
    }
  }

  const isDisabled = !cursoData?.enVenta && !isGratuito;

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <root.div>
      <ModalCommon
        small
        isOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
      >
        <Wrapper>
          <h1>¡Ya casi!</h1>

          <h1>Un solo pago de: ${cursoData?.precio || ""} pesos</h1>
          {/* <p className='description'>Manual práctico para transformar tus espacios en un imán de prosperidad</p> */}
          <ElBotton disabled={isDisabled} onClick={createCheckoutSession}>
            {cursoData?.previewBoton ||
              `Quiero aprender ${cursoData?.nombreCurso}`}
          </ElBotton>

          <div style={{ marginBottom: "32px" }}></div>

          <h1>Pagos mensuales de ${cursoData?.precioMensual || ""} pesos</h1>
          {/* <p className='description'>Te enviaremos un correo electronico con el link para descargar el libro</p> */}
          <ElBotton
            disabled={isDisabled}
            onClick={createSubscriptionCheckoutSession}
          >
            {cursoData?.previewBoton ||
              `Quiero aprender ${cursoData?.nombreCurso}`}
          </ElBotton>
        </Wrapper>
      </ModalCommon>
      <ElBotton
        style={{ margin: "0 auto", marginTop: "24px" }}
        disabled={isDisabled}
        onClick={handleButtonClick}
      >
        {cursoData?.previewBoton || `Quiero aprender ${cursoData?.nombreCurso}`}
      </ElBotton>
    </root.div>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 348px;
  background-color: white;
  border-radius: 8px;
  padding: 8px;
  height: 528px;

  & > h1 {
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    max-width: 300px;
  }

  & > .description {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    max-width: 238px;
    margin-bottom: 32px;
  }
`;

export default BottonCompra;
