import React from "react";
import styled from "styled-components";


const BurgerMenuWrapper = styled.div`
    position: fixed;
    width: 60px;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    z-index: 23;
    margin: 25px 20px;
   div {
    width: 80%;
    height: 16%;
    background-color: #8B9087;
    border-radius: 20px;
    z-index: 25;
  }
  @media screen and (min-width: 550px) {
      display: none;
  }
`

export const BurgerMenu = ({ setOpenNav }: {setOpenNav: (a: boolean) => void }) => {
  return (
    <BurgerMenuWrapper
      onClick={() => {
        setOpenNav(true);
      }}
    >
      <div
        onClick={() => {
          setOpenNav(true);
        }}
      />
      <div
        onClick={() => {
          setOpenNav(true);
        }}
      />
      <div
        onClick={() => {
          setOpenNav(true);
        }}
      />
    </BurgerMenuWrapper>
  );
};
