import React from "react";
import { IndiceContainer, IndiceComponent } from "../styles";

export const FengshuiIndiceContainer = () => {
  return (
    <IndiceContainer>
      <h3>Índice</h3>
      <p>Contenido del diplomado de Feng Shui clásico</p>

      <div>
        <IndiceComponent title="FUNDAMENTOS Y EXTERIORES">
          <li>
            <p>» El Lou Shu.</p>
          </li>
          <li>
            <p>» El He Tu.</p>
          </li>
          <li>
            <p>» Los trigramas.</p>
          </li>
          <li>
            <p>» Los 5 elementos y los 3 ciclos.</p>
          </li>
          <li>
            <p>» Exteriores.</p>
          </li>
          <li>
            <p>» Montañas.</p>
          </li>
          <li>
            <p>» Agua.</p>
          </li>
          <li>
            <p>» Caminos y carreteras.</p>
          </li>
          <li>
            <p>» Alrededores, edificios y usos de suelo.</p>
          </li>
          <li>
            <p>» Tipo de suelo.</p>
          </li>
          <li>
            <p>» Vegetación.</p>
          </li>
          <li>
            <p>» Formas del terreno y topografía.</p>
          </li>
        </IndiceComponent>
        <IndiceComponent title="MÉTODOS DE ANÁLISIS Y DISEÑO">
          <li>
            <p>» Circulación de energía.</p>
          </li>
          <li>
            <p>» Patrones de movimientos.</p>
          </li>
          <li>
            <p>» Las 3 llaves.</p>
          </li>
          <li>
            <p>» Puerta de entrada.</p>
          </li>
          <li>
            <p>» La cocina</p>
          </li>
          <li>
            <p>» La recámara.</p>
          </li>
          <li>
            <p>» Estudio y zonas de trabajo.</p>
          </li>
          <li>
            <p>» Departamentos y edificios comerciales.</p>
          </li>
          <li>
            <p>» Diseño y división de planos.</p>
          </li>
          <li>
            <p>» Casa habitación.</p>
          </li>
          <li>
            <p>» Departamentos y edificios comerciales.</p>
          </li>
          <li>
            <p>» Zonas perdidas, fuertes y debilitadas.</p>
          </li>
          <li>
            <p>» Curas y prioridades.</p>
          </li>
        </IndiceComponent>
        <IndiceComponent title="MÉTODO BA ZHAI">
          <li>
            <p>» Método Ba Zhai.</p>
          </li>
          <li>
            <p>» El Min Gua.</p>
          </li>
          <li>
            <p>» Grupo del Este y del Oeste.</p>
          </li>
          <li>
            <p>» Orientaciones y características.</p>
          </li>
          <li>
            <p>» El Na Jia.</p>
          </li>
          <li>
            <p>» El House Gua.</p>
          </li>
          <li>
            <p>» Las 8 mansiones.</p>
          </li>
          <li>
            <p>» Curas y diseño.</p>
          </li>
          <li>
            <p>» Técnicas avanzadas de curas y diseños.</p>
          </li>
          <li>
            <p>» Ejercicios de análisis y diseño.</p>
          </li>
        </IndiceComponent>
        <IndiceComponent title="XUAN KONG">
          <li>
            <p>» El tiempo y los periodos.</p>
          </li>
          <li>
            <p>» Carta Xuan Kong.</p>
          </li>
          <li>
            <p>» Las 9 estrellas y sus características.</p>
          </li>
          <li>
            <p>» Activación y neutralización de las estrellas.</p>
          </li>
          <li>
            <p>» Análisis y diseño por el método Xuan Kong.</p>
          </li>
          <li>
            <p>» La puerta de entrada.</p>
          </li>
          <li>
            <p>» La cocina.</p>
          </li>
          <li>
            <p>» La recámara.</p>
          </li>
          <li>
            <p>» Oficina y zonas de trabajo.</p>
          </li>
          <li>
            <p>» Aplicación del He Tu y Lou Shu.</p>
          </li>
          <li>
            <p>» Las 81 combinaciones.</p>
          </li>
          <li>
            <p>» Ejercicios de análisis y diseño.</p>
          </li>
          <li>
            <p>» Estrellas anuales.</p>
          </li>
          <li>
            <p>» Actualizaciones de estudio.</p>
          </li>
        </IndiceComponent>
        <IndiceComponent title="XUAN KONG II.">
          <li>
            <p>» Aplicación del He Tu y Lou Shu.</p>
          </li>
          <li>
            <p>» Las 81 combinaciones.</p>
          </li>
          <li>
            <p>» Ejercicios de análisis y diseño.</p>
          </li>
          <li>
            <p>» Estrellas anuales.</p>
          </li>
          <li>
            <p>» Actualizaciones de estudio.</p>
          </li>
        </IndiceComponent>
        <IndiceComponent title="ESCUELA SAN HE">
          <li>
            <p>» Las 24 montañas.</p>
          </li>
          <li>
            <p>» Ba Sha Huang Quan.</p>
          </li>
          <li>
            <p>» Ba Lun Huang Quan.</p>
          </li>
          <li>
            <p>» Tao Hua Shui.</p>
          </li>
          <li>
            <p>» Wu Wui Yun Cai</p>
          </li>
          <li>
            <p>» San He Lian Zhu Lu.</p>
          </li>
          <li>
            <p>» Aflicciones anuales.</p>
          </li>
        </IndiceComponent>
        <IndiceComponent title="COLOCACIÓN DE AGUA">
          <li>
            <p>» Pautas generales para la colocación del agua.</p>
          </li>
          <li>
            <p>» El He Tu y el Lou Shu, activación con agua.</p>
          </li>
          <li>
            <p>» El life Gua y cómo seleccionar la casa ideal</p>
          </li>
        </IndiceComponent>
        <IndiceComponent title="ANALISIS DE UNA CASA - HABITACIÓN">
          <li>
            <p>» Análisis de un edificio comercial.</p>
          </li>
          <li>
            <p>» Diseño de una casa habitación.</p>
          </li>
          <li>
            <p>» Diseño de un edificio comercial.</p>
          </li>
          <li>
            <p>» Realización de un estudio de Feng Shui a casa habitación.</p>
          </li>
          <li>
            <p>» Realización de un estudio de Feng Shui a un negocio.</p>
          </li>
        </IndiceComponent>
      </div>
    </IndiceContainer>
  );
};
