import React from "react";
import ArlenePresentation from "../curso-preview/components/ArlenePresentation";
import { Container, Header, InscripcionesContainer } from "./styles";

import { PromoPlataforma } from "../curso-preview/components/PromoPlataforma";
import { FengshuiIndiceContainer } from "./components/FengShuiIndiceContainer";
import { DudasFengshui } from "./components/DudasFengShui";
import { InscripcionesComponent } from "./components/InscripcionesComponent";

const FengShuiCerrado = () => {
  return (
    <Container>
      <Header>
        <div>
          <h1>DIPLOMADO DE FENG SHUI CLÁSICO</h1>
          <p>
            Aprende técnicas milenarias para transformar
            <br />
            tus espacios físicos y potencializar tu
            <br />
            prosperidad, relaciones y felicidad.
          </p>
        </div>
      </Header>

      <main>
        <InscripcionesContainer>
          <h3>INSCRIPCIONES CERRADAS</h3>
          <p>¿Quieres apuntarte a la lista de espera?</p>

          <InscripcionesComponent curso="interesados-feng-shui-2024" />
        </InscripcionesContainer>

        <ArlenePresentation />

        <PromoPlataforma />
      </main>

      <FengshuiIndiceContainer />

      <DudasFengshui />
    </Container>
  );
};

export default FengShuiCerrado;
