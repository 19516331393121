import styled from "styled-components";

export const LearnCourseButton = styled.button`
  background: #145882;
  border-radius: 8px;
  padding: min(7%, 12px);
  border: none;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: clamp(11px, 2.6vw, 13px);
  color: white;
  margin: auto;
  display: block;
  cursor: pointer;
  text-transform: uppercase;
  transition: 0.25s;

  :hover {
    transform: scale(1.05);
  }

  &:disabled {
    opacity: 80%;
  }

  &:disabled {
    transform: scale(1);
    opacity: 80%;
    cursor: auto;
  }

  a {
    color: white;

    :hover {
      color: white;
    }
  }

  @media screen and (max-width: 550px) {
    margin: 5% 1em 0px auto;
  }
`;

export const MainHeaderContent = styled.div`
  margin-top: 24px;
  height: auto;
  position: relative;

  display: flex;
  gap: 12px;

  #portada-curso {
    width: 50%;
    height: 100%;
  }
  
  @media screen and (min-width: 721px) {
    #otra-mitad {
      width: 50%;
    }
  }

  h3,
  p {
    ${({ isAdmin }) => (isAdmin ? "cursor: pointer;" : "")}
  }

  h3 {
    font-weight: 700;
    margin-bottom: 25px;
    font-size: clamp(18px, 4vw, 24px);
    text-align: center;
    font-family: "Montserrat";
  }

  p {
    font-weight: 400;
    font-size: clamp(12px, 2.85vw, 14px);
    letter-spacing: -0.04em;
    text-align: justify;
    text-justify: inter-word;
    white-space: pre-wrap;
    margin: 0;
    font-family: "Montserrat";
  }

  @media screen and (max-width: 720px) {
    flex-direction: column;
    #portada-curso {
      width: 80%;
    }
    div {
      width: 100%;
    }

    img {
      margin: auto;
    }
  }

  #admin-portada-curso {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const CursoHeaderStyles = styled.div`
  @media screen and (max-width: 768px) {
    padding: 0 2%;
  }

`;

export const InputContainerStyle = styled.div`
  border-radius: 5px;
  padding: 1px 8px 1px;
  position: relative;
  min-height: 50px;

  input,
  textarea {
    position: absolute;
    margin-top: 2px;
    height: 100%;
    outline-width: 0;
    border: none;
    width: 100%;
    background: white;
    font-family: "Montserrat", sans-serif;
    font-size: 17px;
    color: black;
    padding: 2%;
    resize: none;

    ::placeholder {
      height: 100%;
      text-align: center;
    }
  }

  input:focus,
  textarea:focus {
    height: 100%;
    outline-width: 0;
    border: none;
  }

  ${({ customStyle }) => customStyle}
`;
