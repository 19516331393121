export const filterCourses = (cardsList: any[], isAdmin: boolean, search: string, categoryFilter: string) => {
    let filteredCards = cardsList;

    if (!isAdmin) {
        filteredCards = cardsList.filter((value) => {
            return !value.invisible || value.isBought;
        });
    }

    if (categoryFilter !== "") {
        filteredCards = filteredCards.filter(
            (value) => value.categoria === categoryFilter
        );
    }

    if (search.length > 0) {
        filteredCards = filteredCards.filter(
            (value) =>
                value.nombreCurso
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLocaleLowerCase()
                    .includes(search.toLocaleLowerCase()) &&
                (categoryFilter === "" ? true : value.categoria === categoryFilter)
        );
    }

    if (categoryFilter === "gratuito") {
        filteredCards = cardsList.filter((value) => {
          return value.isGratuito;
        });
      }

    return filteredCards;
}

export const filtroPromos = (cards: any[]) => {
    return cards.filter((value) => value.promocionado);
};