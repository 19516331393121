import React, { useState, useEffect } from "react";
import PageBackground from "./PageBackground";
import { Curso } from "../../constants/types";
import { Link } from "react-router-dom";

import CourseListFilter from "./components/CourseListFilter";
import CourseList from "./components/CourseList/index";
import background from "./background.webp";
import CourseCreator from "./components/CourseCreator";
import useCursosCards from "./hooks/useCursosCards";
import { useCourses } from "../curso/hooks/useCourses";
import * as ROUTES from "../../constants/routes";

import {
  CoursesContainer,
  CoursesMainContainer,
  CoursesHeaderMessage,
} from "./styles";
import { useAuthUser } from "../../Services/useAuthUser";

export const INITIAL_COURSE_STATE = {
  precio: 0,
  precioMensual: 0,
  autor: "",
  background: "",
  categoria: "",
  cursoUrl: "",
  descripcion: "",
  nombreCurso: "",
  modo: "",
  userCreador: "",
  subtitulo: "",
  tipoCurso: "",
  enVenta: false,
};

const getCardCompletePercentage = (
  userProgress: { id: string; data: any }[],
  courseModulesResponde: any[]
) => {
  try {
    let numberLessonsCourse = 0,
      numberLessonsUser = 0;

    userProgress.forEach(({ id }) => {
      courseModulesResponde.forEach((module) => {
        module.lessonsData.forEach((lesson: any) => {
          if (lesson.id === id) numberLessonsUser++;
        });
      });
    });
    courseModulesResponde.forEach((module) => {
      numberLessonsCourse += module.lessonsData.length;
    });

    if (numberLessonsUser === 0) return 0;
    if (numberLessonsCourse === 0)
      throw new Error("El curso no contiene lecciones, division de 0");
    else return Math.round((numberLessonsUser * 100) / numberLessonsCourse);
  } catch (error) {
    console.error("Error:", error);
    return -1;
  }
};

export const InicioTiendaPage = () => {
  const [category, setCategory] = useState("");
  const [searchCourse, setSearchCourse] = useState("");
  const [isCourseFormEnable, setIsCourseFormEnabled] = useState(false);
  const [courseToEdit, setCourseToEdit] = useState<string>("");
  const { getCourseDataById, getTablonById, getCourseCards } = useCursosCards();
  const [createData, setCreateData] = useState({
    ...INITIAL_COURSE_STATE,
    modo: "construcción",
  });

  const { isAdmin, authUser } = useAuthUser();

  const filterCategoryClick = (event: any) => {
    let categoryChoosed = "";

    // escritorio
    if (event.target.localName === "li") {
      // para Escritorio agarramos el innerText del li
      categoryChoosed = event.target.innerText.toLowerCase();
      // mobil
    } else {
      // Para la version movil usamos el target value del option
      categoryChoosed = event.target.value;
    }

    if (categoryChoosed === category) {
      setCategory("");
    } else {
      setCategory(categoryChoosed);
    }
  };

  const handleOnChangeSearch = (event: any) => {
    setSearchCourse(event.target.value);
  };

  const enableCourseCreator = () => {
    if (!isCourseFormEnable) {
      setCreateData({ ...INITIAL_COURSE_STATE });
      setIsCourseFormEnabled(true);
    }
  };

  function enableCourseEdition(idCourse: string, isTablon?: boolean) {
    if (isTablon) {
      getTablonById(idCourse).then((resolve) => {
        setCourseToEdit(resolve.id);
        setIsCourseFormEnabled(true);
      });
    } else {
      getCourseDataById(idCourse).then((resolve) => {
        setCreateData(resolve.data);
        setCourseToEdit(resolve.id);
        setIsCourseFormEnabled(true);
      });
    }
  }

  const closeCourseCreator = () => {
    setIsCourseFormEnabled(false);
  };

  // Card list stuff

  const [cardsList, setCardsList] = useState<Curso[]>([]);

  const [isLoading, setLoadingState] = useState(true);
  const { getUserProgress, getModulesLessonsById } = useCourses();

  async function getCourses() {
    const respuesta = await getCourseCards();

    let userProductos: string[];

    if (authUser) {
      userProductos = authUser.products;
    }

    let cursePromisesResponses: Promise<Curso>[] = [];
    respuesta.forEach((value) => {
      cursePromisesResponses.push(
        new Promise(async (resolve, reject) => {
          try {
            const values = {
              ...value.data,
              id: value.id,
            };

            if (userProductos && userProductos.includes(value.id)) {
              //Si el curso se encuentra en los productos del cliente (Si ya lo compro)
              //Pasarle la data del progreso del curso comprado
              const courseModulesResponde = await getModulesLessonsById(
                value.id,
                isAdmin
              );
              const userProgress: { id: string; data: any }[] =
                await getUserProgress(authUser.email, values.id);
              const percentage = isAdmin
                ? -1
                : getCardCompletePercentage(
                    userProgress,
                    courseModulesResponde
                  );

              if (isAdmin) {
                resolve({ ...values, isBought: true });
              } else {
                resolve({
                  ...values,
                  isBought: true,
                  completePercentage: percentage,
                });
              }
            } else {
              resolve({ ...values, isBought: false });
            }
          } catch (error) {
            console.error("Failed during course preparation", error);
          }
        })
      );
    });

    Promise.all(cursePromisesResponses)
      .then((promiseCurso) => {
        setCardsList(promiseCurso);
        setLoadingState(false);
      })
      .catch((error) => {
        console.error("Failed to fetch cursos", error);
      });
  }
  useEffect(() => {
    getCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageBackground
      backgroundImage={background}
      id="cursos-page"
      nombrePagina="Cursos"
    >
      <CoursesContainer>
        <CoursesMainContainer>
          <CoursesHeaderMessage>
            <div className="container__lista">
              <Link to={ROUTES.HOME}>
                <button id="lista-cursos">Lista de Cursos</button>
              </Link>
            </div>
            <h1>
              Cuando el conocimiento crece <br />
              la <span>oportunidad</span> aparece
            </h1>
            <p>
              Y por eso celebro que estés aquí
              <br />
              Juntos vamos a crear oportunidades en tu vida
            </p>
          </CoursesHeaderMessage>

          <CourseListFilter
            filterCategoryClick={filterCategoryClick}
            handleChange={handleOnChangeSearch}
            search={searchCourse}
            categorySelected={category}
          />

          <CourseList
            cardsList={cardsList}
            isLoading={isLoading}
            enableCourseEditor={enableCourseEdition}
            categoryFilter={category}
            search={searchCourse}
            toggleCourseCreator={enableCourseCreator}
          />
        </CoursesMainContainer>
      </CoursesContainer>

      {isAdmin && (
        <>
          <CourseCreator
            courseToEdit={courseToEdit}
            isEnable={isCourseFormEnable}
            categoriaFilter={category}
            cursoData={createData}
            setCursoData={setCreateData}
            refreshCourses={getCourses}
            setIsCourseFormEnabled={setIsCourseFormEnabled}
            closeCourseCreator={closeCourseCreator}
          />
        </>
      )}
    </PageBackground>
  );
};
