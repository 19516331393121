import React, { useEffect } from "react";
import { Switch, useHistory, Route } from "react-router-dom";

import PageBackground from "../InicioTienda/PageBackground";
import bgImage from "../InicioTienda/background.webp";
import { useAuthUser } from "../../Services/useAuthUser";
import { ModulePresentation } from "./components/PaginaModulo";
import UsersMaterials from "./components/paginaTareasUsuarios";

import {
  CourseMaterial,
  Container,
} from "./styles";
import { CourseNavigation } from "./components/Navegacion/CourseNavigation";
import { CursoContextProvider, useCursoContext } from "./CursoContext";
import { PaginaLeccion } from "./components/PaginaLeccion";
import { PaginaForo } from "./components/PaginaForo";
import { PaginaDebateForo } from "./components/PaginaDebateForo";


const CoursePage = () => {
  const history = useHistory();
  const { isAdmin } = useAuthUser();
  const { authUser } = useAuthUser();

  const {
    // State
    isLoading,
    courseData,
  } = useCursoContext();

  // Check if the user has the couse.
  useEffect(() => {
    if (authUser && courseData) {
      if (authUser.products.includes(courseData.id) || isAdmin) {
        // ok
      } else {
        history.replace("/curso/preview/" + courseData.data.cursoUrl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser, courseData]);

  if (isLoading) {
    return null;
  }

  return (
    <PageBackground
      //TODO: subir imagen al cdn
      backgroundImage={bgImage}
      id="course-page"
      nombrePagina={courseData?.data.nombreCurso}
    >
      <Container>
        <CourseNavigation />

        <CourseMaterial>
          <Switch>
            <Route exact path={`/curso/:courseUrl/materiales`}>
              <UsersMaterials />
            </Route>

            <Route exact path={`/curso/:courseUrl/foro`}>
              <PaginaForo />
            </Route>

            <Route exact path={`/curso/:courseUrl/foro/:idDebate`} >
              <PaginaDebateForo />
            </Route>

            <Route exact path={`/curso/:courseUrl/:idModule`}>
              <ModulePresentation />
            </Route>

            <Route path={`/curso/:courseUrl/:idModule/:idLesson`}>
              <PaginaLeccion />
            </Route>
          </Switch>
        </CourseMaterial>
      </Container>
    </PageBackground>
  );
};

export const CursoPageFull = () => {
  return (
    <CursoContextProvider>
      <CoursePage />
    </CursoContextProvider>
  );
};
