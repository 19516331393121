import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: blue;
`;

export const CourseMaterial = styled.div`
  background: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
  max-height: 100%;
  overflow-y: overlay;
`;
export const MaterialContainer = styled.div`
  width: 100%;
  height: auto;

  & .lesson-title {
    margin: 25px 0 40px 20px;
    padding: 6px;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 550px) {
      width: 90%;
    }

    & h1 {
      font-family: "Montserrat", sans-serif;
      font-size: 28px;
      font-weight: 700;
      margin: 0;
    }
  }
`;

export const NoContentContainer = styled.div`
  color: #a7a7a7;
  text-align: center;
  margin: auto;

  h1 {
    font-size: 80px;
  }

  p {
    font-size: 20px;
    font-weight: bold;
  }
`;
