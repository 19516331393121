import React, { useEffect, useState, FC } from "react";
import { SwitchButton } from "../../../../components/common/SwitchButton";
import { RespuestaPreguntaExamen } from "../../../../constants/types";
import { useFirebaseContext } from "../../../../Services/Firebase";
import { useExamenContext } from "../../ExamenContext";

import {
  Wrapper,
  Submition,
  InformacionExtra,
  AlumnosListContainer,
} from "../../components/UserSubmitionsStyles";
import { ElBotton } from "../../../../components/common/ElBotton";

export const UserSubmitions = () => {
  const { idCourse, getUsersSubmitions } = useExamenContext();

  const [submitions, setSubmitions] = useState<any>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function init() {
    const temp = await getUsersSubmitions(idCourse);
    setSubmitions(temp);
  }

  useEffect(() => {
    init();
  }, [getUsersSubmitions, idCourse, init]);

  return (
    <Wrapper>
      <h3>Alumnos - {submitions.length}</h3>

      <AlumnosListContainer>
        {submitions.map((doc: any, index: number) => (
          <Resultado init={init} doc={doc} key={index} index={index + 1} />
        ))}
      </AlumnosListContainer>
    </Wrapper>
  );
};

const Resultado: FC<{ doc: any; index: number; init: any }> = ({
  doc,
  index,
  init,
}) => {
  const firebase = useFirebaseContext();
  const { idCourse } = useExamenContext();
  const [expandido, setExpandido] = useState(false);

  const [mencionHonorifica, setMencionHonorifica] = useState(
    doc.mencionHonorifica || false
  );

  async function updateMencionHonorifica() {
    const snapshoot = firebase.cursoExamenRespuestas(idCourse).doc(doc.email);
    await snapshoot.update({ mencionHonorifica: !mencionHonorifica });
    setMencionHonorifica(!mencionHonorifica);
  }

  async function handleReiniciarExamen(event: any) {
    event.stopPropagation();
    const snapshoot = firebase.cursoExamenRespuestas(idCourse).doc(doc.email);
    await snapshoot.update({
      completado: false,
      calificacion: 0,
      respuestas: [],
    });

    await init();

    alert("Examen reiniciado con exito");
  }

  return (
    <Submition
      className="list-element"
      onClick={() => setExpandido(!expandido)}
    >
      <div className="resumen">
        <span>{index}</span>
        <p>{doc.username}</p>
        <p>{doc.email}</p>
        <p>examen: {doc.completado ? "completado" : "en progreso"}</p>
        <p>Calificacion: {doc.calificacion.toFixed(2)}</p>
        <span className="list-details-label">Ver Detalles</span>
      </div>
      {expandido && (
        <InformacionExtra>
          <div onClick={(e) => e.stopPropagation()}>
            <h6>Mención Honorífica</h6>
            <SwitchButton
              name="mension-honorifica"
              checked={mencionHonorifica}
              onChange={updateMencionHonorifica}
              label="Honor"
            />
          </div>
          <div>
            <h6>Comentario del curso</h6>
            <p>{doc.comentario || "No se ha comentado"}</p>
          </div>
          <div id="respuestas">
            {doc.respuestas.length > 0 && <h6>respuestas</h6>}
            {doc.respuestas.map(
              (respuesta: RespuestaPreguntaExamen, index: number) => (
                <p
                  key={index}
                  className={respuesta.correcta ? "correcta" : "incorrecta"}
                >
                  {index + 1} {respuesta.texto}
                </p>
              )
            )}
          </div>
          <ElBotton onClick={handleReiniciarExamen} color="verde">
            Retomar examen
          </ElBotton>
        </InformacionExtra>
      )}
    </Submition>
  );
};
