import React, { useContext } from "react";
import { useCoursePreview } from "../../hook/useCoursePreview";
import { CursoPreviewData } from "../../index";
import { useAuthUser } from "../../../../Services/useAuthUser";
import { ElBotton } from "../../../../components/common/ElBotton";

export const VideoPreview = () => {
  // la cosa para updatear el curso con nuevas propiedades
  const { addCoursePreviewData } = useCoursePreview();
  const cursoData = useContext(CursoPreviewData);
  const { isAdmin } = useAuthUser();

  function updateVideo() {
    addCoursePreviewData(cursoData!.id, {
      previewVideo: id,
    }).then((resolve) => {
      window.alert(resolve);
    });
  }

  const [id, setId] = React.useState<string>(cursoData?.previewVideo || "");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "660px",
        margin: "0 auto",
      }}
    >
      {cursoData?.previewVideo && (
        <iframe
          width="100%"
          height="415"
          style={{ margin: "0 auto" }}
          src={`https://www.youtube.com/embed/${cursoData.previewVideo}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      )}

      {isAdmin && (
        <div>
          <p>Video de Youtube embed</p>
          <input
            type="text"
            placeholder="youtube link"
            onChange={(e) => setId(e.target.value)}
            value={id}
          />
          <ElBotton onClick={updateVideo}>Guardar id</ElBotton>
        </div>
      )}
    </div>
  );
};
