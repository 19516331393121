import React from "react";
import { Box, Container, Row, Column, FooterLink, Heading, YoutubeLink, FacebookLink, InstagramLink } from "./footerStyles";
import * as ROUTES from "../../constants/routes";

const Footer = () => {
  return (
    <Box>
      <Container>
        <Row>
          {
            <Column>
              <Heading>Aplicaciones</Heading>
              <FooterLink href="/apps/minggua">Calculadora Min Gua</FooterLink>
              <FooterLink href="/apps/regla">Regla Puerta</FooterLink>
              <FooterLink href="/calendario">Calendario Tong Shu</FooterLink>
            </Column>
          }
          <Column>
            <Heading>Legales</Heading>
            <FooterLink href={ROUTES.LEGAL}>Legal</FooterLink>
            <FooterLink href={ROUTES.AVISO_PRIVACIDAD}>Aviso de Privacidad</FooterLink>
            <FooterLink href={ROUTES.POLITICA_COOKIES}>Cookies</FooterLink>
          </Column>

          <Column>
            <Heading>Síguenos</Heading>
            <FacebookLink href="https://www.facebook.com/arleneruizfengshui">
              <i className="fab fa-facebook-f">
                <span style={{ marginLeft: "10px" }}>Facebook</span>
              </i>
            </FacebookLink>
            <YoutubeLink href="https://www.youtube.com/channel/UCR0qBX5YlpPr6NzIF6lz6wg">
              <i className="fab fa-youtube"></i>
              <span style={{ marginLeft: "10px" }}>YouTube</span>
            </YoutubeLink>
            <InstagramLink href="https://www.instagram.com/arleneruizfengshui/">
              <i className="fab fa-instagram"></i>
              <span style={{ marginLeft: "10px" }}>Instagram</span>
            </InstagramLink>
          </Column>
        </Row>
      </Container>
    </Box>
  );
};
export default Footer;
