import React from "react";
import styled from "styled-components";
import { useAuthUser } from "../../../Services/useAuthUser";

type CoursesNotFoundComponentProps = {
    search: string;
    toggleCourseCreator: () => void;
  };

export default function CoursesNotFoundComponent (props: CoursesNotFoundComponentProps) {
    const { isAdmin } = useAuthUser();
    const {search, toggleCourseCreator} = props


    return (
    <EmptyList>
        <p>¯\_(ツ)_/¯</p>
        <p>No se ha encontrado ningún curso</p>
        {isAdmin && search.length === 0 ? (
            <p>
                ¡Admin! Parece que no hay curso en esta categoría,{" "}
                <span onClick={toggleCourseCreator}>¿creamos uno?</span>
            </p>
        ) : null}
    </EmptyList>
    );
};

export const EmptyList = styled.div`
  margin: auto;
  text-align: center;
  padding: 5vh 0;

  p {
    color: rgba(0, 0, 0, 0.6);

    span {
      user-select: none;
      text-decoration-line: underline;
      color: #c11f6d;

      &:hover {
        cursor: pointer;
        color: #1f94c1;
      }
    }
  }
  p:first-child {
    font-size: 80px;

    @media screen and (width <= 480px) {
    font-size: 60px;
    }

    @media screen and (width <= 368px) {
    font-size: 40px;
    }
  }
  p:nth-child(2n) {
    font-size: 20px;
    font-weight: bold;
  }
`;



