import React from 'react';
import styles from './Loading.module.css';

const LoadingScreen = ({pastDelay, retry, error}) => {
  if(!pastDelay)return null;
  return (
    <div className={styles.loading}>
      <div className={styles.loader}>
        <div className={styles.flor}></div>
        <h1>Cargando...</h1>
        {error && <button onClick={retry}>Reintentar</button>}
      </div>
    </div>
  )
};

export { LoadingScreen };