import styled from 'styled-components';

export const CoursesContainer = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    background-color: #ffffff;
`

export const CoursesMainContainer = styled.div`
    max-width: 754px;
    min-height: 100%;
    margin: auto;
`

export const CoursesHeaderMessage = styled.div`
    padding: 39px 0 17px;
    font-family: 'Montserrat';


    h1 {
        font-family: 'Montserrat';
        font-weight: 600;
        color: #282828;
        line-height: 1.1;
        text-align: center;
        font-size: 40px;
        @media screen and (width <= 763px) {
      & {
        font-size: 32px;
      }

      @media screen and (width <= 670px) {
        font-size: 28px;
      }

      @media screen and (width <= 480px) {
        font-size: 26px;
      }
    }
    }

    p {
        margin: 1.1rem 0;
        line-height: 20.5px;
        text-align: center;
    }

    .container__lista{
        text-align: center
    }

    #lista-cursos{
        font-weight: 700;
        color: #C11F6D;
        background-color: rgba(193, 31, 109, 0.2);
        border: none; 
        border-radius: 25px; 
        outline: none; 
        width: 160px;
        height: 34px;
        margin-bottom: 34px;
        font-size: 15px;
    }

    span{
        position:relative;
    }

    span::after{
        content: "";
        position: absolute; 
        left: 0; 
        bottom: 4.1px; 
        width: 100%; 
        height: 3.5px;
        background-color: #C11F6D;
    }
    

    @media screen and (max-width: 768px){
        h1{
            line-height: normal;
            max-width: 77vw;
            margin: auto;

            br{
                display: none;
            }
        }

        p{ 
            font-size: 14px;
            width: 70%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 0;
        }
    }
`