
import React, { useState } from 'react';
import { useFirebaseContext } from '../../Services/Firebase';
import { GoogleLoginButton, } from 'react-social-login-buttons';
import { useHistory } from 'react-router-dom';

const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential';
  
const ERROR_MSG_ACCOUNT_EXISTS = `
  Ya existe una correo electrónico vinculada con
  esta red social, intente conectarse desde esta cuenta.
`;

export const GoogleSignIn = () => {
	const firebase = useFirebaseContext()
  const history = useHistory()

	const [error, setError] = useState<any | null>(null)
  
  const onSubmit = (event: any) => {
    firebase
    .doSignInWithGoogle()
    .then((socialAuthUser: any) => {
      //creamos usuario en DB
      if(!socialAuthUser.additionalUserInfo.isNewUser) {
        // para todos los usuarios que ya entraron con google
        // pero no guardavamos la foto de perfil..
        return firebase.user(socialAuthUser.user.email).update({
          socialMediaPhotoUrl: socialAuthUser.user.photoURL,
        })
      }
      return firebase
      .user(socialAuthUser.user.email)
      .set({
        username: socialAuthUser.user.displayName,
        email: socialAuthUser.user.email,
        socialMediaPhotoUrl: socialAuthUser.user.photoURL,
        roles: [],
        products: [],
      });
    })
    .then((socialAuthUser: any) => {
      setError(null);
      history.goBack(); 
      // history.push('https://cursos.arleneruizfengshui.com/')
      
    })
    .catch((error: any) => {
      if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
        error.message = ERROR_MSG_ACCOUNT_EXISTS;
      }
      setError(error);
    });
    event.preventDefault();
  };
  
  
	return (
    <form onSubmit={onSubmit}>
      <GoogleLoginButton className="google-login-button"><span>Acceder con Google</span></GoogleLoginButton>
      {error && <p>{error.message}</p>}
    </form>
	);
}
