import React, { FC, useRef, useState } from "react";
import LessonListElement, { LessonCreator } from "./LessonListElement";
import styled from "styled-components";

import { useCourses } from "../../hooks/useCourses";
import { LessonsType, ModulosType } from "../../types";
import { useHistory, useLocation } from "react-router-dom";
import { useAuthUser } from "../../../../Services/useAuthUser";

type ComponentProps = {
  idCourse: string | "";
  courseURL: string;
  moduleData: ModulosType;
  idModule: string;
  refresh: () => void;
  userCourseProgress: { id: string; data: any }[];
  lessonData: { id: string; data: LessonsType }[];
};

const ModuleListElement: FC<ComponentProps> = ({
  idCourse,
  courseURL,
  idModule,
  moduleData,
  refresh,
  lessonData,
  userCourseProgress,
}) => {
  const { isAdmin } = useAuthUser();
  const [isModifyModule, setModifyModule] = useState(false);
  const [lessonsFailing, setLessonFailing] = useState<string[]>([]);
  const { deleteModuleById, updateValuesOfModule } = useCourses();
  const inputReference = useRef<any>(null);
  let isFailing =
    !moduleData.descripcion ||
    !moduleData.moduloImgUrl ||
    lessonsFailing.length > 0;
  const history = useHistory();

  function toggleLessonList(e: any) {
    history.replace(`/curso/${courseURL}/${idModule}`);
  }

  async function deleteModule() {
    if (lessonData!.length === 0) {
      await deleteModuleById(idCourse, idModule);
      refresh();
    } else if (
      window.confirm("Este modulo posee lecciones, ¿desea eliminarlo?")
    ) {
      await deleteModuleById(idCourse, idModule);
      refresh();
    }
  }

  function isModuleCompleted() {
    return lessonData.every((lesson) =>
      userCourseProgress.find((data) => data.id === lesson.id)
    );
  }

  function enableModuleModification(event: any) {
    if (event.target !== inputReference.current) {
      setModifyModule(!isModifyModule);
    }
  }

  function onLoseFocus() {
    setModifyModule(false);
  }

  async function doCreateModule(event: any) {
    if (event.key === "Enter") {
      await updateValuesOfModule(idCourse, idModule, {
        nombreModulo: event.target.value,
      });
      setModifyModule(false);
      refresh();
    }
  }

  function tellFailing() {
    let failingMessage = "";

    if (!moduleData.descripcion) failingMessage += "\n-Descripción de lección";
    if (!moduleData.moduloImgUrl) failingMessage += "\n-Imagen del Modulo";

    if (lessonsFailing.length > 0) {
      failingMessage += "\n\nLas siguientes lecciones estan fallando:";
      for (let leccion of lessonsFailing) failingMessage += "\n-" + leccion;
    }

    return failingMessage.length > 0
      ? "Este modulo no se mostrara a los usuarios\n\nLe falta:" +
          failingMessage
      : "";
  }

  const location = useLocation<any>();
  // no podemos usar useParams por que estamos fuera del switch de cursos...
  // toca extraer el id del modulo de la url
  const ModuleIdFromUrl = location.pathname.split("/")[3];

  return lessonData ? (
    lessonData.length > 0 || isAdmin ? (
      <Container
        isFailing={lessonData?.length === 0 || isFailing}
        isModify={isModifyModule}
        isCompleted={isModuleCompleted() && !isAdmin}
      >
        <div>
          {isModifyModule ? (
            <InputContainer>
              <input
                autoFocus
                ref={inputReference}
                onBlur={onLoseFocus}
                onKeyPress={doCreateModule}
              />
            </InputContainer>
          ) : (
            <li
              key={idModule}
              onClick={toggleLessonList}
              className="course-module-list"
              title={tellFailing()}
            >
              {moduleData.nombreModulo}
              {isModuleCompleted() && !isAdmin && (
                <i className="fas fa-check-circle"></i>
              )}
            </li>
          )}
          {isAdmin && (
            <DeleteModuleButton className="module-delete-button">
              <i
                className="fas fa-pen-square"
                onClick={enableModuleModification}
              ></i>
              <i className="fas fa-trash-alt" onClick={deleteModule}></i>
            </DeleteModuleButton>
          )}
        </div>
        <CourseLessonList moduleIdFromUrl={ModuleIdFromUrl} id={idModule}>
          <ul>
            {lessonData?.map((lesson) => {
              return (
                <LessonListElement
                  key={lesson.id}
                  idCourse={idCourse}
                  courseURL={courseURL}
                  idModule={idModule}
                  idLesson={lesson.id}
                  nombreLeccion={lesson.data.nombreLeccion}
                  isAdmin={isAdmin}
                  update={refresh}
                  lessonData={lesson.data}
                  lessonsFailing={lessonsFailing}
                  setFailing={setLessonFailing}
                  userCourseProgress={userCourseProgress}
                />
              );
            })}
            {isAdmin ? (
              <LessonCreator
                onCreation={refresh}
                idCourse={idCourse}
                idModule={idModule}
              />
            ) : null}
          </ul>
        </CourseLessonList>
      </Container>
    ) : null
  ) : null;
};

const DeleteModuleButton = styled.span`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  padding: 0 5px;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  margin-bottom: 13px;
  overflow: hidden;
  position: relative;

  .course-module-list {
    user-select: none;
    cursor: pointer;
    display: block;
    padding: 5px 8px;
    text-align: left;
    line-height: 17px;
    font-family: "Montserrat", sans-serif;
    font-size: 17px;
    font-weight: 500;
    color: white;
    border-radius: 5px;
    position: relative;

    ${({ isFailing, isCompleted }) =>
      isFailing
        ? "background-color: #cf2121"
        : isCompleted
        ? "background-color: #009b0c"
        : "background-color: #909689"};

    i {
      position: absolute;
      right: 2%;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  div:first-of-type {
    overflow: hidden;
    position: relative;
    font-size: 15px;

    .module-delete-button {
      color: white;
      right: -20%;
      transition: 0.5s;

      i {
        cursor: pointer;
      }
      i:last-of-type {
        margin-left: 6px;
      }
    }

    :hover {
      .module-delete-button {
        background: #00000042;
        ${({ isModify }) => (isModify ? null : "right: 0;")}
      }
    }
  }

  :last-of-type {
    margin-bottom: 0px;
  }
`;

const CourseLessonList = styled.div`
  font-family: "Montserrat", sans-serif;
  overflow: hidden;
  padding: 0 10px;
  ${({ moduleIdFromUrl, id }) =>
    moduleIdFromUrl === id ? "height: auto" : "height: 0"};

  li {
    margin-top: 5px;
    font-size: 15px;
  }
`;

const ContainerCreator = styled.div`
  margin-bottom: 10px;

  #course-module-creator {
    padding: 5px 8px;
    cursor: pointer;
    display: block;
    text-align: left;
    line-height: 17px;
    font-family: "Montserrat", sans-serif;
    font-size: 17px;
    font-weight: 500;
    color: white;
    background-color: #618af17d;
    border-radius: 5px;
    transition: 0.25s;

    :hover {
      background-color: #909689;
    }
  }

  :last-of-type {
    margin-bottom: 0px;
  }
`;

const InputContainer = styled.div`
  background-color: #909689;
  border-radius: 5px;
  padding: 1px 8px 1px;

  input {
    margin-top: 2px;
    height: 100%;
    outline-width: 0;
    border: none;
    width: 100%;
    background: none;

    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    color: white;
  }

  input:focus {
    height: 100%;
    outline-width: 0;
    border: none;
  }
`;

type ModuleCreatorProps = {
  idCourse: string;
  onCreation: any;
};

export const ModuleCreator = ({ idCourse, onCreation }: ModuleCreatorProps) => {
  const [isCreatingModule, setCreatingModule] = useState(false);
  const { createModuleForCourse } = useCourses();
  const inputReference = useRef<any>(null);

  function enableModuleCreation(event: any) {
    if (event.target !== inputReference.current) {
      setCreatingModule(!isCreatingModule);
    }
  }

  function onLoseFocus() {
    setCreatingModule(false);
  }

  async function doCreateModule(event: any) {
    if (event.key === "Enter") {
      await createModuleForCourse(idCourse, event.target.value);
      setCreatingModule(false);
      onCreation();
    }
  }

  return (
    <ContainerCreator onClick={enableModuleCreation!}>
      {!isCreatingModule ? (
        <li id="course-module-creator">Crear Modulo</li>
      ) : (
        <InputContainer>
          <input
            autoFocus
            ref={inputReference}
            onBlur={onLoseFocus}
            onKeyPress={doCreateModule}
          />
        </InputContainer>
      )}
    </ContainerCreator>
  );
};

export default ModuleListElement;
