import React, { useEffect, useState } from "react";
import VideoMaterial from "./VideoMaterial";
import PdfMaterial from "./PdfMaterial";
import { LessonCompleteButton } from "./LessonCompleteButton";
import { useCursoContext } from "../../CursoContext";

import { MaterialContainer } from "../../styles";
import { useParams } from "react-router-dom";

export type LessonsType = {
  indexPos: number;
  descripcion: string;
  nombreLeccion: string;
  video: [string, string];
  pdfs: string;
};

export const PaginaLeccion = () => {
  const {
    courseData,
    doModuleLessonRefresh,
    courseModules,
    userCourseProgress,
  } = useCursoContext();

  const urlParams = useParams<any>();

  const [lessonData, setLessonData] = useState<any>(null);

  useEffect(() => {
    function getLessonSelectedData(): { id: string; data: LessonsType } | null {
      //Conseguir el modulo que lo tiene
      if (courseModules) {
        const module = courseModules.find(
          (modulo) => modulo.id === urlParams.idModule
        );
        return (
          module?.lessonsData.find(
            (lesson) => lesson.id === urlParams.idLesson
          ) || null
        );
      }
      return null;
    }
    setLessonData(getLessonSelectedData());
  }, [urlParams.idModule, urlParams.idLesson, courseModules]);

  function isSelectedLessonCompleted() {
    if (userCourseProgress) {
      return userCourseProgress.find(
        (lesson: { id: string; data: any }) => lesson.id === urlParams.idLesson
      );
    }
    return null;
  }
  const [isLessonComplete, setLessonComplete] = useState(false);

  return (
    <MaterialContainer>
      <VideoMaterial
        idCourse={courseData?.id || ""}
        idLesson={urlParams?.idLesson}
        idModule={urlParams?.idModule}
        submit={doModuleLessonRefresh}
        setLessonComplete={setLessonComplete}
        video={lessonData?.data.video}
      />
      <div className="lesson-title">
        <h1>{lessonData?.nombreLeccion}</h1>
        <LessonCompleteButton
          isComplete={isLessonComplete}
          wasCompleted={isSelectedLessonCompleted()}
          idCourse={courseData?.id || ""}
          idLesson={urlParams?.idLesson}
        />
      </div>
      <PdfMaterial
        idCourse={courseData?.id || ""}
        idLesson={urlParams?.idLesson}
        idModule={urlParams?.idModule}
        pdfUrl={lessonData?.data.pdfs || ""}
        refresh={doModuleLessonRefresh}
      />
    </MaterialContainer>
  );
};
